import {ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {


    constructor(public zone: NgZone, private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.calculate();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    calculate() {
    }
}
