<section>
    <div class="flex px-4 py-2 overflow-x-auto snap-x gap-x-2">
        <!--<vw-warning-list-item *ngFor="let warning of [{}, {},{}, {},{}, {},{}, {}]"></vw-warning-list-item> -->

        <vw-circle-button (click)="selectMonth(p_month, $event)" *ngFor="let p_month of months"
                          [label]="'COMMON.SHORT_MONTHS.'+p_month.toUpperCase()"
                          [selected]="p_month == selectedMonth"
                          class="h-[56px]"></vw-circle-button>
    </div>

    <!--{{monthTasks | json}}-->

    <div class="py-8 h-[calc(100vh-240px)] overflow-x-auto" >
        <!--  {{monthTasks | json}} -->
        <vw-task-type-tasks *ngFor="let mt of monthTasks"
                            [month]="selectedMonth" [monthTasks]="mt" >
        </vw-task-type-tasks>
    </div>
</section>
