import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-plus-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plus-icon.component.html',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent {

}
