import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
  public loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loaderQueue = 0;

  constructor() { }

  public showLoader(): void {
    this.loaderQueue = this.loaderQueue + 1;
    setTimeout(() => {
      this.loaderSubject.next(this.loaderQueue !== 0);
    }, 500);
  }

  public hideLoader(): void {
    this.loaderQueue = this.loaderQueue > 0 ? this.loaderQueue - 1 : 0;
    this.loaderSubject.next(this.loaderQueue !== 0);
  }
}
