<div *ngIf="question">
    <div class="mt-6 w-full flex justify-center">
        <div class="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#F5F6F8]">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 14.0003C28 17.8053 25.1075 20.9129 21.4118 21.3287V27.1765C21.4118 27.6316 21.0434 28 20.5883 28C20.1331 28 19.7647 27.6316 19.7647 27.1765V21.3287C17.2357 21.0442 15.104 19.5019 14 17.3245C12.8961 19.5019 10.7644 21.0441 8.23534 21.3287V27.1765C8.23534 27.6316 7.86698 28 7.41183 28C6.95668 28 6.58831 27.6316 6.58831 27.1765V21.3287C2.8925 20.913 0 17.8053 0 14.0003C0 13.5452 0.368367 13.1768 0.823516 13.1768C3.1638 13.1768 5.22889 14.2884 6.58822 15.9874V11.4465C3.80197 11.0425 1.64703 8.66141 1.64703 5.76517V0.824112C1.64703 0.469477 1.87382 0.154988 2.21001 0.0432668C2.54454 -0.0709581 2.91615 0.0465118 3.13005 0.330405L4.94119 2.74545L6.75233 0.330405C7.06441 -0.0845872 7.75924 -0.0845872 8.07123 0.330405L9.88237 2.74545L11.6935 0.330405C11.9074 0.048088 12.2806 -0.0692892 12.6136 0.0432668C12.9497 0.155081 13.1765 0.469477 13.1765 0.824112V5.76517C13.1765 8.66141 11.0216 11.0426 8.23534 11.4465V15.9874C9.59467 14.2883 11.6598 13.1768 14 13.1768C16.3403 13.1768 18.4054 14.2884 19.7647 15.9874V11.4465C16.9785 11.0425 14.8236 8.66141 14.8236 5.76517V0.824112C14.8236 0.469477 15.0503 0.154988 15.3865 0.0432668C15.7211 -0.0709581 16.0927 0.0465118 16.3066 0.330405L18.1177 2.74545L19.9289 0.330405C20.2409 -0.0845872 20.9358 -0.0845872 21.2478 0.330405L23.0589 2.74545L24.87 0.330405C25.0839 0.048088 25.4571 -0.0692892 25.7901 0.0432668C26.1263 0.155081 26.3531 0.469477 26.3531 0.824112V5.76517C26.3531 8.66141 24.1981 11.0426 21.4119 11.4465V15.9874C22.7712 14.2883 24.8363 13.1768 27.1766 13.1768C27.6316 13.1768 28 13.5452 28 14.0003Z"
                      fill="#F26B59"></path>
            </svg>
        </div>
    </div>

    <h3 class="my-5 text-xl w-full text-center leading-[34px] font-bold">{{question.content | Label}}</h3>

    <div *ngIf="question.type === QuestionType.SIMPLE || question.type === QuestionType.MULTI">
        <div>
            <ng-container *ngFor="let answer of question.answers">
                <div class=" px-6 py-4 mb-4 flex border rounded shadow-sm items-center gap-x-5 justify-between" *ngIf="!answer.selected"
                     (click)="toggle(answer)">
                    <p class="text-base leading-[21px]">{{answer.title | Label}}</p>
                </div>

                <div class=" px-6 py-3 flex items-center gap-x-5 justify-between" *ngIf="answer.selected"
                     (click)="toggle(answer)">
                    <p class="text-[15px] leading-[21px] font-bold">{{answer.title | Label}}</p>
                    <div class="round min-w-[24px] max-h-[24px]">
                        <input type="checkbox" id="checkbox" checked="">
                        <label for="checkbox"></label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>


    <div *ngIf="question.type === QuestionType.YESNO">
        <div class="flex items-center justify-between w-full my-4">
            <ng-container *ngFor="let answer of question.answers">
                <button class="w-full text-base leading-6 font-medium px-[38px] py-3 m-2 bg-brand-500 text-white rounded-lg"
                        *ngIf="!answer.selected" (click)="toggle(answer)">{{answer.title | Label}}</button>
                <button class="w-full text-base leading-6 font-medium px-[38px] text-white py-3 m-2 bg-brand-500 rounded-lg"
                        *ngIf="answer.selected" (click)="toggle(answer)">{{answer.title | Label}}</button>
            </ng-container>
        </div>
    </div>
</div>
