import {Component, Input} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {ArrowLeftComponent} from "../../icons/arrow-left/arrow-left.component";
import {LocationModule} from "@faker-js/faker";

@Component({
  selector: 'vw-app-details-header',
  standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, ArrowLeftComponent],
  templateUrl: './app-details-header.component.html',
  styleUrls: ['./app-details-header.component.scss']
})
export class AppDetailsHeaderComponent {
  @Input() title: string = '';
  @Input() titleData: any = {};

  constructor(private location: Location) {
  }

  onPreviousClicked() {
    this.location.back();
  }
}
