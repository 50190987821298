<div class="overflow-auto h-full pb-3">
    <div class="space-y-4">
        <div class="relative">
            <img src="https://source.unsplash.com/600x350/?tomatoes" alt="">
            <div class="absolute top-3 left-3 flex justify-center items-center">
                <div class="flex justify-center items-center inline-block bg-red-300 text-red-900 px-4 py-2 space-x-2 rounded-full">
                    <svg height="18px" width="18px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 512 512"  xml:space="preserve">
                    <style type="text/css">
                        .st0{fill:#7f1d1d;}
                    </style>
                    <g>
                        <path class="st0" d="M505.038,228.923c-4.596-16.219-11.413-31.43-20.408-44.915c-7.467-11.208-16.39-20.831-26.175-28.908
                            c-14.69-12.128-31.291-20.806-48.226-26.484c-3.269-1.089-6.563-2.05-9.866-2.928c28.737-26.841,36.049-76.336,31.445-67.112
                            c-3.92,7.834-56.002,36.692-92.872,52.146c13.64-16.504,29.42-44.622,40.335-78.231c0,0-56.619,52.3-91.798,73.196
                            c-11.119-19.326-31.478-84.055-31.478-84.055s-17.236,49.64-18.895,79.248c-28.054-15.714-58.832-51.454-67.307-72.862
                            c-9.996-25.296-12.819,53.675,14.494,86.861c-0.391,0.114-0.765,0.228-1.13,0.333c-0.334-0.081-0.651-0.171-0.984-0.244
                            c-1.065-0.252-2.132-0.48-3.214-0.699c-36.366-15.17-91.294-47.405-95.369-53.749c-4.506-7.012,0.732,42.028,27.427,65.323
                            c-14.446,3.912-28.81,9.899-42.206,18.269c-15.779,9.858-30.241,23.076-41.442,39.896c-8.995,13.486-15.812,28.696-20.4,44.915
                            C2.367,245.141,0,262.386,0,280.036c0,24.71,4.652,50.218,14.568,74.7c7.434,18.342,17.846,36.098,31.445,52.366
                            c20.392,24.409,47.982,45.402,82.933,60.092c34.951,14.714,77.174,23.181,127.05,23.173c44.345,0,82.623-6.678,115.142-18.505
                            c24.377-8.865,45.492-20.618,63.379-34.487c26.833-20.806,46.314-46.33,58.945-73.521c12.649-27.2,18.529-56.026,18.537-83.819
                            C512,262.386,509.633,245.141,505.038,228.923z M463.538,340.991c-6.059,14.966-14.518,29.387-25.614,42.654
                            c-16.65,19.895-39.205,37.261-69.056,49.844c-29.851,12.558-67.088,20.302-112.872,20.31c-40.686,0-74.637-6.124-102.633-16.308
                            c-21.01-7.638-38.684-17.552-53.463-29.013c-22.165-17.203-37.888-37.904-48.202-60.052c-10.306-22.14-15.129-45.752-15.129-68.389
                            c0-14.356,1.944-28.306,5.58-41.149c3.644-12.852,8.971-24.589,15.649-34.602c5.377-8.052,11.81-15.023,19.033-20.986
                            c10.818-8.939,23.45-15.609,36.562-20c13.103-4.409,26.679-6.508,39.042-6.499c7.759-0.008,15.047,0.83,21.384,2.318
                            c6.336,1.472,11.688,3.628,15.689,6.035l3.758-6.247c3.799-1.139,9.785-2.814,17.13-4.474c12.762-2.895,29.647-5.686,45.598-5.661
                            c8.101,0,16.455,0.7,24.376,1.782c11.884,1.635,22.783,4.115,30.608,6.182c3.132,0.821,5.734,1.57,7.76,2.18l3.75,6.238
                            c4.001-2.407,9.362-4.563,15.69-6.035c6.336-1.488,13.624-2.326,21.383-2.318c8.24,0,17.025,0.92,25.842,2.855
                            c13.233,2.888,26.524,8.036,38.392,15.462c11.883,7.434,22.344,17.09,30.404,29.168c6.678,10.012,12.005,21.75,15.649,34.602
                            c3.636,12.843,5.58,26.793,5.58,41.149C475.43,300.15,471.624,321.054,463.538,340.991z"/>
                        <path class="st0" d="M108.692,280.19c-0.008-15.56,3.229-30.153,8.126-41.573l-24.011-10.273
                            c-6.369,14.893-10.224,32.706-10.241,51.846c0,11.753,1.48,23.986,4.824,36.236s8.565,24.516,16.016,36.228l22.042-14.022
                            c-5.986-9.411-10.167-19.213-12.851-29.087C109.897,299.687,108.692,289.756,108.692,280.19z"/>
                    </g>
                    </svg>
                    <p>
                        Tomate
                    </p>
                </div>
            </div>
        </div>
        <div class="flex space-x-2">
            <div class="bg-neutral-200 rounded-full inline-block">
                <p class="px-4 py-2 font-bold">01 - Step 1</p>
            </div>
            <div class="bg-neutral-200 rounded-full inline-block">
                <p class="px-4 py-2">02 - Step 2</p>
            </div>
            <div class="bg-neutral-200 rounded-full inline-block">
                <p class="px-4 py-2">03 - Step 3</p>
            </div>
        </div>
    </div>

    <div *ngFor="let growingStep of growingSteps">
        <div class="px-4 py-5">
            <vw-growing-step-viewer class="block" [growingSteps]="growingSteps" [growingStep]="growingStep" (delete)="onDelete()"></vw-growing-step-viewer>
        </div>
    </div>
</div>