import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {LabelPipe} from "../../../../@commons/pipes/LabelPipe";
import {
    CultivationStockLevelComponent
} from "../../../../@commons/components/@cultivation-specific/cultivation-stock-level/cultivation-stock-level.component";
import {TranslateModule} from "@ngx-translate/core";
import {
    CultivationRankingComponent
} from "../../../../@commons/components/@cultivation-specific/cultivation-ranking/cultivation-ranking.component";
import {FileUtils} from "../../../../@commons/utils/file-utils";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {DmsService} from "../../../../@commons/services/vegiwise/dms.service";
import {DotButtonComponent} from "../../../../@commons/components/@generic/buttons/dot-button/dot-button.component";
import {DotMenuItem} from "../../../../@commons/classes/dot-menu-item";

@Component({
    selector: 'vw-cultivation-settings-item',
    templateUrl: './cultivation-settings-item.component.html',
    styleUrls: ['./cultivation-settings-item.component.scss'],
    standalone: true,
    imports: [NgIf, LabelPipe, NgTemplateOutlet, CultivationStockLevelComponent, TranslateModule, CultivationRankingComponent, DotButtonComponent]
})
export class CultivationSettingsItemComponent implements OnInit {

    @Input() cultivation: CultivationDto;
    @Input() showMenu: boolean = true;
    isPendingAction: boolean = false;
    cultivationPictureUrl = '';
    backgroundColor = null;
    @Output() delete: EventEmitter<CultivationDto> = new EventEmitter<CultivationDto>();
    dotMenuItems: DotMenuItem[] = [
        {path: '', label: 'CULTIVATIONS.ITEM.MENU.DELETE', style: '', onClick: () => {this.delete.emit(this.cultivation)}}
    ]

    constructor(private dmsService: DmsService) {
    }

    ngOnInit(): void {
        if (this.cultivation) {
            if (this.cultivation.risks) {
                this.isPendingAction = this.cultivation.risks.length > 0;
            }
            const fileUid: string = FileUtils.getFileUidFromCollection(this.cultivation.files,  null );
            if (fileUid) {
                this.cultivationPictureUrl = `url(${this.dmsService.getFileUrl(fileUid, null, 300)})`;
                this.backgroundColor = this.cultivation.backgroundColor;
            }
            //this.cultivationPictureUrl = './assets/cards/Component4.png';
        }
    }

}
