<div class="w-full h-[16px]"></div>

<div class="bg-white rounded-lg border border-[#D0D0D0] px-3 py-3 mx-3">

    <div class="flex justify-between items-center gap-x-2.5">
        <div class="w-[250px] h-[250px] rounded-full flex items-center justify-center bg-[#F5F6F8] m-auto"
             (click)="uploadProfilePicture()"
             [style.background-image]="pictureUrl"
             [style.background-size]="'auto 250px'">
        </div>
    </div>
    <input
        #fileInput
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        (change)="onFileSelected($event)"
        style="display: none"/>

    <div class="h-[50px] w-full"></div>
    <div class="w-full max-w-full">
        <form [formGroup]="form">

            <div class="mb-6">
                <label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5">
                    {{ "REGISTER.FORM.PERSONAL_DATA" | translate }}
                </label>
                <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.NAME.LABEL' | translate }} </label>-->
                <vw-text-input
                        [placeholder]="'REGISTER.FORM.NAME.PLACEHOLDER'"
                        [type]="'string'"
                        [iconTemplate]="nameIcon"
                        formControlName="lastNameCtrl">
                    <ng-template #nameIcon>
                        <vw-person-standing-icon></vw-person-standing-icon>
                    </ng-template>
                </vw-text-input>
                <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.FIRSTNAME.LABEL' | translate }} </label>-->
                <vw-text-input
                        [placeholder]="'REGISTER.FORM.FIRSTNAME.PLACEHOLDER'"
                        [type]="'string'"
                        [iconTemplate]="firstNameIcon"
                        formControlName="firstNameCtrl">
                    <ng-template #firstNameIcon>
                        <vw-person-standing-icon></vw-person-standing-icon>
                    </ng-template>
                </vw-text-input>
                <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.EMAIL.LABEL' | translate }} </label>-->
            </div>
            <!-- Error Message -->
            <vw-error-message
                    *ngFor="let error of errors"
                    [message]="error.message"
                    [fieldName]="error.field"
                    [formI18nPath]="'REGISTER.FORM'"
            ></vw-error-message>
            <div class="h-[50px] w-full"></div>
        </form>
    </div>
    <div class="h-[64px] w-full"></div>

    <div class="flex items-center gap-x-[12px]">
        <button class="bg-white hover:bg-white/80 border-[0.5px] border-[#D0D0D1] rounded-[7px] text-base font-medium text-[#202939] px-6 py-2.5 w-full"
                [routerLink]="'[..]'"
                type="button">
            {{ "COMMON.CANCEL" | translate }}
        </button>
        <button class="bg-[#30A156] hover:bg-[#30A156]/80 border-[0.5px] border-[#30A156] rounded-[7px] text-base font-medium text-white px-6 py-2.5 w-full"
                (click)="save($event)"
                type="button">
            {{ "COMMON.SAVE" | translate }}
        </button>
    </div>
</div>