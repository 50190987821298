import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {Context} from "../context.service";

@Injectable({
    providedIn: 'root'
})
export class HeaderFooterService implements OnDestroy {

    visibility: Subject<boolean> = new Subject<boolean>();
    scrolledComponents: any;
    scrolling: boolean = false;
    public scrollChanged: Subject<boolean> = new Subject<boolean>();


    lastKnownScroll: number = 0;

    ngOnDestroy(): void {

    }

    setVisible(val: boolean) {
        this.visibility.next(val);
    }

    onScroll(componentName: string, scrollValue: number) {
        if(!this.scrolledComponents) {
            this.scrolledComponents = {};
        }

        let lastScrollPosition = this.scrolledComponents[componentName] || 0;
        if (scrollValue > lastScrollPosition) {
            this.scrolling = true;
            this.scrollChanged.next(this.scrolling);
            // L'utilisateur fait défiler vers le bas, cachez le header et le footer
            //this.setVisible(false); //todo not working correctly, should use height to scroll footer & header
        } else {
            this.scrolling = false;
            this.scrollChanged.next(this.scrolling);
            // L'utilisateur fait défiler vers le haut, révélez le header et le footer
           // this.setVisible(true);
        }


        this.scrolledComponents[componentName] = scrollValue;
    }

    /*doHideShow() {
        // Initialisation de la position de défilement
        let lastScrollPosition = this.page.nativeElement.scrollTop;

        // Écouteur d'événement de défilement
        this.renderer.listen(this.page.nativeElement, 'scroll', () => {
            const currentScrollPosition = this.page.nativeElement.scrollTop;

             const currentScrollPosition = this.page.nativeElement.scrollTop;

            if (currentScrollPosition > lastScrollPosition) {
                // L'utilisateur fait défiler vers le bas, cachez le header et le footer
                this.renderer.addClass(this.header.nativeElement, 'hide-element');
                this.renderer.addClass(this.footer.nativeElement, 'hide-element');
            } else {
                // L'utilisateur fait défiler vers le haut, révélez le header et le footer
                this.renderer.removeClass(this.header.nativeElement, 'hide-element');
                this.renderer.removeClass(this.footer.nativeElement, 'hide-element');
            }

            lastScrollPosition = currentScrollPosition;
        });
    }*/




}
