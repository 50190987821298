<vw-default-layout [bodyTemplate]="bodyT" [tabsTemplate]="tabs" [headerAdditionalComponent]="headerAdditionalComponent">
    <ng-template #bodyT>
        <router-outlet></router-outlet>
    </ng-template>

    <ng-template #headerAdditionalComponent>
        <vw-upgrade-pro-btn [style]="'COLOR'" (click)="onClickUpgradePro($event)"></vw-upgrade-pro-btn>
    </ng-template>

    <ng-template #tabs>
        <vw-user-summary class="px-3 pt-2.5" [currentUser]="currentUser"></vw-user-summary>
        <vw-app-header-tabs [tabs]="tabItems"></vw-app-header-tabs>
    </ng-template>

</vw-default-layout>
