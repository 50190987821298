import { Component, OnInit } from '@angular/core';
import {RouterLinkActive, RouterModule, RouterOutlet} from '@angular/router';
import { DefaultLayoutComponent } from '../@commons/layouts/default-layout/default-layout.component';
import {UserSummaryComponent} from '../settings/@components/user-summary/user-summary.component';
import {TabMenuItem} from '../@commons/classes/tab-menu-item';
import {AppHeaderTabsComponent} from '../@commons/components/app-header-tabs/app-header-tabs.component';

@Component({
    selector: 'vw-cultivations',
    templateUrl: './cultivations.component.html',
    styleUrls: ['./cultivations.component.scss'],
    standalone: true,
    imports: [RouterOutlet, DefaultLayoutComponent, RouterModule, RouterLinkActive, UserSummaryComponent, AppHeaderTabsComponent]
})
export class CultivationsComponent implements OnInit {
    tabItems: TabMenuItem[] = [
        {path: '/cultivations/mine', label: 'Mes Cultures', style: ''},
        {path: '/cultivations/marketplace', label: 'Catalogue', style: ''}
    ];
  constructor() { }

  ngOnInit(): void {
  }

}
