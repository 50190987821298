import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { NgTemplateOutlet, NgClass, CommonModule } from '@angular/common';
import {HeaderFooterService} from "../../services/display/header-footer.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'vw-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, CommonModule]
})
export class AppHeaderComponent implements OnInit, OnDestroy{
    @Input() tabsTemplate: TemplateRef<any>;
    @Input() additionalComponent: TemplateRef<any>;
    scrolling: boolean = false;
    scrollChangedSubscription: Subscription;
  constructor(private headerFooterSevice: HeaderFooterService) { }

  ngOnInit(): void {
      this.scrollChangedSubscription = this.headerFooterSevice.scrollChanged.subscribe((isScrolling) => {
          this.scrolling = isScrolling;
      })
  }

  ngOnDestroy() {
      this.scrollChangedSubscription.unsubscribe();
  }

}
