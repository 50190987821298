import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, RouterModule} from "@angular/router";
import {LabelPipe} from "../../pipes/LabelPipe";
import {SkeletonDirective} from "../../directives/skeleton.directive";
import {ProgressBarComponent} from "../@generic/progress-bar/progress-bar.component";
import {TranslateModule} from "@ngx-translate/core";
import * as moment from "moment";
import {PlantDto} from "vegiwise-sdk-typescript/dist/model/@common/plant";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {DotButtonComponent} from "../@generic/buttons/dot-button/dot-button.component";
import {GrowingStepViewerComponent} from "../growing-step-viewer/growing-step-viewer.component";
import {PlantApi} from "vegiwise-sdk-typescript/dist/sdk/plant/plant.api";
import {ModalService} from "../../services/modal/modal.service";
import {ModalRef} from "../../services/modal/model-ref";
import {DeleteItemModalComponent} from "../../modals/delete-item-modal/delete-item-modal.component";
import {GrowingStepDto} from "vegiwise-sdk-typescript/dist/model/@common/growing-step";
import {PlantToGrowingStepDto} from "vegiwise-sdk-typescript/dist/model/@common/plant-to-growing-step";
import {PlantToGrowingStepApi} from "vegiwise-sdk-typescript/dist/sdk/plant/plant-to-growing-step.api";

@Component({
  selector: 'vw-plant-list-horizontal-viewer',
  standalone: true,
    imports: [CommonModule, RouterModule, LabelPipe, SkeletonDirective, ProgressBarComponent, TranslateModule, DotButtonComponent, GrowingStepViewerComponent],
  templateUrl: './plant-list-horizontal-viewer.component.html',
  styleUrls: ['./plant-list-horizontal-viewer.component.scss']
})
export class PlantListHorizontalViewerComponent implements OnInit {

  @Input() plantToGrowingStep: PlantToGrowingStepDto;
  @Input() cultivation: CultivationDto
  progressionPercentage: number = 60;
  plantedDate: string;


  constructor(private router: Router, private plantToGrowingStepApi: PlantToGrowingStepApi, private modalService: ModalService) {
  }

  ngOnInit() {
   // this.calculateProgression();
    this.plantedDate = moment(new Date()).format('DD/MM/yyyy')
  }

  goToDetails($event: MouseEvent) {
    this.router.navigate([`cultivations/${this.cultivation.uid}/plant/${this.plantToGrowingStep.plant.uid}`]);
  }

  onDelete() {
    const modal: ModalRef = this.modalService.open(DeleteItemModalComponent)
    modal.onResult().subscribe((res) => {
      if(res && res.deleteConfirmed) {
        this.plantToGrowingStepApi.deleteByPlantUid(this.plantToGrowingStep.plant.uid).then((res) => {
          this.router.navigate(['..'])
        })
      }
    })
  }
}
