import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TaskDto} from "vegiwise-sdk-typescript/dist/model/@common/task";
import {
  CultivationSettingsItemComponent
} from "../../../../../settings/@components/seed-stock/cultivation-settings-item/cultivation-settings-item.component";

;

@Component({
  selector: 'vw-task-cultivation-viewer',
  standalone: true,
  imports: [CommonModule, CultivationSettingsItemComponent],
  templateUrl: './task-cultivation-viewer.component.html',
  styleUrls: ['./task-cultivation-viewer.component.scss']
})
export class TaskCultivationViewerComponent {
  @Input() task: TaskDto;
}
