import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { DefaultLayoutComponent } from "../@commons/layouts/default-layout/default-layout.component";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { MapHomeComponent } from "./map-home/map-home.component";
import { ModalService } from "../@commons/services/modal/modal.service";
import { UpgradePremiumModalComponent } from "../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import { ModalRef } from "../@commons/services/modal/model-ref";
import { UpgradeProBtnComponent } from "../@commons/components/upgrade-pro-btn/upgrade-pro-btn.component";

@Component({
  selector: "vw-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    HttpClientModule,
    CommonModule,
    DefaultLayoutComponent,
    MapHomeComponent,
    UpgradeProBtnComponent,
  ],
})
export class MapComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  onClickUpgradePro($event: MouseEvent) {
    const modal: ModalRef = this.modalService.open(
      UpgradePremiumModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }
}
