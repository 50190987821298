<ng-template #defaultHeader>
  <vw-app-header
    [tabsTemplate]="tabsTemplate"
    [class]="{ 'hide-element': !headerFooterVisible }"
    [additionalComponent]="headerAdditionalComponent"
    #header
  ></vw-app-header>
</ng-template>

<ng-template #defaultBody> DEFAULT BODY </ng-template>

<ng-template #defaultFooter>
  <vw-app-menu
    id="footer-bar"
    class="footer-bar-1"
    [class]="{ 'hide-element': !headerFooterVisible }"
    #footer
  ></vw-app-menu>
</ng-template>

<div
  id="page"
  class="relative inset-0 flex flex-col w-full h-full"
  style="contain: layout size style"
>
  <ng-container
    [ngTemplateOutlet]="headerTemplate ? headerTemplate : defaultHeader"
  >
  </ng-container>
  <div class="relative flex-1" style="contain: layout size style">
    <ng-container
      [ngTemplateOutlet]="bodyTemplate ? bodyTemplate : defaultBody"
    >
    </ng-container>
  </div>

  <ng-container
    [ngTemplateOutlet]="footerTemplate ? footerTemplate : defaultFooter"
  >
  </ng-container>
</div>
