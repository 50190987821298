<vw-default-layout [bodyTemplate]="bodyT" [headerTemplate]="header">
  <ng-template #header>
    <vw-app-details-header
      [title]="'CULTIVATIONS.SELECT.TITLE'"
    ></vw-app-details-header>
  </ng-template>
  <ng-template #bodyT>
    <section
      class="h-full overflow-y-auto p-3"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="200"
      (scrolled)="loadMore($event)"
      (scroll)="onScroll($event)"
      [scrollWindow]="false"
    >
      <div class="">
        <vw-text-input
          [placeholder]="'CULTIVATIONS.SELECT.FILTER.PLACEHOLDER'"
          [inputClasses]="'pl-[30px]'"
          [type]="'email'"
          [showIcon]="false"
          formControlName="emailCtrl"
        >
        </vw-text-input>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2.5 gap-y-2.5">
        <div *ngFor="let cultivation of cultivations">
          <div class="w-full h-60">
            <vw-cultivation-item
              class="space-y-3.5 py-1.5"
              [cultivation]="cultivation"
              [mode]="'select'"
              (selectedChange)="onCultivationSelected($event, cultivation)"
            ></vw-cultivation-item>
            <!-- <vw-cultivation-item class="space-y-3.5 py-1.5 " [cultivation]="cultivation" [mode]="'select'"
                                            (selectedChange)="onCultivationSelected($event, cultivation)"
                                            *ngFor="let cultivation of cultivations"
                        ></vw-cultivation-item> -->
          </div>
        </div>
      </div>
    </section>
    <vw-selected-cultivations-viewer
      [selectedItems]="selectedCultivationUids"
      (click)="addSelectedCultivations($event)"
      *ngIf="selectedCultivationUids && selectedCultivationUids.length"
      class="absolute bottom-[30px] left-[30px] right-[30px]"
    >
    </vw-selected-cultivations-viewer>
  </ng-template>
</vw-default-layout>
