import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultLayoutComponent } from "../../@commons/layouts/default-layout/default-layout.component";
import { AppDetailsHeaderComponent } from "../../@commons/components/app-details-header/app-details-header.component";
import { CultivationHorizontalViewerComponent } from "../../@commons/components/cultivation-horizontal-viewer/cultivation-horizontal-viewer.component";
import { GrowingStepsViewerComponent } from "../../@commons/components/growing-steps-viewer/growing-steps-viewer.component";
import { Router } from "@angular/router";
import { GrowingStepApi } from "vegiwise-sdk-typescript/dist/sdk/plant/growing-step.api";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { PlantListHorizontalViewerComponent } from "../../@commons/components/plant-list-horizontal-viewer/plant-list-horizontal-viewer.component";
import { PlantApi } from "vegiwise-sdk-typescript/dist/sdk/plant/plant.api";
import { PagingResponse } from "../../@commons/services/base.service";
import { StorageService } from "../../@commons/services/front/storage.service";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { TranslateModule } from "@ngx-translate/core";
import { CultivationItemComponent } from "../../home/home-page/cultivation-item/cultivation-item.component";
import { HeaderFooterService } from "../../@commons/services/display/header-footer.service";
import { ListCultivationRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";
import { ErrorMessageComponent } from "../../@commons/components/@forms/error-message/error-message.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { KeyIconComponent } from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import { LinkComponent } from "../../@commons/components/@generic/link/link.component";
import { MailIconComponent } from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import { TextInputComponent } from "../../@commons/components/@generic/inputs/text-input/text-input.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SelectedCultivationsViewerComponent } from "./selected-cultivations-viewer/selected-cultivations-viewer.component";
import { CultivationToUserApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation-to-user.api";

@Component({
  selector: "vw-cultivation-plants",
  standalone: true,
  imports: [
    CommonModule,
    DefaultLayoutComponent,
    AppDetailsHeaderComponent,
    CultivationHorizontalViewerComponent,
    GrowingStepsViewerComponent,
    PlantListHorizontalViewerComponent,
    TranslateModule,
    CultivationItemComponent,
    ErrorMessageComponent,
    FormsModule,
    KeyIconComponent,
    LinkComponent,
    MailIconComponent,
    ReactiveFormsModule,
    TextInputComponent,
    InfiniteScrollModule,
    SelectedCultivationsViewerComponent,
  ],
  templateUrl: "./select-cultivation.component.html",
  styleUrls: ["./select-cultivation.component.scss"],
  providers: [
    CultivationApi,
    GrowingStepApi,
    StorageService,
    PlantApi,
    CultivationToUserApi,
  ],
})
export class SelectCultivationComponent {
  cultivations: CultivationDto[] = [];
  warnings: any[] = [];
  cultivationPairs: Array<CultivationDto[]> = [];
  page: number = 0;
  pageSize: number = 15;
  totalCultivations: number;
  selectedCultivationUids: string[] = [];

  constructor(
    private router: Router,
    private cultivationApi: CultivationApi,
    private headerFooterService: HeaderFooterService,
    private cultivationToUserApi: CultivationToUserApi
  ) {}

  ngOnInit() {
    this.loadCultivations();
    this.selectedCultivationUids = [];
  }

  divideArray<T>(array: T[]): [T[], T[]] {
    const middle = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middle);
    const secondHalf = array.slice(middle);
    return [firstHalf, secondHalf];
  }

  loadMore($event) {
    if (this.cultivations.length < this.totalCultivations) {
      this.page += 1;
      this.loadCultivations();
    }
  }

  loadWarnings() {
    //TODO get warnings from the db
    this.warnings = [];
  }

  loadCultivations() {
    const filters: ListCultivationRequest = new ListCultivationRequest();
    this.cultivationApi
      .list(filters, this.page, this.pageSize, "fr")
      .then((cultivations: PagingResponse<CultivationDto>) => {
        this.cultivations.push(...cultivations.results);
        this.totalCultivations = cultivations.page.totalElements;
        this.cultivationPairs.push(
          ...this.divideArray<CultivationDto>(this.cultivations)
        );
        console.log("Cultivations récupérées :", this.cultivations);
      });
  }

  onScroll($event) {
    this.headerFooterService.onScroll($event.target, $event.target.scrollTop);
  }

  onSwipe(none: string) {}

  onCultivationSelected(selected: boolean, cultivation: CultivationDto) {
    if (selected) {
      this.selectedCultivationUids.push(cultivation.uid);
    } else {
      this.selectedCultivationUids.splice(
        this.selectedCultivationUids.findIndex((culti) => {
          return culti === cultivation.uid;
        }),
        1
      );
    }
  }

  addSelectedCultivations($event: MouseEvent) {
    this.cultivationToUserApi
      .addCultivationsToUserList(this.selectedCultivationUids)
      .then((res) => {
        this.router.navigate(["home"]);
      });
  }
}
