import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-mail-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mail-icon.component.html',
  styleUrls: ['./mail-icon.component.scss']
})
export class MailIconComponent {

}
