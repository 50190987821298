<div *ngIf="isPendingAction" class="h-full w-full text-white" >
    <div [style.background-image]="cultivationPictureUrl"
         [style.background-size]="'auto 100px'"
         [style.background-color]="backgroundColor"
         class="h-full w-full px-2.5 py-3.5 bg-no-repeat  bg-center relative rounded-lg relative shadow-[0_4px_6px_-2px_rgba(0,0,0,0.5)]">
        <ng-container *ngTemplateOutlet="cultivationBody"></ng-container>
    <vw-cultivation-item-action *ngIf="isPendingAction"></vw-cultivation-item-action>
    </div>

    <!--<div class="bg-[#BE4A3A] h-full px-2.5 pt-1 pb-2.5" *ngIf="isPendingAction">
        <div class="flex items-center gap-x-1">
            <img src="./assets/common/medical-file.svg" alt="medical-file"/>
            <p class="text-[13px] leading-[21px] font-medium">Action Required!</p>
        </div>
        <p class="text-[11px] font-light leading-[13px]">Lorem ipsum dolor sit amet, consectetur adipisc</p>
    </div>-->
</div>
<div *ngIf="!isPendingAction"
     [style.background-image]="cultivationPictureUrl"
     [style.background-size]="'auto 100px'"
     [style.background-color]="backgroundColor"
     class="h-[220px] w-full px-2.5 py-3.5 bg-no-repeat flex flex-col bg-center items-center relative rounded-lg  text-white">
    <ng-container *ngTemplateOutlet="cultivationBody"></ng-container>
    <img src="/assets/imgs/shadow.png" alt="avatar" class="relative bottom-0 rounded-full"/>
</div>


<ng-template #cultivationBody>

    <div class="w-full rounded-lg text-white" (click)="onClick($event)">
        <div class="flex relative items-center justify-between w-full">
            <div class="flex justify-between w-full items-start">
                <div *ngIf="mode == 'select'" class="absolute right-[10px] w-[32px] h-[32px] rounded-full border-[1px]">
                    <button 
                            [ngClass]="{'bg-[#00000026] border-[#FFFFFF]': !selected, 'bg-[#FFFFFF] border-[#999999]': selected }">
                    </button>
                </div>
                <div class="mr-2">
                    <p class="max-w-[120px] font-bold text-[20px] leading-6">{{ cultivation.name | Label }}</p>
                    <p class="text-xs font-light leading-6">{{ cultivation.family?.name | Label }}</p>
                </div>

                <vw-dot-button *ngIf="mode == 'info'" theme="white" [menuItems]="dotMenuItems"></vw-dot-button>
            </div>

        </div>
        <div class="absolute bottom-1 flex items-center justify-between w-[calc(100%-20px)]" *ngIf="mode == 'info'">
            <div class="flex-row items-center">
                <vw-rounded-level-indicator>
                    <p>80%</p>
                </vw-rounded-level-indicator>
                <p class="text-xs">Progress</p>

            </div>
            <div class="flex-row">                                  
                <vw-rounded-level-indicator>
                    <div class="flex items-center gap-x-0.5">
                        <img src="/assets/common/ArrowFatLinesUp.svg" alt="ArrowFatLinesUp"/>
                        <p class="text-xs font-medium leading-6">12</p>
                    </div>
                </vw-rounded-level-indicator>
                <p class="text-xs">Humidité</p>
            </div>
            <div class="flex-row">
                <vw-rounded-level-indicator>
                    <div class="flex items-center gap-x-0.5">
                        <p class="text-xs font-medium leading-6">12</p>
                    </div>
                </vw-rounded-level-indicator>
                <!-- <vw-cultivation-ranking></vw-cultivation-ranking> -->
                <p class="text-xs">Classé</p>
            </div>
        </div>
    </div>
</ng-template>

