import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DefaultLayoutComponent } from '../@commons/layouts/default-layout/default-layout.component';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'vw-home',
    templateUrl: './auth.component.html',
    standalone: true,
    imports: [RouterOutlet, HttpClientModule, CommonModule, DefaultLayoutComponent ],
})
export class AuthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
