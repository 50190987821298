<section class="py-5">
    <!-- cards -->
    <div class="grid grid-cols-2 px-3 pb-2.5 gap-3.5">
        <div
                class="bg-[url('/assets/cards/Component4.png')] h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative rounded-lg overflow-hidden text-white"
        >
            <div class="flex items-start justify-between">
                <div>
                    <p class="font-bold text-[20px] leading-6">Garlic</p>
                    <p class="text-xs font-light leading-6">Vegetable & Plant</p>
                </div>

                <div class="flex items-center gap-x-0.5">
                    <button class="w-[32px] h-[32px] flex justify-center items-center bg-[#00000026] rounded-full">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M1.62582 7H1.6333"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.61922 12H1.62671"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.63216 2H1.63965"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="absolute bottom-3 flex items-center justify-between w-[calc(100%-20px)]">
                <div class="flex gap-x-2.5">
                    <div class="w-[40px] h-[40px] flex justify-center items-center relative">
                        <p class="absolute text-xs font-medium leading-6">80%</p>
                        <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
                            <circle cx="20" cy="20" r="18" fill="none" stroke="#FFFFFF35" stroke-width="4" />
                            <circle
                                    class="percent fifty"
                                    cx="20"
                                    cy="20"
                                    r="18"
                                    fill="none"
                                    stroke="#FFFFFF"
                                    stroke-width="4"
                                    pathLength="100"
                                    stroke-dasharray="100"
                                    stroke-dashoffset="20"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div
                class="bg-[url('/assets/cards/Component4.png')] h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative rounded-lg overflow-hidden text-white"
        >
            <div class="flex items-start justify-between">
                <div>
                    <p class="font-bold text-[20px] leading-6">Tomato</p>
                    <p class="text-xs font-light leading-6">Vegetable & Plant</p>
                </div>

                <div class="flex items-center gap-x-0.5">
                    <button class="w-[32px] h-[32px] flex justify-center items-center bg-[#00000026] rounded-full">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M1.62582 7H1.6333"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.61922 12H1.62671"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.63216 2H1.63965"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="absolute bottom-3 flex items-center justify-between w-[calc(100%-20px)]">
                <div class="flex gap-x-2.5">
                    <div class="w-[40px] h-[40px] flex justify-center items-center relative">
                        <p class="absolute text-xs font-medium leading-6">80%</p>
                        <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
                            <circle cx="20" cy="20" r="18" fill="none" stroke="#FFFFFF35" stroke-width="4" />
                            <circle
                                    class="percent fifty"
                                    cx="20"
                                    cy="20"
                                    r="18"
                                    fill="none"
                                    stroke="#FFFFFF"
                                    stroke-width="4"
                                    pathLength="100"
                                    stroke-dasharray="100"
                                    stroke-dashoffset="20"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div
                class="bg-[url('/assets/cards/Component4.png')] h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative rounded-lg overflow-hidden text-white"
        >
            <div class="flex items-start justify-between">
                <div>
                    <p class="font-bold text-[20px] leading-6">Egplant</p>
                    <p class="text-xs font-light leading-6">Vegetable & Plant</p>
                </div>

                <div class="flex items-center gap-x-0.5">
                    <button class="w-[32px] h-[32px] flex justify-center items-center bg-[#00000026] rounded-full">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M1.62582 7H1.6333"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.61922 12H1.62671"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M1.63216 2H1.63965"
                                    stroke="white"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="absolute bottom-3 flex items-center justify-between w-[calc(100%-20px)]">
                <div class="flex gap-x-2.5">
                    <div class="w-[40px] h-[40px] flex justify-center items-center relative">
                        <p class="absolute text-xs font-medium leading-6">80%</p>
                        <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
                            <circle cx="20" cy="20" r="18" fill="none" stroke="#FFFFFF35" stroke-width="4" />
                            <circle
                                    class="percent fifty"
                                    cx="20"
                                    cy="20"
                                    r="18"
                                    fill="none"
                                    stroke="#FFFFFF"
                                    stroke-width="4"
                                    pathLength="100"
                                    stroke-dasharray="100"
                                    stroke-dashoffset="20"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <button class="h-[220px] w-full flex flex-col justify-center items-center gap-y-2">
            <img src="/assets/common/PlusCircleGreen.svg" alt="PlusCircle" class="stroke-white" />

            <p class="text-base text-brand-500">Add New</p>
        </button>
    </div>
</section>
