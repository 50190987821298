
<div class="bg-gray-200 border border-[#E6E6E6] shadow-md" [class.rounded-[50px]]="!isOpen" [class.rounded-[25px]]="isOpen">
    <div class="px-2 py-3 flex justify-between gap-x-2" (click)="isOpen=!isOpen">
        <div class="flex justify-center">
            <!-- ajouter par exemple la classe skip quand c'est skip pour changer l'etat du label' -->
            <div class="round min-w-[24px] max-h-[24px]">
            <input type="checkbox" [(ngModel)]="task.state" id="checkbox">
            <label for="checkbox"></label>
        </div>
        </div>
        <p class="grow text-black text-[15px] font-semibold items-center checked">{{task.title}}</p>
        <div class="flex items-center gap-x-1.5">
            <div class="max-w-fit bg-[#EFF1F0] rounded-full px-2"> 
                <p class="text-[10px] leading-[20px] text-black whitespace-nowrap ">{{task.state}} {{task.skipDate | date: 'dd/MM/yy'}} {{task.doneDate | date: 'dd/MM/yy'}}</p>
            </div>

            <button>
                <img src="./assets/common/arrow-up.svg" alt="arrow" class="w-[24px] h-[24px] min-w-[24px]" [class.rotate-180]="!isOpen">
            </button>
        </div>
    </div>

    <div *ngIf="isOpen" class="pl-10 pr-3 pb-3">
        <!-- <vw-todo-item></vw-todo-item>
        <vw-todo-item></vw-todo-item> -->
        <div class="flex items-center gap-x-1">
            <p class="text-[15px] leading-[21px] font-medium">{{task.description}}</p>
        </div>
    </div>
</div>