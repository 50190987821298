import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DotMenuItem} from '../../../@commons/classes/dot-menu-item';
import {DotButtonComponent} from '../../../@commons/components/@generic/buttons/dot-button/dot-button.component';
import {UserApi} from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import {UserDto} from "vegiwise-sdk-typescript/dist/model/@common/user";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import {UploadFileRes} from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";
import {DmsApi} from "vegiwise-sdk-typescript/dist/sdk/dms/dms.api";
import {DmsService} from "../../../@commons/services/vegiwise/dms.service";
import {TranslateModule} from "@ngx-translate/core";
import * as moment from "moment";
import {AuthApi} from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import {Router} from "@angular/router";
import {WebViewBridgeService} from "../../../@commons/services/webview/webview-bridge.service";
import {WebViewAction} from "../../../@commons/enums/webview-action";

@Component({
  selector: 'vw-user-summary',
  standalone: true,
  imports: [CommonModule, DotButtonComponent, DotButtonComponent, TranslateModule],
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
  providers: [DmsApi, AuthApi, UserApi]
})
export class UserSummaryComponent implements OnChanges, OnInit {
  @Input() currentUser: UserDto;
  registrationDate: string;
  @ViewChild("fileInput") fileInput: ElementRef;
  file: ExternalFileDto;
  pictureUrl: string;

  selectedImage: File;

  constructor(private dmsApi: DmsApi,
              private authApi: AuthApi,
              private dmsService: DmsService,
              private webviewBridgeService: WebViewBridgeService,
              private userApi: UserApi,
              private router: Router) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.currentUser) {
      if (this.currentUser && this.currentUser.registrationDate) {
        this.registrationDate = moment(this.currentUser.registrationDate).format("DD/MM/YYYY");
      }
      if (this.currentUser && this.currentUser.profilePicture) {
        this.file = this.currentUser.profilePicture;
        this.buildPictureUrl();
      }
    }
  }


  menuItems: DotMenuItem[] = [
    /*{path: '', label: 'Partager mon Profil', style: ''},*/
    {
      path: '', label: 'SETTINGS.USER_INFO.MENU.CHANGE_PICTURE', style: '', onClick: () => {
        this.uploadProfilePicture()
    }},
    {path: '/user/settings', label: 'SETTINGS.USER_INFO.MENU.UPDATE_PROFILE', style: ''},
    {path: '/onboarding', label: 'SETTINGS.USER_INFO.MENU.PERSONALIZE_XP', style: ''},
    {path: '', label: 'SETTINGS.USER_INFO.MENU.LOGOUT', style: '', onClick: () => {
        this.logout()
      }},
  ]

  logout() {
    this.authApi.logout();
    this.router.navigate(['auth/login']);
  }

  uploadProfilePicture() {
    this.fileInput.nativeElement.click();
    /*const sub = this.webviewBridgeService.permissionAskedSubject.subscribe((res) => {
      console.log(res);
      this.fileInput.nativeElement.click();
    });
    this.webviewBridgeService.postMessageToWebview({
      payload: {},
      type: WebViewAction.askPermission
    });*/
  }

  onFileSelected(event: any) {
    this.selectedImage = event.target.files[0];
    this.previewImage(this.selectedImage);

    this.dmsApi.getPreSignedUrl().then((urlData: PreSignedUrlRes) => {
      let file = event.target.files[0];
      if (file) {
        this.dmsApi.uploadToPreSignedUrl(file, urlData).then((data: UploadFileRes) => {
          this.file = {
            fileKey: data.fileUid,
            fileOrigin: ExternalFileOrigin.USER,
            name: this.selectedImage.name,
            bucketId: '',
            scope: '',
            contentType: this.selectedImage.type
          }
          this.buildPictureUrl();
          this.currentUser.profilePicture = this.file;
          this.userApi.upsert(this.currentUser).then(() => {
            console.log('user updated');
          });
        }).catch((e)=> {
          console.error(e);
        });
      }
    });
  }

  buildPictureUrl() {
    if(this.file && this.file.fileKey) {
      this.pictureUrl = `url(${this.dmsService.getFileUrl(this.file.fileKey, null, 50)})`;
    } else {
      this.pictureUrl = null;
    }
  }

  previewImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.pictureUrl = `url(${e.target.result.toString()})`;
    };
    reader.readAsDataURL(file);
  }

  get selectedImageSize(): string {
    // If less than 1 MO => display in KO
    if (this.selectedImage && this.selectedImage.size < 1000000) {
      return `${Math.round(this.selectedImage.size / 1000)} ko`;
    }
    // If more than 1 MO => display in MO
    if (this.selectedImage && this.selectedImage.size >= 1000000) {
      return `${Math.fround(this.selectedImage.size / 1000000).toFixed(1)} Mo`;
    }
    return "";
  }
}
