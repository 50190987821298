<div [style.background-image]="cultivationPictureUrl"
     [style.background-size]="'auto 65px'"
     [style.background-color]="backgroundColor"
     class="h-36 p-2 bg-no-repeat  flex flex-col  bg-center relative rounded-[7px] overflow-hidden text-white">
    <ng-container *ngTemplateOutlet="cultivationBody"></ng-container>
    <img src="/assets/imgs/shadow.png" alt="avatar" class="absolute bottom-[10px] left-[20px] w-[80%] rounded-full"/>
</div>

<ng-template #cultivationBody>
    <div class="flex items-start">
        <div class="flex items-center justify-between w-full">
            <p class="mr-2 text-xs font-bold leading-4">{{cultivation.name | Label}}</p>
            <vw-dot-button theme="white" *ngIf="showMenu" [menuItems]="dotMenuItems"></vw-dot-button>
        </div>
        <div class="absolute bottom-2 right-2 w-[calc(100%-16px)] ">
            <div class="flex justify-end gap-x-2.5 w-full">
                <p class="text-xs font-medium leading-6">{{'SETTINGS.STOCK.MY_SEEDS.ITEM.QUANTITY' | translate }}</p>
                <vw-cultivation-stock-level [readonly]="true"></vw-cultivation-stock-level>
            </div>
        </div>
    </div>
</ng-template>
