<button *ngIf="style == 'COLOR'"
        class="uppercase text-xs leading-6 text-white flex items-center gap-x-1.5 py-1 px-3 rounded-full bg-gradient-to-r from-[#FFC839] to-[#E0A100]"
>
    {{ 'COMMON.UPGRADE' | translate }}
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M1.55556 9L0 0.75L4.27778 4.5L7 0L9.72222 4.5L14 0.75L12.4444 9H1.55556ZM12.4444 11.25C12.4444 11.7 12.1333 12 11.6667 12H2.33333C1.86667 12 1.55556 11.7 1.55556 11.25V10.5H12.4444V11.25Z"
                fill="white"
        />
    </svg>
</button>
<button *ngIf="style == 'LIGHT'"
        class="uppercase text-[10px] leading-[20px] tracking-[0.25px] font-medium px-2 py-1 flex items-center gap-x-1 bg-white rounded-full"
>
    <img src="/assets/common/crown-2 1.svg" alt="crown" class="min-w-[20px] min-h-[20px]" />
    Infos
</button>
