import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-question-progression',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './question-progression.component.html',
  styleUrls: ['./question-progression.component.scss']
})
export class QuestionProgressionComponent implements OnChanges, OnInit{
  @Input() index: number = 0;
  @Input() total: number = 10;

  indexString: string;
  totalString: string;
  percent: number;

  classes: string = 'w-[85%]';

  constructor() {
  }

  ngOnInit() {
    this.compute();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && (changes.index || changes.total)) {
      this.compute();
      console.log('computed');
    }
  }

  compute() {
    this.percent = (this.index + 1) / this.total * 100;
    this.indexString = (this.index + 1).toString();
    this.totalString = this.total.toString();
    this.classes = 'w-['+this.percent+'%]';
  }
}
