import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultLayoutComponent } from "../../@commons/layouts/default-layout/default-layout.component";
import { AppDetailsHeaderComponent } from "../../@commons/components/app-details-header/app-details-header.component";
import { CultivationHorizontalViewerComponent } from "../../@commons/components/cultivation-horizontal-viewer/cultivation-horizontal-viewer.component";
import { GrowingStepsViewerComponent } from "../../@commons/components/growing-steps-viewer/growing-steps-viewer.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GrowingStepApi } from "vegiwise-sdk-typescript/dist/sdk/plant/growing-step.api";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { PlantListHorizontalViewerComponent } from "../../@commons/components/plant-list-horizontal-viewer/plant-list-horizontal-viewer.component";
import { PlantMock } from "vegiwise-sdk-typescript/dist/mock/plant/plant.mock";
import { PlantApi } from "vegiwise-sdk-typescript/dist/sdk/plant/plant.api";
import { PagingResponse } from "../../@commons/services/base.service";
import { StorageService } from "../../@commons/services/front/storage.service";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { PlantDto } from "vegiwise-sdk-typescript/dist/model/@common/plant";
import { ListPlantRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-plant-req";
import { TranslateModule } from "@ngx-translate/core";
import { CreatePlantReq } from "vegiwise-sdk-typescript/dist/model/plant/plant/create-plant-req";
import { SimpleButtonComponent } from "../../@commons/components/@generic/buttons/simple-button/simple-button.component";
import { PlantToGrowingStepApi } from "vegiwise-sdk-typescript/dist/sdk/plant/plant-to-growing-step.api";
import { ListPlantToGrowingStepRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-plant-to-growing-step-req";
import { PlantToGrowingStepDto } from "vegiwise-sdk-typescript/dist/model/@common/plant-to-growing-step";
import { ModalService } from "../../@commons/services/modal/modal.service";
import { AskQuestionsModalComponent } from "../../@commons/modals/ask-questions-modal/ask-questions-modal.component";
import { ModalRef } from "../../@commons/services/modal/model-ref";
import { QuestionDto } from "vegiwise-sdk-typescript/dist/model/@common/question";
import { GrowingStepDto } from "vegiwise-sdk-typescript/dist/model/@common/growing-step";
import * as moment from "moment";
import { DmsService } from "src/app/@commons/services/vegiwise/dms.service";
import { FileUtils } from "src/app/@commons/utils/file-utils";

@Component({
  selector: "vw-cultivation-plants",
  standalone: true,
  imports: [
    CommonModule,
    DefaultLayoutComponent,
    AppDetailsHeaderComponent,
    CultivationHorizontalViewerComponent,
    GrowingStepsViewerComponent,
    PlantListHorizontalViewerComponent,
    TranslateModule,
    SimpleButtonComponent,
  ],
  templateUrl: "./cultivation-plants.component.html",
  styleUrls: ["./cultivation-plants.component.scss"],
  providers: [CultivationApi, PlantToGrowingStepApi, StorageService, PlantApi],
})
export class CultivationPlantsComponent implements OnInit, OnChanges {
  @Input() cultivation: CultivationDto;
  @Input() growingStep: GrowingStepDto;
  @Input() growingSteps: GrowingStepDto[];
  @Input() plantToGrowingStep!: PlantToGrowingStepDto;
  selectedStepIndex: number;
  progressionPercentage: number;
  userGrowingStepsToPlant: PlantToGrowingStepDto[] =
    []; /*[new PlantMock().generate(), new PlantMock().generate(), new PlantMock().generate()]*/
  cultivationPictureUrl: string = "";
  backgroundColorClass: string;
  loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
    private cultivationApi: CultivationApi,
    private plantToGrowingStepApi: PlantToGrowingStepApi,
    private modalService: ModalService,
    private dmsService: DmsService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const uid = params["cultivationUid"];
      this.load(uid);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && (changes["growingSteps"] || changes["growingStep"])) {
      this.calculateProgression();
    }
    if (this.cultivation) {
      const fileUid: string = FileUtils.getFileUidFromCollection(
        this.cultivation.files,
        null
      );
      if (fileUid) {
        this.cultivationPictureUrl = `url(${this.dmsService.getFileUrl(
          fileUid,
          null,
          300
        )})`;
      }
    }
  }
  ngOnInit() {
    this.calculateProgression();
  }
  calculateProgression() {
    if (this.growingSteps) {
      let totalDaysForCultivation = 0;
      let actualDaysDuration = 0;
      let actualStepPassed = false;
      this.growingSteps.forEach((gs) => {
        totalDaysForCultivation += gs.durationDays;
        if (!actualStepPassed) {
          if (gs.uid === this.growingStep.uid) {
            if (this.plantToGrowingStep) {
              actualDaysDuration += Math.abs(
                moment(this.plantToGrowingStep.startDate)
                  .subtract(1, "day")
                  .diff(new Date(), "day")
              );
            }
            actualStepPassed = true;
          } else {
            actualDaysDuration += gs.durationDays;
          }
        }
      });
      this.progressionPercentage = Math.floor(
        (actualDaysDuration / totalDaysForCultivation) * 100
      );
    }
  }

  load(uid: string) {
    this.loading = true;
    this.cultivationApi.findByUid(uid).then((culti: CultivationDto) => {
      this.cultivation = culti;
    });
    const listPlantRequest: ListPlantToGrowingStepRequest =
      new ListPlantToGrowingStepRequest();
    listPlantRequest.cultivationUid = uid;
    this.plantToGrowingStepApi
      .list(listPlantRequest, 0, 20, this.storageService.getCurrentLanguage())
      .then((plants: PagingResponse<PlantToGrowingStepDto>) => {
        this.userGrowingStepsToPlant = plants.results;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  goToCultivationDetails(cultivation: CultivationDto) {
    this.router.navigate([`cultivations/${cultivation.uid}/details`]);
  }

  addPlantToUser($event: MouseEvent) {
    this.plantToGrowingStepApi
      .getQuestionsForUser(this.cultivation.uid)
      .then((res) => {
        console.log("GOT QUESTIONS", res);
        const modal: ModalRef = this.modalService.open(
          AskQuestionsModalComponent,
          res
        );

        modal.onResult().subscribe((questions: QuestionDto[]) => {
          let currentStepUid = null;
          if (questions) {
            const index = questions.findIndex(
              (q) => q.id === "QUESTION.CURRENT_GROWING_STEP"
            );
            if (index >= 0 && questions[index].selectedAnswer) {
              currentStepUid = questions[index].selectedAnswer.id;
            }
          }

          const createRequest: CreatePlantReq = new CreatePlantReq();
          createRequest.cultivationUid = this.cultivation.uid;
          createRequest.locationUid = null;
          createRequest.currentStepUid = currentStepUid; //TODO ask user
          createRequest.metadata = JSON.stringify(questions);
          this.loading = true;
          this.plantToGrowingStepApi
            .addPlantForUser(createRequest)
            .then((res) => {
              this.userGrowingStepsToPlant.push(res);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      });
  }
}
