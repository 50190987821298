import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CultivationPeriodMonthComponent} from "./cultivation-period-month/cultivation-period-month.component";
import * as moment from "moment";
import {CultivationPeriodLabelComponent} from "./cultivation-period-label/cultivation-period-label.component";
import {CultivationPeriodDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";
import {TranslateModule} from "@ngx-translate/core";

export class PeriodByMonth {
  month: string;
  periods: CultivationPeriodDto[] = [];
}

@Component({
  selector: 'vw-cultivation-periods-viewer',
  standalone: true,
  imports: [CommonModule, CultivationPeriodMonthComponent, CultivationPeriodLabelComponent, TranslateModule],
  templateUrl: './cultivation-periods-viewer.component.html',
  styleUrls: ['./cultivation-periods-viewer.component.scss']
})
export class CultivationPeriodsViewerComponent implements OnInit, OnChanges {
  @Input() periods: CultivationPeriodDto[];
  groupedSimilarPeriods: CultivationPeriodDto[][];
  periodsByMonths: PeriodByMonth[];
  months: string[] = moment.months();

  ngOnInit() {
    this.initPeriods()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initPeriods();
  }

  initPeriods() {
    const $periodsByMonths: PeriodByMonth[] = [];
    if(this.periods) {

      //group period with same type together
      this.groupedSimilarPeriods = [];
      this.periods.forEach((period: CultivationPeriodDto) => {
        const index : number= this.groupedSimilarPeriods.findIndex(periods => periods.length && periods[0].type === period.type);
        if (index !== -1) {
          this.groupedSimilarPeriods[index].push(period);
        } else {
          this.groupedSimilarPeriods.push([period]);
        }
      });

      this.months.forEach((month: string, i: number) => {
        const monthPeriod = new PeriodByMonth();
        monthPeriod.month = this.months[i];
        monthPeriod.periods = [];
        $periodsByMonths.push(monthPeriod);
        this.periods.forEach((period) => {
          if(period.startDate && period.endDate) {
            const startD  = moment(period.startDate, 'YYYY-MM-DD');
            const endD  = moment(period.endDate, 'YYYY-MM-DD');
            if (i >= (startD.get('month')) || i <= (endD.get('month'))) {
              if ($periodsByMonths[i].periods.findIndex(p => p.type === period.type) === -1) {
                $periodsByMonths[i].periods.push(period); //todo verify that i and month both start on 0
              }
            }
          }
        });
      });
    }
    this.periodsByMonths = $periodsByMonths;
    console.log(this.periodsByMonths);
  }
}
