<div *ngIf="!readonly" class="flex items-center gap-x-3">
    <button class="bg-[#ED880D] h-[28px] w-[24px] flex items-center justify-center rounded-lg" (click)="decreaseClicked()">
        <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.37166" y1="0.691086" x2="8.7356" y2="0.691086" stroke="white" stroke-width="1.31461" stroke-linecap="round"></line>
        </svg>
    </button>
    <div class="flex items-center gap-x-1">
        <div class="w-[6px] h-5 bg-[#7E7E7E]" *ngFor="let level of quantity" [class.opacity-40]="level >= currentQuantity"></div>
    </div>
    <button class="bg-brand-500 h-[28px] w-[24px] flex items-center justify-center rounded-lg" (click)="increaseClicked()">
        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.26326" y1="0.927811" x2="4.26326" y2="8.64369" stroke="white" stroke-width="1.31461" stroke-linecap="round"></line>
            <line x1="0.756668" y1="4.63017" x2="7.33165" y2="4.63017" stroke="white" stroke-width="1.31461" stroke-linecap="round"></line>
        </svg>
    </button>
</div>

<ng-container *ngIf="readonly">
    <div class="flex items-center h-full gap-x-1">
        <div class="w-1 h-4 bg-white rounded-md" *ngFor="let level of quantity" [class.opacity-40]="level >= currentQuantity"></div>
    </div>
</ng-container>