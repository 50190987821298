import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TodoItemComponent} from './todo-item/todo-item.component';
import {TaskDto} from "vegiwise-sdk-typescript/dist/model/@common/task";

@Component({
  selector: 'vw-todo-group',
  standalone: true,
  imports: [CommonModule, TodoItemComponent, FormsModule],
  templateUrl: './todo-group.component.html',
  styleUrls: ['./todo-group.component.scss']
})
export class TodoGroupComponent {
  @Input() color: string
  isOpen: boolean = false;
  @Input() task: TaskDto;
}
