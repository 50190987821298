import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuestionDto} from "vegiwise-sdk-typescript/dist/model/@common/question";

@Component({
  selector: 'vw-question-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './question-footer.component.html',
  styleUrls: ['./question-footer.component.scss']
})
export class QuestionFooterComponent {

  @Input() question: QuestionDto;
  @Output() skip: EventEmitter<QuestionDto> = new EventEmitter<QuestionDto>();
  @Output() validate: EventEmitter<QuestionDto> = new EventEmitter<QuestionDto>();
  @Output() previous: EventEmitter<QuestionDto> = new EventEmitter<QuestionDto>();

  onSkip($event) {
    this.skip.emit(this.question);
  }

  onPrevious($event) {
    this.previous.emit($event)
  }

  confirm($event) {
    this.validate.emit(this.question);
  }
}
