<div class="h-screen absolute top-0 bg-black bg-opacity-60 w-full z-[999]">
    <div class="absolute w-full px-5 bottom-4">
        <div class="bg-white p-4 rounded-lg w-full space-y-4">
            <div class="bg-white border border-[#D6D6D6] p-2.5 rounded-lg">
                <!--<img src="./assets/common/Feedback.png" alt="image" class="w-[125px] h-[125px] mx-auto">-->
                <div class="w-full h-[12px]"></div>
                <h4 class="text-black text-[13px] leading-[18px] font-bold">{{'MODALS.DELETE.TITLE' | translate}}</h4>
                <div class="w-full h-[30px]"></div>
                <button class="py-3 w-full bg-gray-400 rounded-lg text-black text-sm font-bold" (click)="onCancel($event)">{{'MODALS.DELETE.CANCEL' | translate}}</button>
            </div>

        </div>
    </div>
</div>
