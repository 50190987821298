import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";

import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { HeaderFooterService } from "../../@commons/services/display/header-footer.service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgFor, NgIf } from "@angular/common";
import { PagingResponse } from "../../@commons/services/base.service";
import { WarningListComponent } from "./warning-list/warning-list.component";
import { WeatherInfoBlockComponent } from "./weather-info-block/weather-info-block.component";
import { WebViewAction } from "../../@commons/enums/webview-action";
import { WebViewBridgeService } from "../../@commons/services/webview/webview-bridge.service";
import { CultivationItemComponent } from "./cultivation-item/cultivation-item.component";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { ListCultivationRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";

import * as Hammer from "hammerjs";
import { HammerGestureConfig, HammerModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { WarningListItemComponent } from "./warning-list/warning-list-item/warning-list-item.component";
import { LabelPipe } from "../../@commons/pipes/LabelPipe";
import { ProgressBarComponent } from "../../@commons/components/@generic/progress-bar/progress-bar.component";
import { PlusIconComponent } from "../../@commons/icons/plus-icon/plus-icon.component";
import { ModalRef } from "../../@commons/services/modal/model-ref";
import { UpgradePremiumModalComponent } from "../../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import { ModalService } from "../../@commons/services/modal/modal.service";
import { AuthApi } from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import { DeleteItemModalComponent } from "../../@commons/modals/delete-item-modal/delete-item-modal.component";
import { CultivationToUserApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation-to-user.api";
import { LoaderComponent } from "../../@commons/components/@generic/loaders/loader/loader.component";
import { AddListCultivationsModalComponent } from "src/app/@commons/modals/add-list-cultivations-modal/add-list-cultivations-modal.component";
import { StorageService } from "src/app/@commons/services/front/storage.service";

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pan: { direction: Hammer.DIRECTION_ALL },
    swipe: { direction: Hammer.DIRECTION_VERTICAL },
  };
}

@Component({
  selector: "vw-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
  standalone: true,
  imports: [
    NgFor,
    CultivationItemComponent,
    WarningListComponent,
    WeatherInfoBlockComponent,
    RouterModule,
    InfiniteScrollModule,
    HammerModule,
    TranslateModule,
    NgIf,
    WarningListItemComponent,
    LabelPipe,
    ProgressBarComponent,
    PlusIconComponent,
    LoaderComponent,
  ],
  providers: [
    CultivationApi,
    AuthApi,
    CultivationToUserApi,
    {
      provide: HammerGestureConfig,
      useClass: MyHammerConfig,
    },
  ],
})
export class HomePageComponent implements OnInit {
  cultivations: CultivationDto[] = [];
  warnings: any[] = [{}, {}];
  cultivationPairs: Array<CultivationDto[]> = [];
  page: number = 0;
  pageSize: number = 6;
  totalCultivations: number;
  loading;
  understood: boolean = false;

  constructor(
    private router: Router,
    private cultivationApi: CultivationApi,
    private headerFooterService: HeaderFooterService,
    private WebviewBridgeService: WebViewBridgeService,
    private modalService: ModalService,
    private authApi: AuthApi,
    private cultivationToUserApi: CultivationToUserApi,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.loading = true;
    //TODO: remove this its just for testing purpose
    this.WebviewBridgeService.postMessageToWebview({
      type: WebViewAction.signIn,
      payload: "jwToken",
    });
    this.loadCultivations();
    this.loadWarnings();
    this.understood = this.storageService.getItem("understood") ? true : false;
  }

  divideArray<T>(array: T[]): [T[], T[]] {
    const middle = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middle);
    const secondHalf = array.slice(middle);
    return [firstHalf, secondHalf];
  }

  goToCultivationDetails(cultivation: CultivationDto) {
    this.router.navigate([`cultivations/${cultivation.uid}/plants`]);
  }

  loadMore($event) {
    if (this.cultivations.length < this.totalCultivations) {
      this.page += 1;
      this.loadCultivations();
    }
  }

  loadWarnings() {
    //TODO get warnings from the db
    this.warnings = [{}, {}, {}, {}];
  }

  loadCultivations() {
    const filters: ListCultivationRequest = new ListCultivationRequest();
    filters.usedByUserUid = this.authApi.getSub();
    this.cultivationApi
      .list(filters, this.page, this.pageSize, "fr")
      .then((cultivations: PagingResponse<CultivationDto>) => {
        this.cultivations.push(...cultivations.results);
        this.totalCultivations = cultivations.page.totalElements;
        this.cultivationPairs.push(
          ...this.divideArray<CultivationDto>(cultivations.results)
        );
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  onScroll($event) {
    this.headerFooterService.onScroll($event.target, $event.target.scrollTop);
  }

  onSwipe(none: string) {}

  onAddPlantClicked($event: MouseEvent) {
    this.router.navigate([`cultivations/select`]);
  }

  onDeleteCultivationFromUserList(cultivation: CultivationDto) {
    const modal: ModalRef = this.modalService.open(DeleteItemModalComponent);
    modal.onResult().subscribe((res) => {
      if (res && res.deleteConfirmed) {
        this.removeCultivationFromLists(cultivation);
        this.cultivationToUserApi
          .deleteCultivationsFromUserList(cultivation.uid)
          .then(() => {})
          .catch((e) => {
            //TODO open error modal
          });
      }
    });
  }

  removeCultivationFromLists(cultivation: CultivationDto) {
    this.cultivations.splice(
      this.cultivations.findIndex((c) => {
        return c.uid === cultivation.uid;
      }),
      1
    );
    this.totalCultivations--;
    this.cultivationPairs = this.divideArray(this.cultivations);
  }

  understoodClicked() {
    this.storageService.setItem("understood", "true");
    this.understood = true;
  }
}
