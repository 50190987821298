<div
  class="relative bg-white z-50 pt-2 pb-3 px-4 text-center grid grid-cols-5 gap-x-5 w-full h-[80px] shadow-[0_-1px_40px_0_rgba(3,21,24,0.09)]"
>
  <vw-app-menu-item
    class="menu-item"
    [path]="'/home'"
    [label]="'MENU.HOME.LABEL'"
    [logoPath]="'/assets/common/home-05.svg'"
  >
  </vw-app-menu-item>
  <vw-app-menu-item
    class="menu-item"
    [path]="'/tasks'"
    [label]="'MENU.TASK.LABEL'"
    [logoPath]="'/assets/common/file-verified.svg'"
  >
  </vw-app-menu-item>
  <vw-app-menu-item
    class="menu-item"
    [path]="'/map'"
    [disabled]="true"
    [label]="'MENU.MAP.LABEL'"
    [logoPath]="'/assets/common/map.svg'"
  >
  </vw-app-menu-item>
  <vw-app-menu-item
    class="menu-item"
    [path]="'/social'"
    [disabled]="true"
    [label]="'MENU.SOCIAL.LABEL'"
    [logoPath]="'/assets/common/Like.svg'"
  >
  </vw-app-menu-item>
  <vw-app-menu-item
    class="menu-item"
    [path]="'/settings'"
    [label]="'MENU.SETTINGS.LABEL'"
    [logoPath]="'/assets/common/setting-01.svg'"
  >
  </vw-app-menu-item>
</div>
