import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CalendarComponent} from '../../@commons/components/calendar/calendar.component';
import {JsonPipe, NgForOf} from '@angular/common';
import * as moment from 'moment';
import {TranslateModule} from '@ngx-translate/core';
import {
    CircleButtonComponent
} from '../../@commons/components/@generic/buttons/circle-button/circle-button.component';
import {
    WarningListItemComponent
} from "../../home/home-page/warning-list/warning-list-item/warning-list-item.component";
import {TaskApi} from "vegiwise-sdk-typescript/dist/sdk/plant/task.api";
import {TaskTypeTasksComponent} from "./period-tasks/task-type-tasks.component";
import {CalendarResponse} from "vegiwise-sdk-typescript/dist/model/task/calendar-res";

@Component({
    selector: 'vw-calendar-home',
    templateUrl: './calendar-home.component.html',
    styleUrls: ['./calendar-home.component.scss'],
    standalone: true,
    providers: [TaskApi],
    imports: [FormsModule, CalendarComponent, NgForOf, TranslateModule, TaskTypeTasksComponent,
        CircleButtonComponent, WarningListItemComponent, JsonPipe]
})
export class CalendarHomeComponent implements OnInit {

    months: string[] = moment.months();
    public selectedMonth: string;

    public monthTasks: any[];

    constructor(private calendarApi: TaskApi) {
    }

    ngOnInit(): void {
        const currentMonth: number = moment().get('month');
        this.selectedMonth = this.months[currentMonth];
        this.loadTasks();
    }

    public selectMonth(month: string, event: MouseEvent) {
        this.selectedMonth = month;
        this.loadTasks();
        console.log(event);
    }

   public loadTasks() {
        //this.monthTasks = [{test: 'ici'}, {}]
        const currentMonth = this.months.indexOf(this.selectedMonth);
        this.calendarApi.calendar(currentMonth).then((res: CalendarResponse) => {
            this.monthTasks = res.monthTasks;
        });
    }
}
