<div class=" bottom-5 bg-white py-2.5 px-5 z-50 w-full flex justify-between items-center">

    <button class="p-[9px] rounded-[9px] bg-neutral-100 flex items-center justify-center" (click)="onPrevious($event)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M 14.5 7 L 10.5 12 L 14.5 17" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>

    <button class="text-[15px] leading-6 font-medium px-[18px] py-3 bg-neutral-100 rounded-[9px]" (click)="onSkip($event)">Ignorer</button>
</div>


<!--
<div class="absolute bottom-5 bg-white py-2.5 px-5 z-50 w-full flex justify-between items-center">

<button class="text-[15px] leading-6 font-medium px-[18px] py-3 bg-[#F5F6F8] rounded-[9px]" (click)="onPrevious($event)">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M 19.5 7 L 14.5 12 L 19.5 17" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
</button>

<button class="p-[9px] rounded-[9px] bg-brand-500 flex items-center justify-center" (click)="onSkip($event)">Ignorer</button>

</div>
-->