<div class="bg-[#BE4A3A] absolute bottom-0 left-0 w-full h-1/4 justify-center items-center rounded p-2.5">
    <div class="relative">
        <div class="flex items-center gap-x-1 pb-1">
            <img src="../../../../../assets/common/medical-file.svg" alt="medical-file" />
            <p class="text-[13px] leading-[21px] font-medium" skeleton>Action Required!</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class=" absolute top-0 right-0 w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>

        </div>
        <p class="text-[11px] font-light leading-[13px]" skeleton>Lorem ipsum dolor sit amet...</p>
    </div>


</div>

