import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";

@Component({
  selector: 'vw-link',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() href: string;
  @Input() label: string;
  @Input() routerLink: any[] | string | null | undefined
}
