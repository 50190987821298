<svg
        class="w-[18px] h-[18px] min-w-[18px]"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
>
    <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 2.56262C12.8318 2.56262 15.9375 5.66912 15.9375 9.50012C15.9375 13.3311 12.8318 16.4376 9 16.4376C5.169 16.4376 2.0625 13.3311 2.0625 9.50012C2.0625 5.66912 5.169 2.56262 9 2.56262Z"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
    <path
            d="M8.99634 6.6532V9.96745"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
    <path
            d="M8.99625 12.347H9.00375"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
</svg>