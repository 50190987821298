<section class="h-full overflow-auto pb-3"
         infiniteScroll
         (scrolled)="loadMore($event)"
         (scroll)="onScroll($event)"
         (scrolledUp)="onScrollUp($event)"
         (scrollend)="onScrollEnd($event)"
         [scrollWindow]="false" >

    <div class="pb-2">
        <div class="z-[9999999] p-3 sticky top-0 bg-white shadow-md mb-4">
            <h3 class="font-bold uppercase">Previous tasks</h3>
        </div>
        <ng-container *ngFor="let task of previousTasks">
            <vw-todo-group [task]="task" class="block pb-4 px-4 md:px-6"></vw-todo-group>
        </ng-container>
    </div>

 <div id="targetDiv" #targetDiv class="pb-2">
        <div class="z-[9999999] p-3 sticky top-0 bg-white shadow-md mb-4">
            <h3 class="font-bold uppercase">Current tasks</h3>
        </div>
        <ng-container *ngFor="let task of currentTasks">
            <vw-todo-group [task]="task" class="block pb-4 px-4 md:px-6"></vw-todo-group>
        </ng-container>
    </div>



    <div class="pb-2">
        <div class="z-[9999999] p-3 sticky top-0 bg-white shadow-md mb-4">
            <h3 class="font-bold uppercase">Future tasks</h3>
        </div>
        <ng-container *ngFor="let task of futureTasks">
            <vw-todo-group [task]="task" class="block pb-4 px-4 md:px-6"></vw-todo-group>
        </ng-container>
    </div>

</section>
