<vw-default-layout [bodyTemplate]="bodyT" [footerTemplate]="footerT" [headerAdditionalComponent]="headerAdditionalComponents">
    <ng-template #headerAdditionalComponents>
        <button class="text-black h-[40px] w-[40px] rounded-full bg-white flex justify-center items-center" (click)="skipAll()">
            <vw-cross-cancel-icon></vw-cross-cancel-icon>
        </button>
    </ng-template>
    <ng-template #bodyT>
        <section class="px-5" *ngIf="questions && questions.length">
            <vw-question-progression [index]="currentQuestionIndex" [total]="questions.length" ></vw-question-progression>
            <vw-question-asker *ngIf="currentQuestion" (respond)="onResponseSelected($event)" [question]="currentQuestion"></vw-question-asker>
        </section>
    </ng-template>

    <ng-template #footerT>
        <vw-question-footer (skip)="onSkip($event)" (previous)="previous()" (validate)="onValidate($event)"></vw-question-footer>
    </ng-template>

</vw-default-layout>
