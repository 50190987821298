import {ComponentRef, ElementRef, Injectable, Type} from "@angular/core";
import {ModalContainerComponent} from "./modal-container/modal-container.component";
import {ModalRef} from "./model-ref";
import {Modal} from "./modal";

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private modalContainer: ModalContainerComponent;

    constructor() {}

    registerContainer(modalContainer: ModalContainerComponent): void {
        this.modalContainer = modalContainer
    }

    open<T extends Modal>(component: Type<T>, data?: any): ModalRef {
        if (!this.modalContainer) {
            console.error('NO MODAL CONTAINER INITIALIZED');
        } else {
            const modalComponentRef: ComponentRef<T> = this.modalContainer.createModal(component);
            modalComponentRef.instance.onOpen();

            if (data) {
                modalComponentRef.instance.onInjectInputs(data);
            }
            return new ModalRef(this.modalContainer, modalComponentRef);
        }
    }

}