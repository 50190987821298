<section>
    <!-- cards -->
    <div class="grid grid-cols-3 gap-2 p-3 overflow-y-auto" infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="500"
         (scrolled)="loadMore($event)"
         (scroll)="onScroll($event)"
         [scrollWindow]="false">
        <vw-cultivation-settings-item class="space-y-1.5 "  [cultivation]="cultivation" [showMenu]="false"
                                      *ngFor="let cultivation of cultivations" (click)="goToCultivationDetails(cultivation)"
        ></vw-cultivation-settings-item>
    </div>
</section>