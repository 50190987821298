<vw-default-layout [bodyTemplate]="bodyT" [headerTemplate]="header">
  <ng-template #header>
    <vw-app-details-header></vw-app-details-header>
  </ng-template>
  <ng-template #bodyT>
    <section class="p-4">
      <vw-cultivation-horizontal-viewer
        [cultivation]="cultivation"
      ></vw-cultivation-horizontal-viewer>
      <div class="w-full h-[16px]"></div>
      <vw-cultivation-periods-viewer
        *ngIf="cultivation"
        [periods]="periods"
      ></vw-cultivation-periods-viewer>
      <div class="w-full h-[16px]"></div>
      <div
        class="bg-white rounded-lg border border-[#D0D0D0] px-3 py-3 flex items-center justify-between"
      >
        <div>
          <p class="text-[#202939] text-[13px] tracking-[0.24px]" skeleton>
            {{ cultivation?.description | Label }}
          </p>
        </div>
      </div>
      <div class="w-full h-[16px]"></div>
      <div
        class="bg-white rounded-lg border border-[#D0D0D0] px-3 py-3 flex items-center justify-between"
      >
        <div>
          <h4 class="text-base font-bold text-[#1A0700]">
            {{ "CULTIVATIONS.DETAILS.QUANTITY.LABEL" | translate }}
          </h4>
          <p class="text-xs text-[#202939] tracking-[0.24px]">
            {{ "CULTIVATIONS.DETAILS.QUANTITY.DESCRIPTION" | translate }}
          </p>
        </div>
        <div class="flex items-center gap-x-3">
          <vw-cultivation-stock-level
            [(currentQuantity)]="userPreferences.stockLevel"
            (dataChanged)="updated($event)"
          ></vw-cultivation-stock-level>
        </div>
      </div>
      <div class="w-full h-[16px]"></div>
      <!--<vw-cultivation-evaluation-card class="py-4" [(stars)]="userPreferences.rate" (dataChanged)="updated($event)"></vw-cultivation-evaluation-card>-->
    </section>
  </ng-template>
</vw-default-layout>
