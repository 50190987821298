<div
  class="basis-2/3 px-[15px] py-[12px] bg-[#475569] space-y-2 rounded-lg shadow-[0_4px_6px_-2px_rgba(0,0,0,0.4)]"
>
  <div class="flex justify-between items-center gap-x-2">
    <div class="space-y-1 flex justify-between">
      <p class="text-[24px] font-light text-white">
        <img
          [src]="weatherIconUrl"
          alt="partly_cloudy"
          class="w-[45px] h-[45px] inline"
          skeleton
        />
        <span skeleton> {{ weatherSummary?.temperature }}ºC </span>
      </p>
    </div>
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-x-[5px]">
        <img
          src="/assets/common/arrow-down-02-sharp.svg"
          alt="arrow-down-02-sharp"
          skeleton
        />
        <p
          class="font-['SF_Pro_Display',_sans-serif] text-white text-xs"
          skeleton
        >
          Min. {{ weatherSummary?.minimalTemperature }}ºC
        </p>
      </div>
      <div class="flex items-center gap-x-[5px]">
        <img
          src="/assets/common/arrow-up-02-sharp.svg"
          alt="arrow-up-02-sharp"
          skeleton
        />

        <p
          class="font-['SF_Pro_Display',_sans-serif] text-white text-xs"
          skeleton
        >
          Max. {{ weatherSummary?.maximalTemperature }}°C
        </p>
      </div>
    </div>
  </div>

  <div
    class="py-2 border-y-[0.5px] border-white border-opacity-20 flex items-center gap-x-2"
  >
    <img
      src="/assets/common/droplet.svg"
      alt="droplet"
      class="min-w-[16px] min-h-[16px]"
      skeleton
    />
    <div>
      <p class="text-sm text-white font-light tracking-[0.25px]" skeleton>
        {{
          "HOME.WEATHER.RAIN.FORESEEN"
            | translate
              : { numberOfMillimeters: weatherSummary?.precipitationsIn24Hours }
        }}
      </p>
      <p class="text-sm text-white font-light tracking-[0.25px]" skeleton>
        {{
          "HOME.WEATHER.RAIN.NEXT_TIME"
            | translate
              : {
                  time: weatherSummary?.timeBeforePrecipitations,
                  unit:
                    "COMMON.TIME_UNITS." +
                      weatherSummary?.timeBeforePrecipitationsUnit
                    | translate
                    | lowercase
                }
        }}
      </p>
    </div>
  </div>
  <!--
    <div class="py-2 border-y-[0.5px] border-white border-opacity-20 flex items-center gap-x-2">
        <div class="min-w-[16px] min-h-[16px] bg-gray-300 animate-pulse rounded"></div>
        <div class="text-sm text-white font-light tracking-[0.25px] bg-gray-300 animate-pulse h-4 w-1/2 rounded"></div>
    </div>-->

  <div class="flex items-center justify-between">
    <div class="flex items-center gap-x-2">
      <img
        src="/assets/common/moon.svg"
        alt="moon"
        class="min-h-[16px] min-w-[16px]"
        skeleton
      />

      <p class="text-sm text-[#EFB317] font-light tracking-[0.25px]" skeleton>
        {{ "HOME.WEATHER.MOON_INFO.LABEL" | translate }}
      </p>
    </div>
    <!-- TODO: uncomment when deployed on store-->
    <!--<vw-upgrade-pro-btn
      [style]="'LIGHT'"
      (click)="onClickUpgradePro($event)"
    ></vw-upgrade-pro-btn>-->
  </div>
</div>
