<!-- <div class="rounded-lg card-inner front "
     [style.background-color]="cultivation?.backgroundColor">
    <div class="items-center">
                <div [style.background-image]="cultivationPictureUrl"
                    [style.background-size]="'auto 70px'"
                    class=" h-[120px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative">
                </div>
    </div>
</div> -->
<div class="rounded-lg card-inner front "
     [style.background-color]="cultivation?.backgroundColor">
    <div class=" px-4 py-3.5 grid grid-cols-[112px_1fr] items-center gap-x-4">

        <div>
            <div [style.background-image]="cultivationPictureUrl"
                 [style.background-size]="'auto 70px'"
                 class=" h-[90px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative">
            </div>
        </div>

        <div class="text-white">
            <div class="flex items-start justify-between">
                <div>
                    <p class="font-bold text-[20px] leading-6">{{cultivation?.name | Label}}</p>
                    <p class="text-xs font-light leading-6">{{cultivation?.family?.name | Label}}</p>
                </div>
                <button class="min-w-[24px] min-h-[24px]" (click)="isFlipped = !isFlipped">
                    <vw-share-icon (click)="openSharePopup(cultivation)"></vw-share-icon>
                </button>

            </div>

            <div class="h-[0.5px] w-full bg-white my-[10px] opacity-30"></div>

            <div class="flex items-center gap-2.5">
                <vw-rounded-level-indicator>
                    <vw-sun-icon></vw-sun-icon>
                </vw-rounded-level-indicator>
                <vw-rounded-level-indicator>
                    <vw-water-drop-icon></vw-water-drop-icon>
                </vw-rounded-level-indicator>
                <a href="#" class="font-bold text-xs text-blue-600 ">En savoir plus</a>
            </div>
        </div>
    </div>
</div>
