import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LightButtonComponent } from "../@generic/buttons/light-button/light-button.component";
import { ProgressBarComponent } from "../@generic/progress-bar/progress-bar.component";
import { TranslateModule } from "@ngx-translate/core";
import { LabelPipe } from "../../pipes/LabelPipe";
import { GrowingStepDto } from "vegiwise-sdk-typescript/dist/model/@common/growing-step";
import { GrowingStepViewerComponent } from "../growing-step-viewer/growing-step-viewer.component";
import { ModalRef } from "../../services/modal/model-ref";
import { DeleteItemModalComponent } from "../../modals/delete-item-modal/delete-item-modal.component";
import { ModalService } from "../../services/modal/modal.service";
import { PlantApi } from "vegiwise-sdk-typescript/dist/sdk/plant/plant.api";
import { Router } from "@angular/router";
import { PlantDto } from "vegiwise-sdk-typescript/dist/model/@common/plant";
import { PlantToGrowingStepApi } from "vegiwise-sdk-typescript/dist/sdk/plant/plant-to-growing-step.api";

@Component({
  selector: "vw-growing-steps-viewer",
  standalone: true,
  imports: [
    CommonModule,
    LightButtonComponent,
    ProgressBarComponent,
    TranslateModule,
    LabelPipe,
    GrowingStepViewerComponent,
  ],
  templateUrl: "./growing-steps-viewer.component.html",
  styleUrls: ["./growing-steps-viewer.component.scss"],
})
export class GrowingStepsViewerComponent implements OnInit {
  @Input() growingStep: GrowingStepDto;
  @Input() growingSteps: GrowingStepDto[];
  @Input() plant: PlantDto;
  currentStep: GrowingStepDto;
  selectedStep: GrowingStepDto;
  selectedStepIndex: number;
  hasNext: boolean;
  hasPrevious: boolean;
  progressionPercentage: number;

  constructor(
    private modalService: ModalService,
    private plantToGrowingStepApi: PlantToGrowingStepApi,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.growingSteps) {
      this.selectedStepIndex = this.growingSteps.findIndex(
        (step) => step.uid === this.plant.currentStep.growingStepUid
      );
      this.selectCurrent();
    }
  }

  selectCurrent() {
    if (this.growingSteps) {
      this.selectedStep = this.growingSteps[this.selectedStepIndex];
      this.currentStep = this.selectedStep; //TODO get growing step from plant
      this.hasNext = this.selectedStepIndex < this.growingSteps.length - 1;
      this.hasPrevious = this.selectedStepIndex > 0;
    }
  }

  nextStep() {
    if (this.hasNext) {
      this.selectedStepIndex++;
      this.selectCurrent();
    }
  }

  previousStep() {
    if (this.hasPrevious) {
      this.selectedStepIndex--;
      this.selectCurrent();
    }
  }

  onDelete() {
    const modal: ModalRef = this.modalService.open(DeleteItemModalComponent);
    modal.onResult().subscribe((res) => {
      if (res && res.deleteConfirmed) {
        this.plantToGrowingStepApi
          .deleteByPlantUid(this.plant.uid)
          .then((res) => {
            this.router.navigate([".."]);
          });
      }
    });
  }
}
