import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { Observable, of } from 'rxjs';
import { CacheService } from './cache.service';
import { ErrorService } from './error.service';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({providedIn: 'root'})
export class HTTPService {
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private errorService: ErrorService,
    private cacheService: CacheService,
    private storageService: StorageService,
  ) { }

  public get<T>(url: string, options?: any, useCache: boolean = false): Observable<T> {
    this.loaderService.showLoader();
    return new Observable<any>((observer) => {
      let result = null;
      if (useCache) {
        result = this.cacheService.findInCacheByKey(url);
      }

      if (!result) {
        this.addRetry(this.http.get<T>(url, options ? options : undefined)).subscribe(
          (res) => {
            if (useCache) {
              this.cacheService.saveInCache(url, res);
            }
            observer.next(res);
          },
          (err) => {
            this.loaderService.hideLoader();
            this.errorService.handleError(err.error);
            observer.error(err.error);
          },
          () => {
            this.loaderService.hideLoader();
            observer.complete();
          }
        );
      } else {
        observer.next(result);
        observer.complete();
      }
    });
  }

  public post<T>(url: string, param: any, options?: any): Observable<T> {
    this.loaderService.showLoader();
    return new Observable<any>((observer) => {
      this.addRetry(this.http.post<T>(url, param, options)).subscribe(
        (res) => observer.next(res),
        (err) => {
          this.loaderService.hideLoader();
          observer.error(err.error);
          this.errorService.handleError(err.error);
        },
        () => {
          this.loaderService.hideLoader();
          observer.complete();
        }
      );
    });
  }

  public put<T>(url: string, param: any, options?: any): Observable<T> {
    this.loaderService.showLoader();
    return new Observable<any>((observer) => {
      this.addRetry(this.http.put<T>(url, param, options)).subscribe(
        (res) => observer.next(res),
        (err) => {
          this.loaderService.hideLoader();
          observer.error(err.error);
          this.errorService.handleError(err.error);
        },
        () => {
          this.loaderService.hideLoader();
          observer.complete();
        }
      );
    });
  }

  public delete<T>(url: string, options?: any): Observable<T> {
    this.loaderService.showLoader();
    return new Observable<any>((observer) => {
      this.addRetry(this.http.delete<T>(url, options)).subscribe(
        (res) => observer.next(res),
        (err) => {
          this.loaderService.hideLoader();
          observer.error(err.error);
          this.errorService.handleError(err.error);
        },
        () => {
          this.loaderService.hideLoader();
          observer.complete();
        }
      );
    });
  }

  private addRetry(query) {
    return query.pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(1000), // delay retry by 1 sec
          concatMap((errorStatus, retryNb) => {
            // this.getRefreshToken() have refresh token in local storage
            // retryNb < 2 => limit to 2 retry
            // errorStatus.status === 401 => retry only if 401
            if (this.getRefreshToken() && retryNb < 2 && errorStatus.status === 401) {
              this.deleteToken(); // delete token to force refresh
              return of(errorStatus); // launch retry
            } else {
              throw errorStatus; // throw error => be handled upper
            }
          }),
        )
      )
    );
  }

  public getRefreshToken(): string {
    return this.storageService.getItem(environment.REFRESH_TOKEN);
  }

  private deleteToken(): void {
    this.storageService.removeItem(environment.ACCESS_TOKEN);
  }
}
