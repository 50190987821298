import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-3-dots-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './3-dots-icon.component.html',
  styleUrls: ['./3-dots-icon.component.scss']
})
export class DotsIcon {

}
