import {Component, OnInit} from '@angular/core';
import {RouterLinkActive, RouterModule, RouterOutlet} from '@angular/router';
import {DefaultLayoutComponent} from '../@commons/layouts/default-layout/default-layout.component';
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {AppHeaderTabsComponent} from "../@commons/components/app-header-tabs/app-header-tabs.component";
import {AppDetailsHeaderComponent} from "../@commons/components/app-details-header/app-details-header.component";

@Component({
    selector: 'vw-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    standalone: true,
    imports: [RouterOutlet, HttpClientModule, CommonModule, DefaultLayoutComponent, RouterModule, RouterLinkActive, AppHeaderTabsComponent, AppDetailsHeaderComponent],
    providers: []

})
export class UserComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
