import { Injectable } from '@angular/core';

class Cache {
    constructor(
        public key: string,
        public result: any,
        public createTs: number = Date.now(),
        public ttl: number = 30000,
    ) {}
}

@Injectable({providedIn: 'root'})
export class CacheService {
    cache: Cache[] = [];

    /**
     * @param key used as unique id in the cache
     * @param value to cache in the service
     * @param ttl cache expiration in ms
     */
    saveInCache(key: string, value: any, ttl?: number): void {
        this.invalidCacheByKey(key); // delete old value for this key
        const c = new Cache(key, value, Date.now(), ttl); // create cache object
        this.cache.push(c); // save it
    }

    /**
     * @param key used to retrieve value
     * @return value or null if not found for key or null if expired
     */
    findInCacheByKey(key: string): any {
        const c = this.cache.find((item) => item.key === key);

        if (c) { // cache found => verify validity
            // cache still valid
            if ((c.createTs + c.ttl) > Date.now()) {
                return c.result;
            } else { // cache expired
                this.invalidCacheByKey(key);
            }
        }

        return null;
    }

    /**
     * @param key used to invalidate (delete) item in cache
     */
    invalidCacheByKey(key: string): void {
        this.cache = this.cache.filter((item) => item.key !== key);
    }
}
