import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-simple-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss']
})
export class SimpleButtonComponent {
  @Input() label!: string;
  @Input() enabled: boolean = true;
  @Input() loading: boolean = false;

}
