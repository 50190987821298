<vw-default-layout [bodyTemplate]="bodyT" [headerTemplate]="header">
    <ng-template #header>
        <vw-app-details-header [title]="'CULTIVATIONS.PLANTS.GROWING.LABEL'| translate" ></vw-app-details-header> 
    </ng-template>

    <ng-template #bodyT>
        <div>
            <div [style.background-image]="cultivationPictureUrl"
                 [style.background-size]="'auto 70px'"
                 class=" h-[90px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative">
            </div>
        </div>
        <section class="p-3">
            <vw-cultivation-horizontal-viewer [cultivation]="cultivation" (click)="goToCultivationDetails(cultivation)"
            ></vw-cultivation-horizontal-viewer>
            
            <div class="py-[20px] space-y-2.5" *ngIf="userGrowingStepsToPlant && userGrowingStepsToPlant.length > 0">
                <div class="bg-white border border-[#D6D6D6] p-2.5 rounded-lg">
                    <div class="w-full h-[20px]"></div>
                    <p class="text-gray-600 w-80 mx-auto text-center text-xl font-medium">
                        {{ 'CULTIVATIONS.PLANTS.ADD.DESCRIPTION' | translate }}
                    </p>
                    <div class="w-full h-[20px]"></div>
                    <vw-simple-button  [loading]="loading" (click)="addPlantToUser($event)" [label]="'CULTIVATIONS.PLANTS.EMPTY_LIST.BUTTON'"> </vw-simple-button>
                </div>
            </div>
            <div *ngIf="growingSteps && growingSteps.length > 0" class="space-y-2.5">
                <p *ngFor="let step of growingSteps">{{ growingStep.name }}</p>
            </div>
            <div *ngIf="userGrowingStepsToPlant && userGrowingStepsToPlant.length > 0">
                <div class="w-full"></div>
                <div class="w-full h-[16px]"></div>
                <div class="space-y-2.5">
                    <vw-plant-list-horizontal-viewer *ngFor="let userGrowingStepPlant of userGrowingStepsToPlant"
                                                     class="block gap-x-2.5"
                                                     [cultivation]="cultivation" [plantToGrowingStep]="userGrowingStepPlant">
                    </vw-plant-list-horizontal-viewer>
                </div>
            </div>
        </section>
    </ng-template>
</vw-default-layout>
