<div class="h-screen absolute top-0 bg-black bg-opacity-60 w-full z-[999]">
  <div class="absolute w-full px-5 bottom-4">
    <button
      (click)="close(false)"
      class="absolute right-[20px] top-[-45px] text-black h-[40px] w-[40px] rounded-full bg-white flex justify-center items-center"
    >
      <vw-cross-cancel-icon></vw-cross-cancel-icon>
    </button>
    <div class="bg-white p-4 rounded-lg w-full space-y-4">
      <div class="bg-white border border-[#D6D6D6] p-2.5 rounded-lg">
        <img
          src="./assets/common/Feedback.png"
          alt="image"
          class="w-[125px] h-[125px] mx-auto"
        />

        <div class="w-full h-[12px]"></div>
        <h4 class="text-black text-[13px] leading-[18px] font-bold">
          {{ "MODALS.UPGRADE_PRO.TITLE" | translate }}
        </h4>
        <div class="w-full h-[16px]"></div>

        <p class="text-[#202939] text-[13px] leading-[18px]">
          {{ "MODALS.UPGRADE_PRO.TEXT1" | translate }}
        </p>
        <div class="w-full h-[10px]"></div>
        <p class="text-[#202939] text-[13px] leading-[18px]">
          {{ "MODALS.UPGRADE_PRO.TEXT2" | translate }}
        </p>

        <div class="w-full h-[30px]"></div>
        <textarea
          name=""
          id=""
          rows="2"
          [(ngModel)]="remarks"
          placeholder="{{ 'MODALS.UPGRADE_PRO.PLACEHOLDER' | translate }}"
          class="w-full placeholder:text-gray-300"
        ></textarea>
        <div class="w-full h-[30px]"></div>

        <button
          class="py-3 w-full bg-brand-500 rounded-lg text-white text-sm font-bold"
          (click)="onSubmit($event)"
        >
          {{ "MODALS.UPGRADE_PRO.SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
