import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Subscription} from 'rxjs';
import {TranslateModule} from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import {DotMenuService} from '../../../../services/display/dot-menu.service';
import {DotMenuItem} from '../../../../classes/dot-menu-item';
import {DotsIcon} from "../../../../icons/3-dots-icon/3-dots-icon.component";

@Component({
  selector: 'vw-dot-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, DotsIcon],
  templateUrl: './dot-button.component.html',
  styleUrls: ['./dot-button.component.scss']
})
export class DotButtonComponent implements OnDestroy, OnInit {

  isOpen: boolean = false;
  closeAllSubscription: Subscription;
  @Input() theme: 'white'|'black' = 'black';

  @Input() menuItems: DotMenuItem[];

  constructor(public dotMenuService: DotMenuService, private eRef: ElementRef, private router: Router) {
  }

  onDotClicked($event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.dotMenuService.closeAll.emit(this);
    this.isOpen = !this.isOpen;
  }

  ngOnInit() {
    this.closeAllSubscription = this.dotMenuService.closeAll.subscribe((origin) => {
      if(origin != this) {
        this.isOpen = false;
      }
    });
  }

  ngOnDestroy() {
    this.closeAllSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.isOpen) {
      event.stopPropagation();
    }
    event.stopImmediatePropagation();
    if(this.eRef.nativeElement.contains(event.target)) {

    } else {
      this.isOpen = false;
    }
  }

  onMenuItemSelected(event: MouseEvent, menuItem: DotMenuItem) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if(menuItem) {
      if(menuItem.onClick) {
        menuItem.onClick(event);
      }
      if(menuItem.path) {
        console.log('navigating to', menuItem.path);
        this.router.navigate([menuItem.path]);
      }
    }
    this.isOpen = false;
  }
}
