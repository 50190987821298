import { Directive, HostListener, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[vwSwiper]',
  standalone: true
})
export class SwiperDirective {
  defaultTouch = { x: 0, y: 0, time: 0 };
  isTouchStart = false;
  constructor(private viewContainerRef: ViewContainerRef) { }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    /*let touch = event.touches[0] || event.changedTouches[0];
    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
      this.isTouchStart = true
    } else if (event.type === 'touchmove') {
      event.preventDefault()
      if (this.isTouchStart) {
        this.swipeMove(window.innerHeight - touch.pageY);
      }
    } else if (event.type === 'touchend') {
      this.isTouchStart = false;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      // simulte a swipe -> less than 500 ms and more than 60 px
      // if (deltaTime < 500) {
      if (Math.abs(deltaY) > 60) {
        // delta y is at least 60 pixels
        if (deltaY > 0) {
          this.swipeUp(event)
        } else {
          this.swipeDown(event);
        }
      }
    }*/
  }

  defaultSwipeContentHeight = 12;
  middleSwipeContentHeight = 40
  height = this.defaultSwipeContentHeight;

  swipeDown(e) {
    /*if (this.height < this.defaultSwipeContentHeight) {
      this.height = this.defaultSwipeContentHeight;
    } else if (this.height < this.middleSwipeContentHeight && this.height > this.defaultSwipeContentHeight) {
      const diff = Math.abs(this.height - this.defaultSwipeContentHeight);
      const diff2 = Math.abs(this.height - this.middleSwipeContentHeight);
      if (diff < diff2) {
        this.height = this.defaultSwipeContentHeight
      } else {
        this.height = this.middleSwipeContentHeight;
      }
    } else {
      this.height = 75
    }

    this.viewContainerRef.element.nativeElement.style.height = this.height + '%';*/
  }

  swipeUp(e) {
    if (this.height < this.defaultSwipeContentHeight) {
      this.height = this.defaultSwipeContentHeight;
    } else if (this.height > this.defaultSwipeContentHeight && this.height < this.middleSwipeContentHeight) {
      const diff = Math.abs(this.height - this.defaultSwipeContentHeight);
      const diff2 = Math.abs(this.height - this.middleSwipeContentHeight);
      if (diff < diff2) {
        this.height = this.defaultSwipeContentHeight;
      } else {
        this.height = this.middleSwipeContentHeight;
      }
    } else if (this.height > this.middleSwipeContentHeight && this.height < 75) {
      const diff = Math.abs(this.height - this.middleSwipeContentHeight);
      const diff2 = Math.abs(this.height - 75);
      if (diff < diff2) {
        this.height = this.middleSwipeContentHeight;
      } else {
        this.height = 75;
      }
    } else {
      this.height = 75;
    }

    this.viewContainerRef.element.nativeElement.style.height = this.height + '%';
  }

  swipeMove(touchY: number) {
    const newHeight = Math.abs((touchY / window.innerHeight) * 100)
    if (newHeight < 75 && newHeight > this.defaultSwipeContentHeight) {
      this.height = newHeight;
    }

    this.viewContainerRef.element.nativeElement.style.height = this.height + '%';
  }
}
