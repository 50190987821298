import {EventEmitter, Injectable, OnDestroy} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DotMenuService implements OnDestroy {

    isOpen: boolean = false;
    closeAll: EventEmitter<any> = new EventEmitter<any>();

    ngOnDestroy(): void {

    }




}
