import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TaskCultivationViewerComponent} from "./task-cultivation-viewer/task-cultivation-viewer.component";
import {TaskDto} from "vegiwise-sdk-typescript/dist/model/@common/task";

@Component({
  selector: 'vw-task-viewer',
  standalone: true,
  imports: [CommonModule, TaskCultivationViewerComponent],
  templateUrl: './task-viewer.component.html',
  styleUrls: ['./task-viewer.component.scss']
})
export class TaskViewerComponent {
  @Input() task: TaskDto
}
