<!-- 
    TODO: conditionner au scroll pour afficher la classe -translate-y-full au lieu de translate-y-0 au moment ou on scroll vers le bas et inverse quand on scroll vers le haut, 
    il y aura un effet directement du header qui monte/descend proprement vu que j'ai ajouté une transition dessus

    [class.translate-y-0]="!scrolling"
        [class.-translate-y-full]="scrolling"
-->
<header class="fixed top-0 right-O left-O relative">
    <div class="transition-transform ease-out bg-white">
        <div class="-translate-y-full"></div>
        
        <div class="flex justify-between px-3 py-2 shadow-md">
            <img src="/assets/common/vegiwise_logo_name_fullwidth.svg" class=" max-h-[40px]" alt="logo" />
            <ng-container *ngTemplateOutlet="additionalComponent ? additionalComponent : defaultAdditionalComponent">
            </ng-container>
        </div>

        <ng-container *ngTemplateOutlet="tabsTemplate ? tabsTemplate : defaultTabs">
        </ng-container>

        <ng-template #defaultTabs>
            <!-- No tabs -->
        </ng-template>

        <ng-template #defaultAdditionalComponent>
            <button>
                <!-- <img src="/assets/common/PlusCircle.svg" alt="PlusCircle" class="w-8 h-8" /> -->
            </button>
        </ng-template>
    </div>
</header>
