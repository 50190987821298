import {Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DotMenuItem} from '../../@commons/classes/dot-menu-item';
import {DotButtonComponent} from '../../@commons/components/@generic/buttons/dot-button/dot-button.component';
import {UserApi} from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import {UserDto} from "vegiwise-sdk-typescript/dist/model/@common/user";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import {UploadFileRes} from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";
import {DmsApi} from "vegiwise-sdk-typescript/dist/sdk/dms/dms.api";
import {DmsService} from "../../@commons/services/vegiwise/dms.service";
import {TranslateModule} from "@ngx-translate/core";
import * as moment from "moment";
import {AuthApi} from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import {Router, RouterLink} from "@angular/router";
import {ErrorMessageComponent} from "../../@commons/components/@forms/error-message/error-message.component";
import {KeyIconComponent} from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import {MailIconComponent} from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import {
    PersonStandingIconComponent
} from "../../@commons/components/@generic/icons/person-standing-icon/person-standing-icon.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../@commons/components/@generic/inputs/text-input/text-input.component";

@Component({
    selector: 'vw-user-details',
    standalone: true,
    imports: [CommonModule, DotButtonComponent, DotButtonComponent,
        TranslateModule, ErrorMessageComponent, KeyIconComponent, MailIconComponent, PersonStandingIconComponent,
        ReactiveFormsModule, TextInputComponent, RouterLink],
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    providers: [DmsApi, AuthApi, UserApi]
})
export class UserDetailsComponent implements OnChanges, OnInit {
    currentUser: UserDto;
    registrationDate: string;
    @ViewChild("fileInput") fileInput: ElementRef;
    file: ExternalFileDto;
    pictureUrl: string;
    selectedImage: File;

    public form: FormGroup;
    errors: any[];

    constructor(private dmsApi: DmsApi,
                private authApi: AuthApi,
                private dmsService: DmsService,
                private userApi: UserApi,
                private router: Router,
                private fb: FormBuilder) {
    }


    ngOnInit() {
        this.initForm();
        this.userApi.getCurrentUser().then((u) => {
            this.currentUser = u;
            this.patchValues();
        })
    }

    initForm() {
        this.form = this.fb.group({
            lastNameCtrl: new FormControl("", [Validators.required]),
            firstNameCtrl: new FormControl("", [Validators.required]),
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.currentUser) {
            this.patchValues();
        }
    }

    patchValues() {
        if (this.currentUser) {
            if (this.currentUser.profilePicture) {
                this.file = this.currentUser.profilePicture;
                this.buildPictureUrl();
            }
        }

        this.form.controls['lastNameCtrl'].patchValue(this.currentUser.lastName);
        this.form.controls['firstNameCtrl'].patchValue(this.currentUser.firstName);
    }

    uploadProfilePicture() {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: any) {
        this.selectedImage = event.target.files[0];
        this.previewImage(this.selectedImage);

        this.dmsApi.getPreSignedUrl().then((urlData: PreSignedUrlRes) => {
            let file = event.target.files[0];
            if (file) {
                this.dmsApi.uploadToPreSignedUrl(file, urlData).then((data: UploadFileRes) => {
                    this.file = {
                        fileKey: data.fileUid,
                        fileOrigin: ExternalFileOrigin.USER,
                        name: this.selectedImage.name,
                        bucketId: '',
                        scope: '',
                        contentType: this.selectedImage.type
                    }
                    this.buildPictureUrl();
                    this.currentUser.profilePicture = this.file;
                }).catch((e) => {
                    console.error(e);
                });
            }
        });
    }

    buildPictureUrl() {
        if (this.file && this.file.fileKey) {
            this.pictureUrl = `url(${this.dmsService.getFileUrl(this.file.fileKey, null, 250)})`;
        } else {
            this.pictureUrl = null;
        }
    }

    previewImage(file: File): void {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.pictureUrl = `url(${e.target.result.toString()})`;
        };
        reader.readAsDataURL(file);
    }

    get selectedImageSize(): string {
        // If less than 1 MO => display in KO
        if (this.selectedImage && this.selectedImage.size < 1000000) {
            return `${Math.round(this.selectedImage.size / 1000)} ko`;
        }
        // If more than 1 MO => display in MO
        if (this.selectedImage && this.selectedImage.size >= 1000000) {
            return `${Math.fround(this.selectedImage.size / 1000000).toFixed(1)} Mo`;
        }
        return "";
    }

    save($event: MouseEvent) {
        this.currentUser.lastName = this.form.controls['lastNameCtrl'].value;
        this.currentUser.firstName = this.form.controls['firstNameCtrl'].value;
        this.userApi.upsert(this.currentUser).then(() => {
            console.log('user updated');
        });
    }
}
