import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {SocialApi} from "vegiwise-sdk-typescript/dist/sdk/social/social.api";
import {HeaderFooterService} from "../../@commons/services/display/header-footer.service";
import {ListCultivationRequest} from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";

@Component({
    selector: 'vw-media-home',
    templateUrl: './media-home.component.html',
    styleUrls: ['./media-home.component.scss'],
    standalone: true,
    imports: [FormsModule, NgForOf, InfiniteScrollModule],
    providers: [SocialApi]
})
export class MediaHomeComponent implements OnInit {
    medias: Array<any> = [{}, {}, {}]

    constructor(private headerFooterService: HeaderFooterService, private socialApi: SocialApi) {
    }

    ngOnInit(): void {
    }


    loadMore($event) {
        this.loadMedias();
    }

    loadMedias() {
        const filters: ListCultivationRequest = new ListCultivationRequest();
        /* this.socialApi.listMedias(filters, this.page, this.pageSize, 'fr').then((medias: PagingResponse<MediaDto>) => {
             console.log('..loaded ' + cultivations.results.length);
             this.cultivations = cultivations.results;
             this.cultivationPairs.push(...this.divideArray<CultivationDto>(this.cultivations));
         });*/
    }

    onScroll($event) {
        this.headerFooterService.onScroll('media', $event.target.scrollTop);
    }

}
