import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ExclamationPointIconComponent } from "../../@generic/icons/exclamation-point-icon/exclamation-point-icon.component";

@Component({
  selector: "vw-error-message",
  standalone: true,
  imports: [CommonModule, TranslateModule, ExclamationPointIconComponent],
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"],
})
export class ErrorMessageComponent {
  @Input() label: string;
  @Input() message: string;
  @Input() formI18nPath: string;
  @Input() fieldName: string;

  get errorMsg(): string {
    if (this.label) return this.label;
    if (this.message) {
      if (this.message.includes(".")) {
        return this.message;
      }
      return `ERRORS.${this.message}`;
    }
  }

  get fieldMsg(): string {
    if (this.formI18nPath && this.fieldName) {
      const cleanedFieldName = this.fieldName.replace("Ctrl", "");
      return `${
        this.formI18nPath
      }.${cleanedFieldName.toUpperCase()}.PLACEHOLDER`;
    }
    return this.fieldName;
  }
}
