<div
  class="h-full px-4 md:px-6 py-2 overflow-y-auto"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="loadMore($event)"
  (scroll)="onScroll($event)"
  [scrollWindow]="false"
>
  <div
    *ngIf="!understood"
    class="text-base bg-indigo-50 rounded-md shadow-[0_4px_6px_-2px_rgba(0,0,0,0.4)] p-4 my-4 text-right"
  >
    <p class="text-start">
      Bonjour, bienvenue sur notre démo. L'application est en cours de
      développement et certaines fonctionnalités ne sont pas encore disponibles.
    </p>
    <button
      class="bg-brand-500 rounded-lg p-2 text-white"
      (click)="understoodClicked()"
    >
      Compris
    </button>
  </div>

  <vw-weather-info-block class="pb-3"></vw-weather-info-block>

  <!-- Uncomment when we really get alerts/warnings -->
  <!--<vw-warning-list
    *ngIf="warnings && warnings.length"
    [prefix]="'HOME.WARNINGS'"
    [warnings]="warnings"
  ></vw-warning-list>-->

  <div class="" *ngIf="!warnings || warnings.length == 0">
    <div
      class="mx-[15px] py-[6px] flex items-center gap-x-2.5 bg-brand-600 rounded-md snap-center shadow-[0_4px_6px_-2px_rgba(0,0,0,0.4)]"
    >
      <div class="text-white max-w-[100%]">
        <p class="text-[15px] leading-[24px] font-medium">
          {{ "HOME.NO_WARNING.TITLE" | translate }}
        </p>
        <p class="truncate text-xs leading-[24px] font-light">
          {{ "HOME.NO_WARNING.DESCRIPTION" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="flex w-full h-[calc(100%-400px)] items-center justify-center"
  >
    <vw-loader></vw-loader>
  </div>
  <div *ngIf="!loading">
    <!-- TODO AJOUT -->
    <div class="flex justify-between my-4">
      <h3 class="font-bold text-xl">Mon potager</h3>
      <button (click)="onAddPlantClicked($event)">
        <p class="font-bold text-base text-blue-600">Ajouter une plante</p>
      </button>
    </div>
    <!-- cards -->
    <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2.5 gap-y-2.5">
      <ng-container *ngFor="let cultivation of cultivations">
        <div class="w-full h-60">
          <vw-cultivation-item
            class="space-y-3.5 py-1.5"
            [cultivation]="cultivation"
            (click)="goToCultivationDetails(cultivation)"
            (delete)="onDeleteCultivationFromUserList(cultivation)"
          ></vw-cultivation-item>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="!cultivations || cultivations.length == 0"
      class="grid grid-cols-1 w-full rounded-lg overflow-hidden text-white"
    >
      <div
        class="h-full w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center rounded-[10px] overflow-hidden max-w-[160px] border border-solid border-brand-500"
        style="cursor: pointer; background-size: cover"
      >
        <button
          class="h-full w-full flex flex-col justify-center items-center gap-y-2"
          (click)="onAddPlantClicked($event)"
        >
          <vw-plus-icon
            class="btn-icon h-[50px] w-[50px] text-brand-500"
          ></vw-plus-icon>
          <p class="text-base text-brand-500">
            {{ "HOME.CULTIVATIONS.EMPTY_LIST.ADD" | translate }}
          </p>
        </button>
      </div>
    </div>
  </div>
</div>
