import {ModalRef} from "./model-ref";

export abstract class Modal {

    modalInstance: ModalRef;

    abstract onInjectInputs(inputs: any): void;
    onOpen(): void{}

    close(output?: any): void {
        this.modalInstance.close(output);
    }

    dismiss(output?: any): void {
        this.modalInstance.dismiss(output);
    }

}