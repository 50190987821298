<div class="w-[40px] h-[40px] flex justify-center items-center relative">
    <div class="absolute text-xs font-medium leading-6">
        <ng-content>

        </ng-content>
    </div>
    <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
        <circle cx="20" cy="20" r="18" fill="none" stroke="#FFFFFF35" stroke-width="4"/>
        <circle
                class="percent fifty"
                cx="20"
                cy="20"
                r="18"
                fill="none"
                stroke="#FFFFFF"
                stroke-width="4"
                pathLength="100"
                stroke-dasharray="100"
                [attr.stroke-dashoffset]="100-percentage"
        />
    </svg>
</div>