import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LabelPipe} from "../../../@commons/pipes/LabelPipe";
import {
    RoundedLevelIndicatorComponent
} from "../../../@commons/components/@generic/rounded-level-indicator/rounded-level-indicator.component";
import {ShareIconComponent} from "../../../@commons/icons/share-icon/share-icon.component";
import {SunIconComponent} from "../../../@commons/icons/sun-icon/sun-icon.component";
import {WaterDropIconComponent} from "../../../@commons/icons/water-drop-icon/water-drop-icon.component";
import {TranslateModule} from "@ngx-translate/core";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";

@Component({
  selector: 'vw-selected-cultivations-viewer',
  standalone: true,
  imports: [CommonModule, LabelPipe, RoundedLevelIndicatorComponent, ShareIconComponent, SunIconComponent, WaterDropIconComponent, TranslateModule],
  templateUrl: './selected-cultivations-viewer.component.html',
  styleUrls: ['./selected-cultivations-viewer.component.scss']
})
export class SelectedCultivationsViewerComponent {
  @Input() selectedItems: string[] = [];
}
