import {Component, ComponentRef, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalService} from "../modal.service";
import {Modal} from "../modal";
import {AppHeaderComponent} from "../../../components/app-header/app-header.component";
import {AppMenuComponent} from "../../../components/app-menu/app-menu.component";

@Component({
  selector: 'vw-modal-container',
  standalone: true,
  imports: [CommonModule, AppHeaderComponent, AppMenuComponent],
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements OnInit {
  modal: ComponentRef<Modal>;

  @ViewChild('insideContainer', { read: ViewContainerRef })
  private modalContainer: ViewContainerRef;

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    this.modalService.registerContainer(this);
  }


  createModal<T extends Modal>(component: Type<T>): ComponentRef<T> {
    this.clear();
    const m: ComponentRef<T> = this.modalContainer.createComponent(component);
    this.modal = m;
    return m;
  }

  clear() {
    if(this.modal) {
      this.modal.destroy();
      this.modal = null;
    }
    if(this.modalContainer) {
      this.modalContainer.clear();
    }
  }

    onClickContainer($event: MouseEvent) {
        console.log($event.target);
        this.clear();
    }
}
