<div class="bg-white rounded-lg border border-[#D0D0D0] px-3 py-3 flex items-center justify-between">
    <div>
        <!--<p class="text-base font-bold text-[#1A0700]">{{'CULTIVATIONS.DETAILS.PERIODS.LABEL' | translate}}</p>-->

        <div class="flex items-center gap-x-2.5 text-xs leading-[20px] text-white">
            <vw-cultivation-period-label *ngFor="let period of groupedSimilarPeriods" [period]="period[0]">
            </vw-cultivation-period-label>
        </div>
        <div class="w-full h-[16px]"></div>
        <div class="grid grid-cols-6 gap-y-[10px] gap-x-[24px] text-sm font-medium font-['Manrope',_sans-serif]">
            <vw-cultivation-period-month *ngFor="let periodsByMonth of periodsByMonths"
                                         [month]="periodsByMonth.month"
                                         [periods]="periodsByMonth.periods"></vw-cultivation-period-month>
        </div>
    </div>
</div>