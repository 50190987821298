import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { LabelPipe } from "../../pipes/LabelPipe";
import { CultivationPeriodsViewerComponent } from "../cultivation-period-viewer/cultivation-periods-viewer.component";
import { FileUtils } from "../../utils/file-utils";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { FlipIconComponent } from "../../icons/flip-icon/flip-icon.component";
import { PaperPlaneIconComponent } from "../../icons/paper-plane-icon/paper-plane-icon.component";
import { WaterDropIconComponent } from "../../icons/water-drop-icon/water-drop-icon.component";
import { RoundedLevelIndicatorComponent } from "../@generic/rounded-level-indicator/rounded-level-indicator.component";
import { SunIconComponent } from "../../icons/sun-icon/sun-icon.component";
import { ShareIconComponent } from "../../icons/share-icon/share-icon.component";
import { ModalRef } from "../../services/modal/model-ref";
import { ModalService } from "../../services/modal/modal.service";
import { ShareCultivationModalComponent } from "../../modals/share-cultivation-modal/share-cultivation-modal.component";
import { DmsService } from "../../services/vegiwise/dms.service";

@Component({
  selector: "vw-cultivation-horizontal-viewer",
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    CultivationPeriodsViewerComponent,
    FlipIconComponent,
    PaperPlaneIconComponent,
    WaterDropIconComponent,
    RoundedLevelIndicatorComponent,
    SunIconComponent,
    ShareIconComponent,
  ],
  templateUrl: "./cultivation-horizontal-viewer.component.html",
  styleUrls: ["./cultivation-horizontal-viewer.component.scss"],
})
export class CultivationHorizontalViewerComponent implements OnInit, OnChanges {
  @Input() cultivation: CultivationDto;
  cultivationPictureUrl: string = "";
  backgroundColorClass: string;
  isFlipped: boolean = false;

  constructor(
    private modalService: ModalService,
    private dmsService: DmsService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.cultivation) {
      const fileUid: string = FileUtils.getFileUidFromCollection(
        this.cultivation.files,
        null
      );
      if (fileUid) {
        this.cultivationPictureUrl = `url(${this.dmsService.getFileUrl(
          fileUid,
          null,
          300
        )})`;
      }
    }
  }

  openSharePopup(cultivation) {
    const modal: ModalRef = this.modalService.open(
      ShareCultivationModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }
}
