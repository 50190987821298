import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { TextInputComponent } from "../../@commons/components/@generic/inputs/text-input/text-input.component";
import { MailIconComponent } from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import { KeyIconComponent } from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { PersonStandingIconComponent } from "../../@commons/components/@generic/icons/person-standing-icon/person-standing-icon.component";
import { PlusIconComponent } from "../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import { ErrorMessageComponent } from "../../@commons/components/@forms/error-message/error-message.component";
import { LinkComponent } from "../../@commons/components/@generic/link/link.component";
import { Router, RouterModule } from "@angular/router";
import { AuthApi } from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import { LoaderService } from "src/app/@commons/services/front/loader.service";
import { v4 as uuidv4 } from "uuid";
import { StorageService } from "src/app/@commons/services/front/storage.service";
import { environment } from "src/environments/environment";
import { UserApi } from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import { Language } from "vegiwise-sdk-typescript/dist/enums/language.enum";
import {ButtonComponent} from "../../@commons/components/@generic/buttons/button/button.component";

@Component({
  selector: "vw-login",
  standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TextInputComponent,
        MailIconComponent,
        KeyIconComponent,
        TranslateModule,
        PersonStandingIconComponent,
        PlusIconComponent,
        ErrorMessageComponent,
        LinkComponent,
        ButtonComponent,
    ],
  templateUrl: "./login.component.html",
  providers: [AuthApi, UserApi],
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  errors: any[];
  public form: FormGroup;

  constructor(
    private authApi: AuthApi,
    private userApi: UserApi,
    private router: Router,
    private fb: FormBuilder,
    private readonly loaderService: LoaderService,
    private readonly storageService: StorageService
  ) {}

  login() {
    if (this.form.valid) {
      const { emailCtrl, passwordCtrl } = this.form.value;
      this.loaderService.showLoader();
      this.authApi.login(emailCtrl, passwordCtrl).then(
        (res) => {
          this.loaderService.hideLoader();
          if (res) {
            this.router.navigate(["/home"]);
          } else {
            this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
          }
        },
        (err) => {
          this.loaderService.hideLoader();
          this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
        }
      );
    } else {
      this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
    }
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      emailCtrl: new FormControl("", [Validators.required, Validators.email]),
      passwordCtrl: new FormControl("", [Validators.required]),
    });
  }

  continueAsGuest() {
    this.loaderService.showLoader();
    const guestEmail = this.storageService.getItem(environment.GUEST_EMAIL);
    if (guestEmail) {
      this.loginAsGuest(guestEmail);
    } else {
      this.userApi.registerGuest({ preferredLanguage: Language.FR }).then(
        (res) => {
          this.storageService.setItem(environment.GUEST_EMAIL, res.email);
          this.storageService.setItem(environment.GUEST_UID, res.uid);
          this.loginAsGuest(res.email);
        },
        (err) => {
          this.loaderService.hideLoader();
          this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
        }
      );
    }
  }

  loginAsGuest(username: string) {
    this.authApi.loginAsGuest(username).then(
      (res) => {
        this.loaderService.hideLoader();
        if (res) {
          this.router.navigate(["/home"]);
        } else {
          this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
        }
      },
      (err) => {
        this.loaderService.hideLoader();
        this.errors = [{ label: "LOGIN.FORM.ERRORS.INVALID_CREDENTIALS" }];
      }
    );
  }
}
