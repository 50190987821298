import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from "@angular/router";
import { CommonModule, NgClass, NgIf } from "@angular/common";

@Component({
  selector: "vw-app-menu-item",
  templateUrl: "./app-menu-item.component.html",
  styleUrls: ["./app-menu-item.component.scss"],
  host: {
    class: "text-brand-500 flex flex-col items-center gap-y-0.5 relative",
  },
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    NgIf,
    CommonModule,
    TranslateModule,
    RouterLinkActive,
  ],
})
export class AppMenuItemComponent implements OnInit {
  @Input() label: string;
  @Input() selected: boolean;
  @Input() logoPath: string;
  @Input() path: string;
  @Input() disabled: boolean = false;
  logoClassUrl;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    /*this.translateService.getTranslation('fr').subscribe((res) => {
           console.log(res);
        });*/
  }

  ngOnInit() {
    if (this.logoPath) {
      this.logoClassUrl = "bg-[url('" + this.logoPath + "')]";
    }
  }
}
