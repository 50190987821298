import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-cultivation-stock-level',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cultivation-stock-level.component.html',
  styleUrls: ['./cultivation-stock-level.component.scss']
})
export class CultivationStockLevelComponent {
  quantity: any[] = [0, 1, 2, 4 , 5];
  @Input() currentQuantity: number = 0;
  @Output() currentQuantityChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() dataChanged: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean = false;

  increaseClicked() {
    this.currentQuantity+=1;
    this.currentQuantityChange.emit(this.currentQuantity);
    this.dataChanged.emit();
  }

  decreaseClicked() {
    this.currentQuantity-=1;
    this.currentQuantityChange.emit(this.currentQuantity);
    this.dataChanged.emit();
  }
}
