import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoaderComponent} from "../@generic/loaders/loader/loader.component";
import {LoaderGreyComponent} from "../@generic/loaders/loader-gray/loader-grey.component";
import {LoaderService} from "../../services/front/loader.service";

@Component({
  selector: 'vw-app-loader',
  standalone: true,
  imports: [CommonModule, LoaderComponent, LoaderGreyComponent],
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent {
  showLoader: boolean = false;
  constructor(private loaderService: LoaderService) {
    loaderService.loaderSubject.subscribe((res) => {
      this.showLoader = res;
    })
  }
}
