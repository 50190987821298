import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UpgradeProBtnComponent } from "../../../@commons/components/upgrade-pro-btn/upgrade-pro-btn.component";
import { SkeletonDirective } from "../../../@commons/directives/skeleton.directive";
import { WeatherApi } from "vegiwise-sdk-typescript/dist/sdk/weather/weather.api";
import { ModalService } from "../../../@commons/services/modal/modal.service";
import { ErrorModalComponent } from "../../../@commons/modals/error-modal/error-modal.component";
import { ModalRef } from "../../../@commons/services/modal/model-ref";
import { UpgradePremiumModalComponent } from "../../../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import {
  WeatherSummary,
  WeatherType,
} from "vegiwise-sdk-typescript/dist/model/weather/weather-summary";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "vw-weather-info-block",
  standalone: true,
  imports: [
    CommonModule,
    UpgradeProBtnComponent,
    SkeletonDirective,
    TranslateModule,
  ],
  providers: [WeatherApi],
  templateUrl: "./weather-info-block.component.html",
  styleUrls: ["./weather-info-block.component.scss"],
})
export class WeatherInfoBlockComponent implements OnInit {
  weatherSummary: WeatherSummary = null;

  constructor(
    private weatherService: WeatherApi,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.weatherService
      .getWeatherInfo()
      .then((weatherSummary: WeatherSummary) => {
        this.weatherSummary = weatherSummary;
        console.log(this.weatherSummary);
      });
  }

  onClickUpgradePro($event: MouseEvent) {
    const modal: ModalRef = this.modalService.open(
      UpgradePremiumModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }

  get weatherIconUrl(): string {
    const basePrefix = "assets/common/weather/static";
    if (!this.weatherSummary?.currentWeather) return null;
    switch (this.weatherSummary.currentWeather) {
      case WeatherType.SUN:
        return `${basePrefix}/day.svg`;
      case WeatherType.CLOUD:
        return `${basePrefix}/cloudy.svg`;
      case WeatherType.RAIN:
        return `${basePrefix}/rainy-6.svg`;
      case WeatherType.THUNDER:
        return `${basePrefix}/thunder.svg`;
      case WeatherType.WIND:
        return `${basePrefix}/cloudy-7.svg`; //TODO find wind icon
      case WeatherType.SNOW:
        return `${basePrefix}/snowy-6.svg`;
      case WeatherType.CLEAR:
        return `${basePrefix}/night.svg`;
      case WeatherType.UNKNOWN:
        return `${basePrefix}/cloudy.svg`;
      default:
        return null;
    }
  }
}
