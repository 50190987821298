import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  RouterLinkActive,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { DefaultLayoutComponent } from "../@commons/layouts/default-layout/default-layout.component";
import { AppHeaderTabsComponent } from "../@commons/components/app-header-tabs/app-header-tabs.component";
import { TabMenuItem } from "../@commons/classes/tab-menu-item";
import { TaskApi } from "vegiwise-sdk-typescript/dist/sdk/plant/task.api";
import { TimeLineResponse } from "vegiwise-sdk-typescript/dist/model/task/time-line-res";
import { ModalService } from "../@commons/services/modal/modal.service";
import { UpgradePremiumModalComponent } from "../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import { ModalRef } from "../@commons/services/modal/model-ref";
import { UpgradeProBtnComponent } from "../@commons/components/upgrade-pro-btn/upgrade-pro-btn.component";

@Component({
  selector: "vw-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    DefaultLayoutComponent,
    RouterModule,
    RouterLinkActive,
    AppHeaderTabsComponent,
    UpgradeProBtnComponent,
  ],
  providers: [TaskApi],
})
export class TaskComponent implements OnInit {
  tabItems: TabMenuItem[] = [
    { path: "/tasks/todo", label: "Todo", style: "", disabled: true },
    { path: "/tasks/calendar", label: "Calendrier", style: "" },
  ];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskService: TaskApi,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((e) => {
      // console.log(e);
    });

    this.taskService.timeline(7).then((timeline: TimeLineResponse) => {
      console.log(timeline);
    });
  }

  goTo(destination: string) {
    this.router.navigate([destination], { relativeTo: this.activatedRoute });
  }

  onClickUpgradePro($event: MouseEvent) {
    const modal: ModalRef = this.modalService.open(
      UpgradePremiumModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }
}
