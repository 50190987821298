import { Component, OnInit } from '@angular/core';
import {ContactListItemComponent} from './contact-list-item/contact-list-item.component';
import {NgFor} from '@angular/common';

@Component({
    selector: 'vw-contacts-list',
    templateUrl: './contacts-list.component.html',
    styleUrls: ['./contacts-list.component.scss'],
    imports: [
        ContactListItemComponent, NgFor
    ],
    standalone: true
})
export class ContactsListComponent implements OnInit {
  contacts: Array<any> = [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}];
  constructor() { }

  ngOnInit(): void {
  }

}
