import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-upgrade-pro-btn',
  standalone: true,
    imports: [CommonModule, TranslateModule],
  templateUrl: './upgrade-pro-btn.component.html',
  styleUrls: ['./upgrade-pro-btn.component.scss']
})
export class UpgradeProBtnComponent {
  @Input() style: 'COLOR' | 'LIGHT';
}
