import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-rounded-level-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rounded-level-indicator.component.html',
  styleUrls: ['./rounded-level-indicator.component.scss']
})
export class RoundedLevelIndicatorComponent {
  @Input() percentage: number = 70;
}
