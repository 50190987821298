<div class="h-screen absolute top-0 bg-black bg-opacity-60 w-full z-[999]">
    <div class="absolute w-full px-5 bottom-4">
        <div class="bg-white p-4 rounded-lg w-full space-y-4">

            <div class="bg-white border border-[#D6D6D6] p-2.5 rounded-lg">
                <img src="./assets/common/Feedback.png" alt="image" class="w-[125px] h-[125px] mx-auto">

                <div class="w-full h-[12px]"></div>
                <h4 class="text-black text-[13px] leading-[18px] font-bold">{{'MODALS.SHARE_CULTIVATION.TITLE' | translate}}</h4>
                <div class="w-full h-[16px]"></div>

                <p class="text-[#202939] text-[13px] leading-[18px]">
                    {{'MODALS.SHARE_CULTIVATION.TEXT1' | translate}}
                </p>
                <div class="w-full h-[10px]"></div>
                <p class="text-[#202939] text-[13px] leading-[18px]">
                    {{'MODALS.SHARE_CULTIVATION.TEXT2' | translate}}
                </p>

                <div class="w-full h-[30px]"></div>


                <button class="py-3 w-full bg-brand-500 rounded-lg text-white text-sm font-bold" (click)="onSubmit($event)">{{'MODALS.SHARE_CULTIVATION.SUBMIT' | translate}}</button>
            </div>

        </div>
    </div>
</div>
