import {CultivationPeriodType} from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";

export class ColorUtils {

    static getBackgroundColorFromType(type: CultivationPeriodType): string {
        switch(type) {
            case CultivationPeriodType.HARVEST:
                return '#F35227';
            case CultivationPeriodType.PLANTING:
                return '#30A156';
            case CultivationPeriodType.SEEDLING:
                return '#36B9BE';
            case CultivationPeriodType.OTHER:
                return '#7E807E';
        }
    }
}