<div class="space-y-2.5">
    <div class="">
        <div class="flex items-center gap-x-1.5">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.7304 8.94024C19.1445 5.33462 16.082 2.65224 12.4306 2.54665C12.257 2.53984 12.0878 2.60235 11.9603 2.72039C11.8328 2.83843 11.7575 3.00232 11.751 3.17594V3.17594V3.23467L12.1621 9.38494C12.1891 9.79895 12.545 10.1137 12.9592 10.0897L19.1263 9.67861C19.3 9.66576 19.4615 9.58414 19.5749 9.45184C19.6883 9.31955 19.7443 9.14749 19.7304 8.9738V8.94024Z"
                      stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M8.1598 6.20503C8.55209 6.11429 8.95354 6.31501 9.11632 6.68329C9.15896 6.76991 9.18459 6.8639 9.19183 6.96018C9.27574 8.15164 9.45194 10.7611 9.55263 12.1707C9.56982 12.4246 9.68749 12.6612 9.87961 12.8281C10.0717 12.9951 10.3224 13.0785 10.5763 13.0601V13.0601L15.7532 12.7413C15.9844 12.7274 16.2109 12.8096 16.3794 12.9685C16.5478 13.1274 16.6431 13.3488 16.6426 13.5803V13.5803C16.4328 16.7065 14.1868 19.3199 11.1277 19.9974C8.06859 20.6748 4.92916 19.2539 3.41914 16.5086C2.96837 15.7185 2.68291 14.845 2.58009 13.9411C2.53613 13.6637 2.51927 13.3827 2.52974 13.1021C2.53865 9.76356 4.88329 6.88699 8.15141 6.20503"
                      stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <p class="text-black tracking-[1px] uppercase font-bold text-[13px] leading-6">
                {{ growingStep.name | Label }}
            </p>
        </div>
        <div>
            <p class="text-black tracking-[1px] text-[13px]">
                {{ growingStep.description | Label }}
            </p>
        </div>
        <div>
             <img src="https://source.unsplash.com/200x150/?tomatoes" alt="">
        </div>
    </div>
</div>