import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-sun-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sun-icon.component.html',
  styleUrls: ['./sun-icon.component.scss']
})
export class SunIconComponent {

}
