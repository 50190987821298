import { Component, OnInit } from "@angular/core";
import { RouterLinkActive, RouterModule, RouterOutlet } from "@angular/router";
import { DefaultLayoutComponent } from "../@commons/layouts/default-layout/default-layout.component";
import { UserSummaryComponent } from "./@components/user-summary/user-summary.component";
import { UpgradeProBtnComponent } from "../@commons/components/upgrade-pro-btn/upgrade-pro-btn.component";
import { TabMenuItem } from "../@commons/classes/tab-menu-item";
import { AppHeaderTabsComponent } from "../@commons/components/app-header-tabs/app-header-tabs.component";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { HomePageComponent } from "../home/home-page/home-page.component";
import { ModalRef } from "../@commons/services/modal/model-ref";
import { ErrorModalComponent } from "../@commons/modals/error-modal/error-modal.component";
import { ModalService } from "../@commons/services/modal/modal.service";
import { UpgradePremiumModalComponent } from "../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import { UserDto } from "vegiwise-sdk-typescript/dist/model/@common/user";
import { UserApi } from "vegiwise-sdk-typescript/dist/sdk/user/user.api";

@Component({
  selector: "vw-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    HttpClientModule,
    CommonModule,
    DefaultLayoutComponent,
    RouterModule,
    RouterLinkActive,
    UserSummaryComponent,
    UpgradeProBtnComponent,
    AppHeaderTabsComponent,
  ],
  providers: [UserApi],
})
export class SettingsComponent implements OnInit {
  tabItems: TabMenuItem[] = [
    {
      path: "/settings/contacts",
      label: "Contacts",
      style: "",
      disabled: true,
    },
    { path: "/settings/stock", label: "Stock", style: "" },
  ];
  currentUser: UserDto;

  constructor(
    private modalService: ModalService,
    private userApi: UserApi
  ) {}

  ngOnInit(): void {
    this.userApi.getCurrentUser().then((u) => {
      this.currentUser = u;
    });
  }

  onClickUpgradePro($event: MouseEvent) {
    const modal: ModalRef = this.modalService.open(
      UpgradePremiumModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }
}
