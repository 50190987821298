
<div class="px-4 pb-2">
    <h3 class="text-[15px] leading-6 font-bold">{{'TASKS.CALENDAR.TASK_SECTION_TITLE.' + monthTasks.type | translate: {month : 'COMMON.MONTHS.'+month.toUpperCase() | translate} }}</h3>
    <p class="text-xs tracking-[0.25px]">{{ ('TASKS.CALENDAR.TASK_SECTION_DESCRIPTION.' + monthTasks.type + '.' + month.toUpperCase()) | translate }}</p>
</div>

<div class="flex flex-row max-w-full gap-2 px-4 pb-2 overflow-x-auto snap-x">
    <vw-task-viewer *ngFor="let task of monthTasks.tasks" [task]="task"></vw-task-viewer>
</div>

<div class="bg-neutrals-200 h-[0.5px] w-full my-4"></div>
