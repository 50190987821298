<div>
    <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div>
    <div class="flex gap-x-4">
        <div class="min-w-[36px] flex justify-center relative">
            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
            <div class="bg-[#E2E6EC] w-[1px] h-[calc(100%-80px)]"></div>
        </div>

        <div>
            <div>
                <div class="flex items-center gap-x-4">
                    <p class="font-medium">Jonathan Smith</p>
                    <p class="text-[#475569] uppercase text-[9px] leading-[16px] font-medium tracking-[1.5px]">
                        Yesterday at 1:40 AM
                    </p>
                </div>

                <p class="text-sm leading-6 mt-2">I think this is great vegetable, What do you think about it guys?</p>

                <img src="/assets/imgs/Component4.png" alt="Image" class="rounded-lg mt-2.5" />

                <div class="flex items-center gap-x-4 mt-4">
                    <button class="px-2.5 py-1.5 rounded-full bg-[#F5F6F8] flex items-center gap-x-2.5">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M3 10C3 8.61929 4.11929 7.5 5.5 7.5C6.88071 7.5 8 8.61929 8 10V18C8 19.3807 6.88071 20.5 5.5 20.5C4.11929 20.5 3 19.3807 3 18V10Z"
                                    stroke="black"
                                    stroke-width="1.5"
                            />
                            <path
                                    d="M11.5754 4.68995L9.34488 8.31457C8.74601 9.28774 8.44657 9.77432 8.26551 10.3075C8.21028 10.4702 8.16347 10.6356 8.12526 10.8031C8 11.3521 8 11.9234 8 13.0661V16.5C8 18.7091 9.79086 20.5 12 20.5H14.9098C16.8037 20.5 18.535 19.43 19.382 17.7361L20.5305 15.439C21.6651 13.1699 20.015 10.5 17.478 10.5H16.7882C15.0593 10.5 13.7371 8.95887 14 7.25L14.243 5.67022C14.3782 4.79201 13.6987 4 12.8101 4C12.3067 4 11.8393 4.26118 11.5754 4.68995Z"
                                    stroke="black"
                                    stroke-width="1.5"
                            />
                        </svg>

                        <p class="text-sm leading-6">233</p>
                    </button>

                    <button class="px-2.5 py-1.5 rounded-full bg-[#F5F6F8] flex items-center gap-x-2.5">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M12.875 20.2493H4.96875C4.77813 20.2493 4.59531 20.1735 4.46052 20.0388C4.32573 19.904 4.25 19.7211 4.25 19.5305V11.6242C4.25 10.4916 4.47309 9.37004 4.90654 8.32361C5.33998 7.27718 5.9753 6.32637 6.7762 5.52547C7.5771 4.72456 8.52791 4.08925 9.57435 3.6558C10.6208 3.22236 11.7423 2.99927 12.875 2.99927H12.875C14.0077 2.99927 15.1292 3.22236 16.1756 3.65581C17.2221 4.08925 18.1729 4.72457 18.9738 5.52547C19.7747 6.32638 20.41 7.27719 20.8435 8.32362C21.2769 9.37006 21.5 10.4916 21.5 11.6243V11.6243C21.5 13.9118 20.5913 16.1056 18.9738 17.7231C17.3563 19.3406 15.1625 20.2493 12.875 20.2493Z"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                        </svg>

                        <p class="text-sm leading-6">15</p>
                    </button>

                    <button class="text-sm leading-6">Reply</button>
                </div>
            </div>

            <div class="space-y-6">
                <div class="flex gap-x-4">
                    <div class="min-w-[36px]">
                        <img src="/assets/imgs/avatar1.png" alt="avatar" class="w-[36px] h-[36px] rounded-full" />
                    </div>
                    <div>
                        <div class="flex items-center gap-x-4">
                            <p class="font-medium text-[15px] leading-6 text-[#475569]">Marvin McKarl</p>
                            <p class="text-[#475569] uppercase text-[8px] leading-[16px] font-medium tracking-[1.5px]">
                                2 hours ago
                            </p>
                        </div>

                        <p class="text-xs leading-[14px] mt-2 text-[#475569]">
                            Yes, Sounds great. I can think about this tomorrow. have you any plan?
                        </p>

                        <div class="flex items-center gap-x-4 mt-4">
                            <button class="px-2.5 py-1.5 rounded-full bg-brand-500 flex items-center gap-x-2.5 text-white">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M3 10C3 8.61929 4.11929 7.5 5.5 7.5C6.88071 7.5 8 8.61929 8 10V18C8 19.3807 6.88071 20.5 5.5 20.5C4.11929 20.5 3 19.3807 3 18V10Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                    />
                                    <path
                                            d="M11.5754 4.68995L9.34488 8.31457C8.74601 9.28774 8.44657 9.77432 8.26551 10.3075C8.21028 10.4702 8.16347 10.6356 8.12526 10.8031C8 11.3521 8 11.9234 8 13.0661V16.5C8 18.7091 9.79086 20.5 12 20.5H14.9098C16.8037 20.5 18.535 19.43 19.382 17.7361L20.5305 15.439C21.6651 13.1699 20.015 10.5 17.478 10.5H16.7882C15.0593 10.5 13.7371 8.95887 14 7.25L14.243 5.67022C14.3782 4.79201 13.6987 4 12.8101 4C12.3067 4 11.8393 4.26118 11.5754 4.68995Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                    />
                                </svg>

                                <p class="text-sm leading-6">1</p>
                            </button>

                            <button class="text-sm leading-6 text-brand-500">Reply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
