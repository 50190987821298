import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {TaskType} from "vegiwise-sdk-typescript/dist/enums/task-type.enum";
import {TaskViewerComponent} from "./task-viewer/task-viewer.component";
import {MonthTasks} from "vegiwise-sdk-typescript/dist/model/task/month-tasks";

@Component({
  selector: 'vw-task-type-tasks',
  standalone: true,
  imports: [CommonModule, TranslateModule, TaskViewerComponent],
  templateUrl: './task-type-tasks.component.html',
  styleUrls: ['./task-type-tasks.component.scss']
})
export class TaskTypeTasksComponent {
  @Input() monthTasks: MonthTasks;
  @Input() month: string;

  @Output() click: EventEmitter<any> = new EventEmitter<any>();
}
