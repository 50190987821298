import {Pipe, PipeTransform} from '@angular/core';
import {LabelDto} from "vegiwise-sdk-typescript/dist/model/@common/label";

export class LabelPipeParam {
    maxLength: number;
}
@Pipe({
    name: 'Label',
    standalone: true
})
export class LabelPipe implements PipeTransform {
    transform(value: LabelDto, param?: LabelPipeParam): string {
        const val = value ? value.fr: '';
        if(val && val.length && param && param.maxLength) {
            return val.substring(0, param.maxLength) + '...';
        }
        return val;
    }
}
