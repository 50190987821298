import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DefaultLayoutComponent} from '../../@commons/layouts/default-layout/default-layout.component';
import {AppDetailsHeaderComponent} from '../../@commons/components/app-details-header/app-details-header.component';
import {
    CultivationHorizontalViewerComponent
} from '../../@commons/components/cultivation-horizontal-viewer/cultivation-horizontal-viewer.component';
import {
  GrowingStepsViewerComponent
} from '../../@commons/components/growing-steps-viewer/growing-steps-viewer.component';
import {ActivatedRoute, Params, Router, RouterModule} from "@angular/router";
import {GrowingStepApi} from "vegiwise-sdk-typescript/dist/sdk/plant/growing-step.api";
import {CultivationApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {PlantApi} from "vegiwise-sdk-typescript/dist/sdk/plant/plant.api";
import {PlantDto} from "vegiwise-sdk-typescript/dist/model/@common/plant";
import {PlantToGrowingStepApi} from "vegiwise-sdk-typescript/dist/sdk/plant/plant-to-growing-step.api";

@Component({
  selector: 'vw-user-plant-details',
  standalone: true,
    imports: [CommonModule, DefaultLayoutComponent, RouterModule, AppDetailsHeaderComponent, CultivationHorizontalViewerComponent, GrowingStepsViewerComponent],
  templateUrl: './plant-details.component.html',
  styleUrls: ['./plant-details.component.scss'],
  providers: [CultivationApi, GrowingStepApi, PlantApi, PlantToGrowingStepApi]
})
export class PlantDetailsComponent {

  cultivation: CultivationDto;
  plant: PlantDto;

  constructor(private activatedRoute: ActivatedRoute,
              private cultivationApi: CultivationApi,
              private plantApi: PlantApi,
              private router: Router,
              private growingStepApi: GrowingStepApi) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const cultivationUid = params['cultivationUid'];
      const plantUid = params['plantUid'];
      this.loadCultivation(cultivationUid);
      this.loadPlant(plantUid);
    });
  }

  loadCultivation(uid: string) {
    this.cultivationApi.findByUid(uid).then((culti: CultivationDto) => {
      this.cultivation = culti;
    })
  }

  loadPlant(uid: string) {
    if(uid) {
      this.plantApi.findByUid(uid).then((p: PlantDto) => {
        this.plant = p;
      })
    }
  }
  goToCultivationDetails(cultivation: CultivationDto) {
    this.router.navigate([`cultivations/${cultivation.uid}/details`]);
  }


}
