import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Subscription} from 'rxjs';
import {TranslateModule} from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import {DotMenuService} from '../../../../services/display/dot-menu.service';
import {DotMenuItem} from '../../../../classes/dot-menu-item';
import { PictureProfileComponent } from '../../../picture-profile/picture-profile.component';
import { UserApi } from 'vegiwise-sdk-typescript/dist/sdk/user/user.api';
import { UserDto } from 'vegiwise-sdk-typescript/dist/model/@common/user';
  
@Component({
  selector: 'vw-profile-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, PictureProfileComponent],
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
  providers: [UserApi]
})
export class ProfileButtonComponent implements OnDestroy, OnInit  {
  isOpen: boolean = false;
  closeAllSubscription: Subscription;
  @Input() theme: 'white' | 'black' = 'black';
  currentUser: UserDto;
  @Input() menuItems: DotMenuItem[];

  constructor(public dotMenuService: DotMenuService, private eRef: ElementRef, private router: Router, private userApi: UserApi) {
  }

  onDotClicked($event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.dotMenuService.closeAll.emit(this);
    this.isOpen = !this.isOpen;
  }

  ngOnInit() {
    this.closeAllSubscription = this.dotMenuService.closeAll.subscribe((origin) => {
      if(origin != this) {
        this.isOpen = false;
      }
    });
    this.userApi.getCurrentUser().then((u) => {
            this.currentUser = u;
        })
  }

  ngOnDestroy() {
    this.closeAllSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.isOpen) {
      event.stopPropagation();
    }
    event.stopImmediatePropagation();
    if(this.eRef.nativeElement.contains(event.target)) {

    } else {
      this.isOpen = false;
    }
  }

  onMenuItemSelected(event: MouseEvent, menuItem: DotMenuItem) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if(menuItem) {
      if(menuItem.onClick) {
        menuItem.onClick(event);
      }
      if(menuItem.path) {
        console.log('navigating to', menuItem.path);
        this.router.navigate([menuItem.path]);
      }
    }
    this.isOpen = false;
  }
}
