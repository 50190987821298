<div class="h-screen absolute top-0 bg-black bg-opacity-60 w-full z-[999]">
    <div class="absolute w-full p-5 bottom-4 text-center">
        <button (click)="close(this.questions)"
                class="absolute right-6 text-black h-[40px] w-[40px] flex justify-center items-center">
            <vw-cross-cancel-icon></vw-cross-cancel-icon>
        </button>
        <div class="bg-white rounded-lg">
            <div class="p-3 space-y-4">
                <h2 class="text-xl py-1">Ajouter un légume</h2> 
                <hr class="my-4 border-t-1 border-zinc-300">
                <div class="flex justify-center items-center">
                    <div class="flex inline-block bg-amber-200 text-yellow-900 px-4 py-2 space-x-2 rounded-full">
                        <svg fill="currentColor" height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 512 512" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M502.4,310.4c-24-73.6-73.6-108.8-118.4-139.2c-48-33.6-88-62.4-96-131.2c0-6.4-1.6-12.8-1.6-19.2
                                    c1.6-1.6,1.6-3.2,1.6-4.8c0-9.6-6.4-16-16-16h-32c-9.6,0-16,6.4-16,16c0,1.6,0,1.6,0,3.2c0,6.4,0,12.8,0,19.2
                                    c-6.4,70.4-48,99.2-96,132.8C83.2,201.6,33.6,236.8,9.6,310.4S16,441.6,52.8,472c22.4,17.6,49.6,27.2,76.8,27.2
                                    c17.6,0,35.2-4.8,52.8-12.8c22.4,16,46.4,25.6,73.6,25.6c27.2,0,51.2-9.6,73.6-25.6c16,8,33.6,12.8,52.8,12.8
                                    c27.2,0,54.4-9.6,76.8-27.2C496,443.2,526.4,385.6,502.4,310.4z M73.6,446.4C44.8,424,20.8,379.2,40,320
                                    c20.8-62.4,62.4-92.8,105.6-123.2c11.2-8,22.4-16,33.6-25.6c-6.4,11.2-12.8,24-19.2,35.2c-19.2,35.2-36.8,65.6-40,96
                                    c-9.6,64,3.2,120,36.8,160C129.6,472,97.6,465.6,73.6,446.4z M326.4,443.2c-1.6,3.2-4.8,4.8-6.4,8c-3.2,1.6-4.8,3.2-6.4,6.4
                                    C296,472,276.8,480,256,480s-40-8-57.6-20.8c-1.6-3.2-4.8-4.8-6.4-6.4c-3.2-1.6-4.8-4.8-6.4-8c-28.8-32-40-83.2-32-137.6
                                    c3.2-24,19.2-52.8,36.8-84.8c25.6-46.4,56-102.4,64-169.6v-1.6c0-3.2,1.6-8,1.6-12.8c0,1.6,0,4.8,0,6.4c0,3.2,0,6.4,1.6,9.6
                                    c9.6,65.6,38.4,121.6,64,166.4c17.6,33.6,33.6,60.8,36.8,84.8C366.4,361.6,355.2,411.2,326.4,443.2z M438.4,446.4
                                    c-25.6,19.2-56,25.6-84.8,16c32-40,44.8-96,36.8-160c-4.8-28.8-20.8-60.8-40-96c-6.4-11.2-12.8-22.4-17.6-33.6
                                    c11.2,8,22.4,16,33.6,24C411.2,227.2,452.8,256,472,320C491.2,379.2,467.2,424,438.4,446.4z"/>
                            </g>
                        </g>
                        </svg>
                        <p>
                            Ail
                        </p>
                    </div>
                </div>
                <p>Définir les détails de la plante</p>
                <div class="flex justify-between items-center bg-white rounded-lg shadow-xl p-3 mb-2 border">
                    <div class="flex items-center  space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fb923c" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>
                        <p class="font-light">
                            Date de la plantation
                        </p>
                    </div>
                    <div class="flex items-center ">
                        <input type="date" class="appearance-none bg-transparent border-none">
                        <!-- <select class="appearance-none bg-transparent border-none">
                            <option value="today">Aujourd'hui</option>
                            <option value="yesterday">Hier</option>
                            <option value="date">Lundi 06 mai</option>
                        </select> -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
                <div class="flex justify-between items-center bg-white rounded-lg shadow-xl p-3 mb-2 border">
                    <div class="flex items-center space-x-2">
                        <div>                        
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fb923c" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                        </div>
                        <p class="font-light">
                            Lieu
                        </p>
                    </div>
                    <div class="flex items-center ">
                        <select class="appearance-none bg-transparent border-none">
                            <option value="jardiniere">Jardinière</option>
                            <option value="terre">Terre</option>
                            <option value="pot">Pot</option>
                            <!-- Ajoutez autant d'options que nécessaire -->
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
                <div class="flex justify-between items-center bg-white rounded-lg shadow-xl p-3 mb-2 border">
                    <div class="flex items-center  space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fb923c" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                    </svg>
                        <p class="font-light">
                            Etape de croissance
                        </p>
                    </div>
                    <div class="flex items-center ">
                        <select class="appearance-none bg-transparent border-none">
                            <option value="step1">Step 1</option>
                            <option value="step2">Step 2</option>
                            <option value="step3">Step 3</option>
                            <!-- Ajoutez autant d'options que nécessaire -->
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
                <div class="flex justify-between items-center bg-white rounded-lg shadow-xl p-3 mb-2 border">
                    <div class="flex items-center  space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fb923c" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0s 1 0 7.5 7.5h-7.5V6Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                    </svg>
                        <p class="font-light">
                            Dernier arrosage
                        </p>
                    </div>
                    <div class="flex items-center ">
                        <select class="appearance-none bg-transparent border-none">
                            <option value="today">Aujourd'hui</option>
                            <option value="yesterday">Hier</option>
                            <option value="date">Lundi 06 mai</option>
                            <!-- Ajoutez autant d'options que nécessaire -->
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
                <div class="text-stone-500">
                    <p>Les informations serviront à vous fournir les tâches et l'entretien les plus appropriés</p>
                </div>
                <div class="flex justify-center">
                    <div class="bg-orange-400 p-3 inline-block rounded-full">
                        <p class="text-white">Ajouter le plant</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
