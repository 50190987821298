import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Modal} from "../../services/modal/modal";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'vw-share-cultivation-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './share-cultivation-modal.component.html',
  styleUrls: ['./share-cultivation-modal.component.scss']
})
export class ShareCultivationModalComponent extends Modal{

  remarks: string = '';
  onInjectInputs(inputs: any): void {
  }

  onCloseClick($event: MouseEvent) {
    this.close('Test');
  }

  onSubmit($event: MouseEvent) {
    //TODO SEND TO BACKEND
    this.close();
  }
}
