import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DotMenuItem} from '../../../../@commons/classes/dot-menu-item';
import {DotButtonComponent} from '../../../../@commons/components/@generic/buttons/dot-button/dot-button.component';

@Component({
  selector: 'vw-contact-list-item',
  standalone: true,
  imports: [CommonModule, DotButtonComponent],
  templateUrl: './contact-list-item.component.html',
  styleUrls: ['./contact-list-item.component.scss']
})
export class ContactListItemComponent {

  menuItems: DotMenuItem[] = [
    {path: '', label: 'Delete', style: ''},
    {path: '', label: 'Edit', style: ''},
    {path: '', label: 'View profile', style: ''},
    {path: '', label: 'Go to conversation', style: ''},
  ]

}
