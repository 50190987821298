
<main class="max-w-[600px] min-h-screen h-screen mx-auto flex flex-col justify-between overflow-hidden">
    <section class="px-6">
        <div class="h-[64px] w-full"></div>
        <img src="/assets/common/vegiwise_logo_name_fullwidth.svg" alt="logo" class=" h-[100px] min-w-[116px] mx-auto"/>
        <div class="h-[50px] w-full"></div>
        <div class="w-full max-w-full">
            <form [formGroup]="form">
                <div class="mb-6">
                    <label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'LOGIN.FORM.EMAIL.LABEL' | translate }} </label>
                    <vw-text-input [placeholder]="'LOGIN.FORM.EMAIL.PLACEHOLDER'" [type]="'email'"
                                   [iconTemplate]="mailIcon"
                                   formControlName="emailCtrl">
                        <ng-template #mailIcon>
                            <vw-mail-icon></vw-mail-icon>
                        </ng-template>
                    </vw-text-input>
                </div>
                <div class="mb-2.5">
                    <label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'LOGIN.FORM.PASSWORD.LABEL' | translate }} </label>
                    <vw-text-input [placeholder]="'LOGIN.FORM.PASSWORD.PLACEHOLDER'" [type]="'password'"
                                   [iconTemplate]="keyIcon"
                                   formControlName="passwordCtrl">
                        <ng-template #keyIcon>
                            <vw-key-icon></vw-key-icon>
                        </ng-template>
                    </vw-text-input>
                </div>
                <div class="flex justify-end">
                    <vw-link [label]="'LOGIN.FORGOT_PASSWORD'" ></vw-link>
                </div>
                <!-- Error Message -->
                <vw-error-message *ngFor="let error of errors" [label]="error.label"></vw-error-message>
                <div class="h-[50px] w-full"></div>
                <div class="flex items-center gap-x-[12px]">
                    <button class="bg-white hover:bg-white/80 border-[0.5px] border-[#D0D0D1] rounded-[7px] text-base font-medium text-[#202939] px-6 py-2.5 w-full"
                            type="button">
                        {{ 'LOGIN.FORM.REGISTER' | translate }}
                    </button>
                    <button class="bg-[#30A156] hover:bg-[#30A156]/80 border-[0.5px] border-[#30A156] rounded-[7px] text-base font-medium text-white px-6 py-2.5 w-full"
                            type="button">
                        {{ 'LOGIN.FORM.LOGIN' | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="h-[64px] w-full"></div>
    </section>

</main>
