import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionType} from "vegiwise-sdk-typescript/dist/enums/question-type";
import {QuestionDto} from "vegiwise-sdk-typescript/dist/model/@common/question";
import {AnswerDto} from "vegiwise-sdk-typescript/dist/model/@common/answer";
import {LabelPipe} from "../../../pipes/LabelPipe";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'vw-question-asker',
  standalone: true,
  imports: [CommonModule, LabelPipe],
  templateUrl: './question-asker.component.html',
  styleUrls: ['./question-asker.component.scss']
})
export class QuestionAskerComponent implements OnInit{
  @Input() question: QuestionDto;
  QuestionType = QuestionType;
  @Output() respond: EventEmitter<AnswerDto> = new EventEmitter<any>()

  toggle(answer: AnswerDto) {
    switch (this.question.type) {
      case QuestionType.SIMPLE:
      case QuestionType.YESNO:
        this.deselectAll();
        break;
    }
    answer.selected = !answer.selected;
    this.respond.emit(answer);
  }

  ngOnInit() {
    if(this.question) {
      switch (this.question.type) {
        case QuestionType.YESNO:
          //this.populateYesNo();
          break;
      }
    }
  }

  deselectAll() {
    this.question.answers.forEach((answer) => {
      answer.selected = false;
    })
  }

  /*populateYesNo() {
    this.question.answers = [
      {title: 'Oui'}, {title: 'Non'}
    ]
  }*/
}
