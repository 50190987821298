<!-- Height : 100% - tabbar height -->
<section id="map" class="relative h-[calc(100%-56px)] overflow-hidden">
    <div  class="h-full map-container"
         leaflet
         [leafletOptions]="options"
         (leafletMapReady)="onMapReady($event)"
         (leafletMapZoomEnd)="onMapZoomEnd($event)">
    </div>
</section>
<vw-map-panel style="height: 0px" [class.visible]="selectedNeighbour != null"
              [cultivations]="selectedNeighbour?.cultivations"
></vw-map-panel>
