import {BaseService} from '../base.service';
import {HTTPService} from '../front/HTTP.service';
import {QueryParamService} from '../front/query-param.service';
import {environment} from "../../../../environments/environment";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class DmsService extends BaseService<any, any, any> {

    constructor(private httpClient: HTTPService, queryParamService: QueryParamService) {
        super('/api/v1/dms', httpClient, queryParamService);
    }

    public getFile(file: any): any {
        return this.httpClient.get(this.path + '/file/' + file.uid).toPromise()
    }

    public getFileUrl(fileUid: string, width: number = null, height: number = null, format: string = 'auto'): string {
        return this.queryParamService.addExtraQueryParams(
            new Map([
                ['format', format ? format.toString() : null],
                ['width', width ? width.toString() : null],
                ['height', height ? height.toString() : null],
            ]),
            `${environment.GET_FILE_BASE_URL}/${fileUid}`
        );
    }

    public async uploadFile(file: File): Promise<any> {
        /*  const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
          });
          const options: any = {headers: headers}*/

        let doc: any = {};
        doc.uid = '';
        doc.name = file.name;
        doc.scope = 'dms';
        let formParams = new FormData();
        formParams.append('file', file);
        return this.httpClient.post<any>(this.path + '/file', formParams).toPromise();
    }


    public async uploadFiles(files: File[]): Promise<any[]> {
        /*  const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
          });
          const options: any = {headers: headers}*/
        let formParams = new FormData();
        for (let i = 0; i < files.length; i++) {
            formParams.append('files', files[i]);
        }

        return this.httpClient.post<any[]>(this.path + '/files', formParams).toPromise();
    }


}
