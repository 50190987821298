import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[skeleton]",
  standalone: true,
})
export class SkeletonDirective {
  private skeletonElement: HTMLElement | null = null;
  private styleHeight: string = "h-4";
  private styleWidth: string = "w-full";
  private styleCorner: string = "rounded";
  private styleGrey: string = "bg-gray-500";
  private isAnimated: boolean = true;

  private tagName: string;
  private isLoaded: boolean = false;
  private minimumSkeletonTime: number = 500; // 500ms minimum skeleton time
  private skeletonAddedTime: number = 0;
  private imgSrc: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.tagName = el.nativeElement.tagName.toLowerCase();
    if (this.tagName != "img") {
      this.checkHasText();
    } else {
      this.addSkeleton(); // For images, add skeleton initially
    }
  }

  @HostListener("DOMSubtreeModified", ["$event"])
  onTextChange(event: Event): void {
    if (this.tagName != "img") {
      this.checkHasText();
    }
  }

  @HostListener("load", ["$event"])
  onImageLoad(event: Event): void {
    if (this.tagName === "img") {
      this.removeSkeleton();
    }
  }

  /**
   * Checks the text value of the component in order to add a skeleton loader while it's loading
   */
  checkHasText() {
    const textContent = this.el.nativeElement.textContent;
    // If the text content is empty, add a skeleton loader
    // Or if the text content is not fullfilled if contains variables
    if (
      !textContent ||
      textContent.trim() === "" ||
      textContent.match(/{{(.*?)}}/g)
    ) {
      this.addSkeleton();
    } else {
      this.removeSkeleton();
    }
  }

  /**
   * Add a skeleton loader to the component to display instead of the text or image
   */
  addSkeleton() {
    if (!this.skeletonElement) {
      this.skeletonElement = this.el.nativeElement;
      this.skeletonAddedTime = Date.now();
      this.renderer.addClass(this.skeletonElement, this.styleGrey);
      this.renderer.addClass(this.skeletonElement, this.styleHeight);
      this.renderer.addClass(this.skeletonElement, this.styleWidth);
      this.renderer.addClass(this.skeletonElement, this.styleCorner);
      if (this.tagName === "img") {
        this.renderer.setStyle(this.skeletonElement, "visibility", "hidden");
      } else {
        this.renderer.addClass(this.skeletonElement, "!text-transparent");
      }
      if (this.isAnimated) {
        this.renderer.addClass(this.skeletonElement, "animate-pulse");
      }
    }
  }

  /**
   * Remove the skeleton loader and restore the normal state of the component
   */
  removeSkeleton() {
    const currentTime = Date.now();
    const timeElapsed = currentTime - this.skeletonAddedTime;

    if (timeElapsed < this.minimumSkeletonTime) {
      setTimeout(() => {
        this.performRemoveSkeleton();
      }, this.minimumSkeletonTime - timeElapsed);
    } else {
      this.performRemoveSkeleton();
    }
  }

  /**
   * Perform the actual removal of the skeleton loader
   */
  performRemoveSkeleton() {
    if (this.skeletonElement) {
      this.renderer.removeClass(this.skeletonElement, this.styleGrey);
      this.renderer.removeClass(this.skeletonElement, this.styleHeight);
      this.renderer.removeClass(this.skeletonElement, this.styleWidth);
      this.renderer.removeClass(this.skeletonElement, this.styleCorner);
      if (this.tagName === "img") {
        this.renderer.setStyle(this.skeletonElement, "visibility", "visible");
      } else {
        this.renderer.removeClass(this.skeletonElement, "!text-transparent");
      }
      if (this.isAnimated) {
        this.renderer.removeClass(this.skeletonElement, "animate-pulse");
      }
      this.skeletonElement = null;
    }
  }
}
