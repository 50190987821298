<button
  [disabled]="disabled"
  [class.opacity-50]="disabled"
  [routerLink]="path"
  routerLinkActive="app-menu-item-active"
  class="app-menu-item flex flex-col items-center gap-y-0.5 relative"
>
  <img [src]="logoPath" alt="{{ label | translate }}" />
  <p class="text-[11px] app-menu-label color-primary">
    {{ label | translate }}
  </p>
</button>
