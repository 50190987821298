import { Component, OnInit } from "@angular/core";
import { RouterModule, RouterOutlet } from "@angular/router";
import { DefaultLayoutComponent } from "../@commons/layouts/default-layout/default-layout.component";
import { AppHeaderTabsComponent } from "../@commons/components/app-header-tabs/app-header-tabs.component";
import { TabMenuItem } from "../@commons/classes/tab-menu-item";
import { ModalService } from "../@commons/services/modal/modal.service";
import { UpgradePremiumModalComponent } from "../@commons/modals/upgrade-premium-modal/upgrade-premium-modal.component";
import { ModalRef } from "../@commons/services/modal/model-ref";
import { UpgradeProBtnComponent } from "../@commons/components/upgrade-pro-btn/upgrade-pro-btn.component";

@Component({
  selector: "vw-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    DefaultLayoutComponent,
    RouterModule,
    AppHeaderTabsComponent,
    UpgradeProBtnComponent,
  ],
})
export class SocialComponent implements OnInit {
  public tabItems: TabMenuItem[] = [
    {
      path: "/social/media",
      label: "Media",
      style: "",
    },
    {
      path: "/social/wall",
      label: "Wall",
      style: "",
    },
  ];
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  onClickUpgradePro($event: MouseEvent) {
    const modal: ModalRef = this.modalService.open(
      UpgradePremiumModalComponent
    );

    modal.onResult().subscribe((res) => {
      //console.log(res);
    });
  }
}
