<div class="grid grid-cols-2">
  <!--mt-2.5-->
  <button
    [disabled]="tab.disabled"
    [routerLink]="tab.path"
    *ngFor="let tab of tabs"
    routerLinkActive="sub-menu-active"
    class="text-[#475569] border-[#F2F3F4] py-[14.5px] w-full border-b-2 font-normal text-[15px] leading-[21px]"
    [class.text-opacity-60]="tab.disabled"
  >
    {{ tab.label | translate }}
  </button>
  <!--font-medium-->
</div>
