import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import { DefaultLayoutComponent } from '../@commons/layouts/default-layout/default-layout.component';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {HomePageComponent} from './home-page/home-page.component';
import { PlusIconComponent } from "../@commons/icons/plus-icon/plus-icon.component";
import {UserApi} from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import {UserDto} from "vegiwise-sdk-typescript/dist/model/@common/user";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import { UploadFileRes } from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";
import { DotButtonComponent } from '../@commons/components/@generic/buttons/dot-button/dot-button.component';
import { DotMenuItem } from '../@commons/classes/dot-menu-item';
import {DmsApi} from "vegiwise-sdk-typescript/dist/sdk/dms/dms.api";
import {DmsService} from "../@commons/services/vegiwise/dms.service";
import {AuthApi} from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import {WebViewBridgeService} from "../@commons/services/webview/webview-bridge.service";
import { ProfileButtonComponent } from "../@commons/components/@generic/buttons/profile-button/profile-button.component";

@Component({
    selector: 'vw-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    providers: [DmsApi, AuthApi, UserApi],
    imports: [RouterOutlet, HttpClientModule, CommonModule, DefaultLayoutComponent, HomePageComponent, PlusIconComponent, DotButtonComponent, ProfileButtonComponent]
})
  
export class HomeComponent implements OnChanges, OnInit {
  @Input() currentUser: UserDto;
  registrationDate: string;
  @ViewChild("fileInput") fileInput: ElementRef;
  file: ExternalFileDto;
  pictureUrl: string;

   constructor(private dmsApi: DmsApi,
              private authApi: AuthApi,
              private dmsService: DmsService,
              private webviewBridgeService: WebViewBridgeService,
              private userApi: UserApi,
              private router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
  }

  onSelectCultivationToAdd($event: MouseEvent) {
      this.router.navigate([`cultivations/select`]);
  }

    menuItems: DotMenuItem[] = [
    /*{path: '', label: 'Partager mon Profil', style: ''},*/
    {
      path: '', label: 'SETTINGS.USER_INFO.MENU.CHANGE_PICTURE', style: '', onClick: () => {
        this.uploadProfilePicture()
    }},
    {path: '/user/settings', label: 'SETTINGS.USER_INFO.MENU.UPDATE_PROFILE', style: ''},
    {path: '/onboarding', label: 'SETTINGS.USER_INFO.MENU.PERSONALIZE_XP', style: ''},
    {path: '', label: 'SETTINGS.USER_INFO.MENU.LOGOUT', style: '', onClick: () => {
        this.logout()
      }},
    ]
    logout() {
    this.authApi.logout();
    this.router.navigate(['auth/login']);
  }
  
    uploadProfilePicture() {
    this.fileInput.nativeElement.click();
    /*const sub = this.webviewBridgeService.permissionAskedSubject.subscribe((res) => {
      console.log(res);
      this.fileInput.nativeElement.click();
    });
    this.webviewBridgeService.postMessageToWebview({
      payload: {},
      type: WebViewAction.askPermission
    });*/
    }
  
  //   onFileSelected(event: any) {
  //   this.selectedImage = event.target.files[0];
  //   this.previewImage(this.selectedImage);

  //   this.dmsApi.getPreSignedUrl().then((urlData: PreSignedUrlRes) => {
  //     let file = event.target.files[0];
  //     if (file) {
  //       this.dmsApi.uploadToPreSignedUrl(file, urlData).then((data: UploadFileRes) => {
  //         this.file = {
  //           fileKey: data.fileUid,
  //           fileOrigin: ExternalFileOrigin.USER,
  //           name: this.selectedImage.name,
  //           bucketId: '',
  //           scope: '',
  //           contentType: this.selectedImage.type
  //         }
  //         this.buildPictureUrl();
  //         this.currentUser.profilePicture = this.file;
  //         this.userApi.upsert(this.currentUser).then(() => {
  //           console.log('user updated');
  //         });
  //       }).catch((e)=> {
  //         console.error(e);
  //       });
  //     }
  //   });
  // }

  buildPictureUrl() {
    if(this.file && this.file.fileKey) {
      this.pictureUrl = `url(${this.dmsService.getFileUrl(this.file.fileKey, null, 50)})`;
    } else {
      this.pictureUrl = null;
    }
  }

  previewImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.pictureUrl = `url(${e.target.result.toString()})`;
    };
    reader.readAsDataURL(file);
  }

  // get selectedImageSize(): string {
  //   // If less than 1 MO => display in KO
  //   if (this.selectedImage && this.selectedImage.size < 1000000) {
  //     return `${Math.round(this.selectedImage.size / 1000)} ko`;
  //   }
  //   // If more than 1 MO => display in MO
  //   if (this.selectedImage && this.selectedImage.size >= 1000000) {
  //     return `${Math.fround(this.selectedImage.size / 1000000).toFixed(1)} Mo`;
  //   }
  //   return "";
  // }
}
