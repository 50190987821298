import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vw-marketplace-home',
    templateUrl: './marketplace-home.component.html',
    styleUrls: ['./marketplace-home.component.scss'],
    standalone: true
})
export class MarketplaceHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
