<vw-default-layout [bodyTemplate]="bodyT" [headerAdditionalComponent]="headerComponents">
   <ng-template #bodyT>
        <router-outlet></router-outlet>
    </ng-template>-

    <ng-template #headerComponents>
        <vw-profile-button [menuItems]="menuItems"></vw-profile-button>
        <!-- <button class=" h-[40px] w-[40px]" (click)="onSelectCultivationToAdd($event)">
            <vw-plus-icon class="text-gray-700"></vw-plus-icon>
        </button> -->
    </ng-template>
</vw-default-layout>


