import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultLayoutComponent} from '../@commons/layouts/default-layout/default-layout.component';
import {Router, RouterModule} from '@angular/router';
import {QuestionFooterComponent} from '../@commons/components/question/question-footer/question-footer.component';
import {QuestionAskerComponent} from '../@commons/components/question/question-asker/question-asker.component';
import {QuestionProgressionComponent} from '../@commons/components/question/question-progression/question-progression.component';
import {CrossCancelIconComponent} from "../@commons/icons/cross-cancel-icon/cross-cancel-icon.component";
import {UserApi} from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import {QuestionDto} from "vegiwise-sdk-typescript/dist/model/@common/question";
import {AnswerDto} from "vegiwise-sdk-typescript/dist/model/@common/answer";

@Component({
  selector: 'vw-onboarding',
  standalone: true,
  imports: [CommonModule, DefaultLayoutComponent, RouterModule, QuestionFooterComponent, QuestionAskerComponent, QuestionProgressionComponent, CrossCancelIconComponent],
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  providers: [UserApi]
})
export class OnboardingComponent implements OnInit{
  NEXT_QUESTION_AUTO_TIMEOUT_DELAY: number = 1500;
  questions: QuestionDto[] = [];
  currentQuestion: QuestionDto;
  currentQuestionIndex: number = 0;

  constructor(private router: Router, private userApi: UserApi) {
  }
  ngOnInit() {
    this.currentQuestionIndex = 0;
    this.loadQuestions();
  }

  onValidate(event) {
    console.log('CALLING BACKEND TO VALIDATE ANSWER');
    this.next();
  }

  onSkip(event) {
    this.next();
  }

  skipAll() {
    this.redirectHome();
  }

  previous() {
    if(this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
    } else {
      this.router.navigate(['..'])
    }  }

  next() {
    if(this.currentQuestionIndex < this.questions.length -1) {
      this.currentQuestionIndex++;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
    } else {
      this.redirectHome();
    }
  }

  redirectHome() {
    this.router.navigate(['/home']);
  }

  loadQuestions() {
    this.userApi.getOnboardingQuestions().then((questions: QuestionDto[]) => {
      this.questions = questions;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
    })
    /*this.questions = [
      {type: QuestionType.YESNO, title: '', content: 'Est-ce que tu as une serre ? ', icon: ''},
      {type: QuestionType.SIMPLE, title: '', content: 'Quel est ton niveau d\'expérience ?', icon: '', answers: [
          {title: 'Novice'}, {title: 'Amateur'}, {title: 'Expert'}]
      },
      {type: QuestionType.SIMPLE, title: '', content: 'Quand préféres-tu travailler au potager ?', icon: '', answers: [
          {title: 'Un peu tous les jours'}, {title: 'Le week-end'}, {title: 'Tout le temps'} ]
      },
      {type: QuestionType.MULTI, title: '', content: 'Quel est ton objectif ?', icon: '', answers: [
          {title: 'Auto-suffisance alimentaire'}, {title: 'Plaisir de jardiner'}, {title: 'Manger sainement'}, {title: 'Développer une activité commerciale'}, {title: 'Faire des économies'} ]
      },
      {type: QuestionType.SIMPLE, title: '', content: 'Culture en pot, ou en pleine terre ?', icon: '', answers: [
          {title: 'En pots'}, {title: 'En pleine terre'} ]
      },
    ]*/
  }


  onResponseSelected(answer: AnswerDto) {
    this.currentQuestion.selectedAnswer = answer;
    setTimeout(() => {
      this.next();
    }, this.NEXT_QUESTION_AUTO_TIMEOUT_DELAY);
  }
}
