<div class="p-4 bg-neutral-100 rounded-lg w-full shadow-md mb-3" (click)="goToDetails($event)">
    <vw-growing-step-viewer *ngIf="plantToGrowingStep"
                            [growingSteps]="cultivation?.growingSteps"
                            [plantToGrowingStep]="plantToGrowingStep"
                            [growingStep]="plantToGrowingStep?.growingStep" (delete)="onDelete()"
                            class="block w-100"></vw-growing-step-viewer>
    <div class="flex items-center gap-2.5 w-full pr-2">
        <p class="my-2 text-neutrals-700" skeleton> {{ plantToGrowingStep?.growingStep?.description | Label: {maxLength: 50} }} </p>
        <button class="relative mr-0 align-middle ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 7L14.5 12L9.5 17" stroke="black" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </svg>
        </button>
    </div>
</div>
