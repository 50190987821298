import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {LabelPipe} from "../../../@commons/pipes/LabelPipe";
import {
    CultivationItemActionComponent
} from "../../../@commons/components/@cultivation-specific/cultivation-item-action/cultivation-item-action.component";
import {
    CultivationRankingComponent
} from "../../../@commons/components/@cultivation-specific/cultivation-ranking/cultivation-ranking.component";
import {FileUtils} from "../../../@commons/utils/file-utils";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {
    RoundedLevelIndicatorComponent
} from "../../../@commons/components/@generic/rounded-level-indicator/rounded-level-indicator.component";
import {DmsService} from "../../../@commons/services/vegiwise/dms.service";
import {DotsIcon} from "../../../@commons/icons/3-dots-icon/3-dots-icon.component";
import {DotButtonComponent} from "../../../@commons/components/@generic/buttons/dot-button/dot-button.component";
import {DotMenuItem} from "../../../@commons/classes/dot-menu-item";

@Component({
    selector: 'vw-cultivation-item',
    templateUrl: './cultivation-item.component.html',
    styleUrls: ['./cultivation-item.component.scss'],
    standalone: true,
    imports: [NgIf, CultivationItemActionComponent, LabelPipe, NgTemplateOutlet, CultivationRankingComponent, RoundedLevelIndicatorComponent, DotsIcon, NgClass, DotButtonComponent]
})
export class CultivationItemComponent implements OnInit {

    @Input() cultivation: CultivationDto;
    @Input() mode: 'select' | 'info' = 'info';
    isPendingAction: boolean = false;
    cultivationPictureUrl = '';
    backgroundColor: string;
    @Input() selected: boolean = false;
    @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() delete: EventEmitter<CultivationDto> = new EventEmitter<CultivationDto>();

    dotMenuItems: DotMenuItem[] = [
        {path: '', label: 'CULTIVATIONS.ITEM.MENU.DELETE', style: '', onClick: () => {this.delete.emit(this.cultivation)}}
    ]

    constructor(private dmsService: DmsService) {
    }

    ngOnInit(): void {
        if(this.cultivation) {
            if (this.cultivation.risks) {
                this.isPendingAction = this.cultivation.risks.length > 0;
            }
            const fileUid: string = FileUtils.getFileUidFromCollection(this.cultivation.files,  null );
            if (fileUid) {
                this.cultivationPictureUrl = `url(${this.dmsService.getFileUrl(fileUid, null, 300)})`;
                console.log(this.cultivationPictureUrl);
                this.backgroundColor = this.cultivation.backgroundColor;
            }
        }
    }

    onClick($event: MouseEvent) {
        if(this.mode === 'select') {
            this.selected = !this.selected;
            this.selectedChange.emit(this.selected);
        }

    }
}
