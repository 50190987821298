import {CultivationPeriodType} from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";

export class FileUtils {

    static getFileUidFromCollection( files: ExternalFileDto[], type: any, returnFirst: boolean = true): string {
        return files ? files[0]?.fileKey  : "";
    }
    static getFileFromCollection(files: ExternalFileDto[], type: any, returnFirst: boolean = true): ExternalFileDto {
        return files ? files[0] : null
    }
}