import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ErrorResponse} from "vegiwise-sdk-typescript/dist/model/error.response";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  MAX_ERROR_STRING_LENGTH_DISPLAY = 250;
  trans: any = null;

  constructor(
   // private toastr: ToastrService,
    private translateService: TranslateService
  ) { }


  handleError(error: ErrorResponse) {
    if (error.requestUid) {
      if (!this.trans) {
        this.loadtrans(this.handleError.bind(this), error);
      } else {
        const errorLabels = this.trans[error.statusCode] ? this.trans[error.statusCode] : this.trans['DEFAULT'];
        switch (error.statusCode) {
          case 400:
          case 401:
            /*this.toastr.warning(this.getErrorMessage(errorLabels, error), errorLabels.TITLE, {
              enableHtml: true, closeButton: true, tapToDismiss: false
            });*/
            break;
          default:
            /*this.toastr.error(this.getErrorMessage(errorLabels, error), errorLabels.TITLE, {
              enableHtml: true, closeButton: true, tapToDismiss: false
            });*/
            break;
        }
      }
    }
  }

  getErrorMessage(errorLabels, error: ErrorResponse) {
    // we could add error message after;
    return `${(errorLabels.MSG ? errorLabels.MSG : error.message)}.<br/>Request id : ${error.requestUid}`;
  }

  truncateMsg(error: ErrorResponse) {
    if (error && error.message && error.message.length > this.MAX_ERROR_STRING_LENGTH_DISPLAY) {
      return error.message.substr(0, this.MAX_ERROR_STRING_LENGTH_DISPLAY - 1) + '&hellip;';
    }
    return error.message;
  }

  loadtrans(callBack, errorPayload) {
    this.translateService.get('COMMON.ERRORS').subscribe((res) => {
      this.trans = res;
      callBack(errorPayload);
    }, (error) => {
      //this.toastr.error(error.toString(), 'Unable to get error message');
    });
  }

}
