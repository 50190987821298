import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import { BaseContextedPage } from '../../abstract/base-contexted-page';
import { Context, ContextService } from '../../services/context.service';
import { AppMenuItemComponent } from './app-menu-item/app-menu-item.component';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'vw-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
    standalone: true,
    imports: [AppMenuItemComponent, CommonModule]
})
export class AppMenuComponent {

  constructor(
  ) {
  }
}
