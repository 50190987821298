import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Modal} from "../../services/modal/modal";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'vw-delete-item-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './delete-item-modal.component.html',
  styleUrls: ['./delete-item-modal.component.scss']
})
export class DeleteItemModalComponent extends Modal{

  WAIT_BEFORE_DELETE_SECONDS = 2;
  onInjectInputs(inputs: any): void {
  }

  onOpen() {
    super.onOpen();
    setTimeout(() => {
      this.close({deleteConfirmed: true});
    }, this.WAIT_BEFORE_DELETE_SECONDS * 1000)
  }

  onCancel($event: MouseEvent) {
    this.dismiss({deleteConfirmed: false});
  }
}
