<section class="h-full p-3 overflow-y-auto"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="500"
         (scrolled)="loadMore($event)"
         (scroll)="onScroll($event)"
         [scrollWindow]="false">

    <vw-article-creator></vw-article-creator>
    <div>
        <vw-article *ngFor="let article of articles"></vw-article>
    </div>
</section>
