<div *ngIf="!periods || (periods.length == 0)" class="h-[24px] w-[24px] bg-gray-400 text-white flex items-center justify-center rounded-full uppercase">
    {{'COMMON.LETTER_MONTHS.' + month.toUpperCase() | translate}}
</div>
<!--<div *ngIf="periods && periods.length == 1" class="h-[24px] w-[24px] text-white flex items-center justify-center rounded-full uppercase"
     [style.background-color]="backgroundColors[0]">
    {{'COMMON.LETTER_MONTHS.' + month.toUpperCase() | translate}}
</div>-->
<div *ngIf="periods && periods.length > 1" class="h-[24px] w-[24px] text-white flex items-center justify-center rounded-full uppercase"
     [style.background-image]="backgroundColorStyle">
    {{'COMMON.LETTER_MONTHS.' + month.toUpperCase() | translate}}
</div>
<div *ngIf="periods && periods.length == 1" class="h-[24px] w-[24px] text-white flex items-center justify-center rounded-full uppercase"
     [style.background-color]="backgroundColorStyle">
    {{'COMMON.LETTER_MONTHS.' + month.toUpperCase() | translate}}
</div>
<!--<div *ngIf="periods && periods.length == 3" class="h-[24px] w-[24px] bg-gradient-to-b from-33% to-33%
text-white flex items-center justify-center rounded-full uppercase"
     [class]="'from-['+this.backgroundColors[0]+'] via-['+this.backgroundColors[1]+'] to-['+this.backgroundColors[2]+']'">
    {{'COMMON.LETTER_MONTHS.' + month.toUpperCase() | translate}}
</div>-->