<form class="relative">
    <div class="absolute top-0 right-0">
        <input type="file" id="file-input" class="hidden" />
        <label for="file-input">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M9 20.5L11.6727 15.7714C11.8024 15.5419 11.9163 15.3404 12.0182 15.1622M12.0182 15.1622C12.3341 14.6097 12.535 14.2806 12.7358 14.0336C14.3978 11.9885 17.3618 11.5845 19.5106 13.1101C19.8516 13.3522 20.2309 13.7309 20.985 14.485M12.0182 15.1622L11.5 15C10.3045 14.7609 9.70672 14.6413 9.15808 14.653C7.68985 14.6842 6.30956 15.3593 5.38354 16.4991C5.03751 16.925 4.76489 17.4702 4.21965 18.5607L4.10982 18.7804L3.98875 18.9849M20.985 14.485C21 13.7605 21 12.939 21 12C21 8.25027 21 6.3754 20.0451 5.06107C19.7367 4.6366 19.3634 4.26331 18.9389 3.95491C17.6246 3 15.7497 3 12 3C8.25027 3 6.3754 3 5.06107 3.95491C4.6366 4.26331 4.26331 4.6366 3.95491 5.06107C3 6.3754 3 8.25027 3 12C3 15.7497 3 17.6246 3.95491 18.9389C3.96611 18.9543 3.97738 18.9697 3.98875 18.9849M20.985 14.485C20.9401 16.6536 20.7609 17.9537 20.0451 18.9389C19.7367 19.3634 19.3634 19.7367 18.9389 20.0451C17.6246 21 15.7497 21 12 21C8.25027 21 6.3754 21 5.06107 20.0451C4.652 19.7479 4.29047 19.3904 3.98875 18.9849M9 9C9 10.1046 9.89543 11 11 11C12.1046 11 13 10.1046 13 9C13 7.89543 12.1046 7 11 7C9.89543 7 9 7.89543 9 9Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                />
            </svg>
        </label>
    </div>

    <textarea name="" id="" rows="2" placeholder="Write your post..." class="w-full placeholder:text-black"></textarea>

    <input
            type="submit"
            value="Post now"
            class="py-[9px] px-4 bg-[#8C92A4] text-white text-[15px] leading-6 font-medium rounded-[9px]"
    />
</form>
