import {Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {CultivationPeriodType} from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";
import {ColorUtils} from "../../../utils/color-utils";
import {CultivationPeriodDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";

@Component({
  selector: 'vw-cultivation-period-label',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './cultivation-period-label.component.html',
  styleUrls: ['./cultivation-period-label.component.scss']
})
export class CultivationPeriodLabelComponent implements OnInit {
  @Input() period: CultivationPeriodDto;
  backgroundColor: string;

  ngOnInit() {
    if(this.period) {
      this.backgroundColor = ColorUtils.getBackgroundColorFromType(this.period.type);
    }
  }



}
