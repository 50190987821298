import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SwiperDirective} from '../../../@commons/directives/swiper.directive';

@Component({
  selector: 'vw-map-panel',
  standalone: true,
  imports: [CommonModule, SwiperDirective],
  templateUrl: './map-panel.component.html',
  styleUrls: ['./map-panel.component.scss']
})
export class MapPanelComponent {
  @Input() cultivations: any[] = [];
}
