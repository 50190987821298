import { Component,OnInit, AfterViewInit, ViewChild, ElementRef  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {TodoGroupComponent} from './todo-group/todo-group.component';
import {TaskApi} from "vegiwise-sdk-typescript/dist/sdk/plant/task.api";
import {PagingResponse} from "../../@commons/services/base.service";
import {IInfiniteScrollEvent, InfiniteScrollModule} from "ngx-infinite-scroll";
import {TaskDto} from "vegiwise-sdk-typescript/dist/model/@common/task";
import {TimeLineResponse} from "vegiwise-sdk-typescript/dist/model/task/time-line-res";

@Component({
    selector: 'vw-todo-home',
    templateUrl: './todo-home.component.html',
    styleUrls: ['./todo-home.component.scss'],
    imports: [
        TodoGroupComponent,
        InfiniteScrollModule,
        FormsModule,
        CommonModule
    ],
    standalone: true
})
export class TodoHomeComponent implements OnInit{

    @ViewChild('currentTasksSection') currentTasksSection: ElementRef;
    @ViewChild('targetDiv') targetDiv: ElementRef;
    @ViewChild('targetSection') targetSection: ElementRef;


    currentTasks: TaskDto[] = [];
    previousTasks: TaskDto[] = [];
    futureTasks: TaskDto[] = [];

    constructor(private taskApi: TaskApi) { }

    ngOnInit(): void {
        this.loadTasks();
        setTimeout(() => {
      this.scrollToTarget();
        }, 50);
    }


    loadTasks() {
    this.taskApi.timeline(7).then((response: TimeLineResponse) => {
      console.log(response);
      this.currentTasks = response.currentTasks;
      // Tri des tâches précédentes par date de réalisation (doneDate)
      this.previousTasks = response.previousTasks.results.sort((a, b) => {
        const dateA = new Date(a.doneDate).getTime();
        const dateB = new Date(b.doneDate).getTime();
        return dateA - dateB;
      });
      this.futureTasks = response.futureTasks.results;
    });
  }

    scrollToTarget() {
        if (this.targetSection && this.targetSection.nativeElement) {
        this.targetSection.nativeElement.scrollIntoView();
        }
    }

    loadMore($event: IInfiniteScrollEvent) {
        console.log('loadMore');
    }

    onScroll($event: Event) {
        console.log('scroll');
    }

    onScrollUp($event: IInfiniteScrollEvent) {
        console.log('scrollUp');
    }

    onScrollEnd($event: any) {
        console.log('scrollEnd');
    }
}