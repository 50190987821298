<section class="h-full overflow-y-auto"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="500"
         (scrolled)="loadMore($event)"
         (scroll)="onScroll($event)"
         [scrollWindow]="false">

    <div class="p-3 space-y-[10px]">
    <!--<div class="bg-[#E1E1E1] h-[0.5px] w-full my-[15px]"></div>

        <h3 class="text-[15px] leading-6 font-medium">Media &amp; Resource</h3>-->

        <div class="grid grid-cols-3 gap-2">
            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Tomato</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Tomato</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Tomato</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Egplant</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Garlic</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>

            <div class="bg-[url('./assets/cards/Component4.png')] h-[148px] p-[9px] bg-no-repeat bg-cover bg-center relative rounded-[7px] overflow-hidden text-white">
                <div class="flex items-start">
                    <div>
                        <p class="font-bold text-[13px] leading-4">Carrot</p>
                        <p class="font-light text-[8px] leading-5">Vegetable &amp; Plant</p>
                    </div>
                </div>

                <button class="absolute inset-0 m-auto w-[44px] h-[44px] rounded-full flex justify-center items-center bg-[#0000004D] backdrop-blur-[2.8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6953 12.846C19.357 14.189 17.7581 15.138 14.5604 17.0361C11.4691 18.8709 9.92346 19.7884 8.67787 19.4196C8.16289 19.2671 7.69369 18.9776 7.31529 18.5787C6.40002 17.6139 6.40002 15.7426 6.40002 12C6.40002 8.2574 6.40002 6.3861 7.31529 5.42132C7.69369 5.02245 8.16289 4.73288 8.67787 4.58042C9.92346 4.21165 11.4691 5.12907 14.5604 6.96393C17.7581 8.86197 19.357 9.811 19.6953 11.154C19.8349 11.7084 19.8349 12.2916 19.6953 12.846Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</section>
