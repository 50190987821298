
<div class="border border-[#F2F3F4] rounded-lg py-3 px-2.5 space-y-2.5">
    <div class="flex justify-between items-center gap-x-2.5">
        <div class="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#F5F6F8]"
             [style.background-image]="pictureUrl"
             [style.background-size]="'auto 50px'">
        </div>
        <div class="grow">
            <p class="text-[17px] font-medium">{{currentUser?.firstName || ''}}</p>
            <p class="text-[#8C92A4] uppercase text-[10px] font-medium leading-6">{{'SETTINGS.USER_INFO.REGISTRATION_DATE' | translate: {registrationDate: currentUser?.registrationDate || '04/10/1991'} }}</p>
        </div>
        <vw-dot-button [menuItems]="menuItems"></vw-dot-button>
    </div>
    <div class="h-[22px] w-full rounded-full bg-[#F5F6F8] overflow-hidden relative">
        <div class="absolute mx-auto text-white text-center font-medium text-xs leading-6 w-full -mt-[0.5px]">
            85/100 XP
        </div>
        <div class="h-full w-[85%] bg-brand-500"></div>
    </div>
    <input
        #fileInput
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        (change)="onFileSelected($event)"
        style="display: none" />
</div>
