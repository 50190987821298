import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppDetailsHeaderComponent} from "../../@commons/components/app-details-header/app-details-header.component";
import {
    CultivationHorizontalViewerComponent
} from "../../@commons/components/cultivation-horizontal-viewer/cultivation-horizontal-viewer.component";
import {DefaultLayoutComponent} from "../../@commons/layouts/default-layout/default-layout.component";
import {
    GrowingStepsViewerComponent
} from "../../@commons/components/growing-steps-viewer/growing-steps-viewer.component";
import {ActivatedRoute, Params} from "@angular/router";
import {CultivationApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import {GrowingStepApi} from "vegiwise-sdk-typescript/dist/sdk/plant/growing-step.api";
import {
    CultivationStockLevelComponent
} from "../../@commons/components/@cultivation-specific/cultivation-stock-level/cultivation-stock-level.component";
import {TranslateModule} from "@ngx-translate/core";
import {
    CultivationRankingComponent
} from "../../@commons/components/@cultivation-specific/cultivation-ranking/cultivation-ranking.component";
import {CultivationEvaluationCardComponent} from "./cultivation-evaluation-card/cultivation-evaluation-card.component";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {
    CultivationPeriodsViewerComponent
} from "../../@commons/components/cultivation-period-viewer/cultivation-periods-viewer.component";
import {LabelPipe} from "../../@commons/pipes/LabelPipe";
import {SkeletonDirective} from "../../@commons/directives/skeleton.directive";
import {CultivationPeriodApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation-period.api";
import {CultivationPeriodDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";
import {PagingResponse} from "../../@commons/services/base.service";
import {StorageService} from "../../@commons/services/front/storage.service";
import {CultivationToUserDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-to-user";
import {CultivationToUserApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation-to-user.api";

@Component({
  selector: 'vw-cultivation-details',
  standalone: true,
  providers: [CultivationApi, GrowingStepApi, CultivationPeriodApi, CultivationToUserApi],
    imports: [CommonModule, AppDetailsHeaderComponent, CultivationHorizontalViewerComponent, DefaultLayoutComponent, GrowingStepsViewerComponent, CultivationStockLevelComponent, TranslateModule, CultivationRankingComponent, CultivationEvaluationCardComponent, CultivationPeriodsViewerComponent, LabelPipe, SkeletonDirective],
  templateUrl: './cultivation-details.component.html',
  styleUrls: ['./cultivation-details.component.scss']
})
export class CultivationDetailsComponent {

    cultivation: CultivationDto;
    periods: CultivationPeriodDto[];
    userPreferences: CultivationToUserDto = new CultivationToUserDto();

    constructor(private activatedRoute: ActivatedRoute, private cultivationApi: CultivationApi,
                private periodApi: CultivationPeriodApi,
                private growingStepApi: GrowingStepApi,
                private storageService: StorageService,
                private cultivationToUserApi: CultivationToUserApi) {
        this.activatedRoute.params.subscribe((params: Params) => {
            const uid = params['cultivationUid'];
            this.load(uid);
        });
    }

    load(cultivationUid: string) {
        this.cultivationApi.findByUid(cultivationUid).then((culti: CultivationDto) => {
            this.cultivation = culti;
        })
        this.periodApi.list({
            cultivationUid
        }, 0, 10, this.storageService.getCurrentLanguage()).then((pagingPeriods: PagingResponse<CultivationPeriodDto>) => {
            this.periods = pagingPeriods.results;
        });
        this.cultivationToUserApi.findByCultivationUid(cultivationUid).then((cultivationToUser: CultivationToUserDto) => {
            this.userPreferences = cultivationToUser;
        })
    }


    updated($event: number) {
        const upsertRequest = new CultivationToUserDto();
        upsertRequest.rate = this.userPreferences.rate;
        upsertRequest.userUid = this.userPreferences.userUid;
        upsertRequest.cultivationUid = this.userPreferences.cultivationUid;
        upsertRequest.stockLevel = this.userPreferences.stockLevel;
        this.cultivationToUserApi.upsert([upsertRequest]).then((c) => {
        });
    }
}
