import { Component, OnInit } from '@angular/core';
import {SkeletonDirective} from "../../../directives/skeleton.directive";

@Component({
    selector: 'vw-cultivation-item-action',
    templateUrl: './cultivation-item-action.component.html',
    styleUrls: ['./cultivation-item-action.component.scss'],
    host: {'class': ''},
    imports: [
        SkeletonDirective
    ],
    standalone: true
})
export class CultivationItemActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
