import {CommonModule} from '@angular/common';
import {
    Component,
    OnInit,
    AfterViewInit, OnDestroy, Output, EventEmitter, Input
} from '@angular/core';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {
    circle,
    latLng,
    polygon,
    tileLayer,
    Map,
    ZoomAnimEvent,
    MapOptions,
    Marker,
    Icon,
    LatLng,
    LeafletMouseEventHandlerFn
} from 'leaflet';
import {MapPanelComponent} from './map-panel/map-panel.component';


@Component({
    selector: 'vw-map',
    standalone: true,
    imports: [CommonModule, LeafletModule, MapPanelComponent],
    templateUrl: './map-home.component.html',
    styleUrls: ['./map-home.component.scss']
})
export class MapHomeComponent implements OnInit, OnDestroy {

    isSwiped = false;
    initialX = 0;
    initialY = 0;
    mouseX = 0;
    mouseY = 0;
    defaultSwipeContentHeight = 200;
    middleSwipeContentHeight = 400;

    @Output() mapChange: EventEmitter<Map> = new EventEmitter();
    @Output() zoomChange: EventEmitter<number> = new EventEmitter();


    @Input() options: MapOptions = {
        layers:[
            tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            opacity: 0.7,
            maxZoom: 20,
            detectRetina: true,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })],
        zoom: 18,
        center:latLng(50.661066719542454, 5.5709291556903136)
    };

    neighbours: any[] = [
        {firstName: 'Olivier Blanpain', icon: 'assets/images/veggies/roco_broccoli.svg', latitude: 50.661066719542454, longitude: 5.5709291556903136, cultivations: [{}, {} , {} , {}, {}, {} , {} , {}]},
        {firstName: 'Tom & Lucie', icon: 'assets/images/veggies/hugo_carrot.svg', latitude: 50.66097623579362, longitude: 5.571060723787909, cultivations: [{}, {}]},
        {firstName: 'Mr Declou', icon: 'assets/images/veggies/willy_potato.svg', latitude: 50.661151694968595, longitude: 5.570528244431151, cultivations: [{}, {} , {} , {}]},
        {firstName: 'Arthur', icon: 'assets/images/veggies/phil_zucchini.svg', latitude: 50.66121149250327, longitude: 5.5697015992723005, cultivations: [{}]},
    ]

    selectedNeighbour: any = null;
    zoom: number;
    map: Map;

    ngOnInit() {

    }


    ngOnDestroy() {
        this.map.clearAllEventListeners();
    };

    onMapReady(map: Map) {
        console.log('On Map Ready');
        this.map = map;
        this.mapChange.emit(map);
        this.zoom = map.getZoom();
        this.zoomChange.emit(this.zoom);
        this.loadNeighbours();
        this.map.on('click', this.onMapClicked);
    }

    onMapClicked(event) {
        this.selectedNeighbour = null;
    }

    onMapZoomEnd(e: ZoomAnimEvent) {
        this.zoom = e.target.getZoom();
        this.zoomChange.emit(this.zoom);
    }

    onMarkerClicked(e: any) {
        console.log('On Marker clicked');
        this.selectNeighbourFromMarker(e.target.options);
    }

    selectNeighbourFromMarker(markerOptions: any) {
        console.log(markerOptions);
        const neighbour = this.neighbours.find((neighbour) => {
            return neighbour.firstName === markerOptions.title;
        });

        console.log('Neighbour selected - ' + neighbour.firstName);
        this.selectedNeighbour = neighbour;
    }

    createNeighbourMarker(name: string, icon: string, latitue: number, longitude: number): Marker {
        const marker =  new Marker(new LatLng(latitue, longitude), {
            title: name,
            icon: new Icon({iconUrl: icon, iconSize: [60, 60]},)
        });
        marker.on('click', this.onMarkerClicked.bind(this));
        return marker;
    }

    loadNeighbours() {
        const markers: Marker[] = this.neighbours.map((neighbour) => {
            return this.createNeighbourMarker(neighbour.firstName, neighbour.icon, neighbour.latitude, neighbour.longitude);
        })
        markers.forEach((marker) => {
            this.map.addLayer(marker);
        });
    }



    handleSwipe(isUp: boolean) {
      const swiper = document.getElementById('swiper');
      if (isUp) {
        console.log('height: ', swiper.clientHeight);
        if (swiper.clientHeight === this.defaultSwipeContentHeight) {
          swiper.style.height = `${this.middleSwipeContentHeight}px`;
        } else if (swiper.clientHeight === this.middleSwipeContentHeight) {
          swiper.style.height = '85vh';
        }
      } else {
        if (swiper.clientHeight > this.middleSwipeContentHeight) {
          swiper.style.height = `${this.middleSwipeContentHeight}px`;
        } else if (swiper.clientHeight === this.middleSwipeContentHeight) {
          swiper.style.height = `${this.defaultSwipeContentHeight}px`;
        }
      }
    }

    getXY(e: any, a: string) {
      const touchArea = document.getElementById('touchArea');
      const rectLeft = touchArea.getBoundingClientRect().left;
      const rectTop = touchArea.getBoundingClientRect().top;

      this.mouseX =
        (!this.isTouchDevice() ? e.pageX : a !== 'end' ? e.touches[0].pageX : e.changedTouches[0].pageX) - rectLeft;
      this.mouseY =
        (!this.isTouchDevice() ? e.pageY : a !== 'end' ? e.touches[0].pageY : e.changedTouches[0].pageY) - rectTop;
    }

    isTouchDevice() {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    onTouchStart(event: any) {
      this.isSwiped = true;
      this.getXY(event, '');
      this.initialX = this.mouseX;
      this.initialY = this.mouseY;
    }

    onTouchEnd(event: any) {
      if (!this.isTouchDevice()) {
        event.preventDefault();
      }
      if (this.isSwiped) {
        this.getXY(event, 'end');
        const diffX = this.mouseX - this.initialX;
        const diffY = this.mouseY - this.initialY;

        if (Math.abs(diffY) > Math.abs(diffX)) {
          console.log(diffY > 0 ? 'Down' : 'Up');
          const isUp = diffY > 0 ? false : true;
          this.handleSwipe(isUp);
        }
      }
    }
}
