import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LabelPipe } from "../../../@commons/pipes/LabelPipe";
import { TranslateModule } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { HeaderFooterService } from "../../../@commons/services/display/header-footer.service";
import { PagingResponse } from "../../../@commons/services/base.service";
import { CultivationSettingsItemComponent } from "./cultivation-settings-item/cultivation-settings-item.component";
import { CultivationItemComponent } from "../../../home/home-page/cultivation-item/cultivation-item.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { ListCultivationRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";
import { AuthApi } from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";

@Component({
  selector: "vw-seed-stock",
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    TranslateModule,
    CultivationItemComponent,
    CultivationSettingsItemComponent,
    InfiniteScrollModule,
  ],
  providers: [CultivationApi, AuthApi],
  templateUrl: "./seed-stock.component.html",
  styleUrls: ["./seed-stock.component.scss"],
})
export class SeedStockComponent {
  cultivations: CultivationDto[] = [];
  cultivationPairs: Array<CultivationDto[]> = [];
  page: number = 0;
  pageSize: number = 21;

  constructor(
    private router: Router,
    private cultivationApi: CultivationApi,
    private headerFooterService: HeaderFooterService,
    private authApi: AuthApi
  ) {}

  ngOnInit() {
    this.loadCultivations();
  }

  divideArray<T>(array: T[]): [T[], T[]] {
    const middle = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middle);
    const secondHalf = array.slice(middle);
    return [firstHalf, secondHalf];
  }

  goToCultivationDetails(cultivation: CultivationDto) {
    this.router.navigate([`cultivations/${cultivation.uid}/details`]);
  }

  loadMore($event) {
    this.loadCultivations();
  }

  loadCultivations() {
    const filters: ListCultivationRequest = new ListCultivationRequest();
    filters.usedByUserUid = this.authApi.getSub();
    this.cultivationApi
      .list(filters, this.page, this.pageSize, "fr")
      .then((cultivations: PagingResponse<CultivationDto>) => {
        console.log("..loaded " + cultivations.results.length);
        this.cultivations = cultivations.results;
        this.cultivationPairs.push(
          ...this.divideArray<CultivationDto>(this.cultivations)
        );
      });
  }

  onScroll($event) {
    this.headerFooterService.onScroll("home", $event.target.scrollTop);
  }
}
