import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HTTPService } from "./front/HTTP.service";
import { QueryParamService } from "./front/query-param.service";
import { FormGroup, ValidationErrors } from "@angular/forms";

export class Paging {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}

export class PagingLinks {
    first: string;
    prev?: string;
    self: string;
    next?: string;
    last: string;
}

// tslint:disable:variable-name
export class PagingResponse<T> {
    results: T[];
    page: Paging;
    _links: PagingLinks;
}

export class BaseService<Entity, Filter, UpsertResponse> {
    path: string;

    constructor(
        path: string,
        protected http: HTTPService,
        protected queryParamService: QueryParamService
    ) {
        this.path = environment.API_BASE + "/" + path;
    }

    static backErrorToErrors(error: any): any {
        const errors = {};
        if (error && error.message && Array.isArray(error.message)) {
            error.message.forEach((element) => {
                if (element.property) {
                    const constraints = [];
                    if (element.constraints) {
                        Object.keys(element.constraints).forEach((item) =>
                            constraints.push(item)
                        );
                    }
                    errors[element.property] = constraints;
                }
            });
        }
        return errors;
    }

    static validationErrorsToErrors(formGroup: FormGroup): any {
        const errors = {};
        Object.keys(formGroup.controls).forEach((key) => {
            const controlErrors: ValidationErrors = formGroup.get(key).errors;
            if (controlErrors != null) {
                const constraints = [];
                Object.keys(controlErrors).forEach((keyError) => {
                    if (controlErrors[keyError]) {
                        constraints.push(this.mapKeyErrorToError(keyError));
                    }
                });
                if (constraints.length) {
                    errors[key] = constraints;
                }
            }
        });
        return errors;
    }

    static validationErrorsToErrorList(formGroup: FormGroup, labelPrefix: string = null): any[] {
        const errors: {} = this.validationErrorsToErrors(formGroup);
        const errorList = [];
        Object.keys(errors).forEach((key) => {
            errors[key].forEach((error) => {
                errorList.push({ field: key, message: (labelPrefix ? labelPrefix + '.' : '') + error });
            });
        });
        return errorList;
    }

    private static mapKeyErrorToError(keyError): string {
        switch (keyError) {
            case "requiredTrue":
                return "ISNOTEMPTY";
            case "required":
                return "ISNOTEMPTY";
            case "dateIsNotSameOrBefore":
                return "MAXDATE";
            case "dateIsNotBefore":
                return "MAXDATE";
            case "dateIsNotSameOrAfter":
                return "MINDATE";
            case "dateIsNotAfter":
                return "MINDATE";
            case "dateInvalid":
                return "ISDATE";
            case "min":
                return "MIN";
            case "max":
                return "MAX";
            case "email":
                return "ISEMAIL";
            case "minLength":
                return "MINLENGTH";
            case "maxLength":
                return "MAXLENGTH";
            case "pattern":
                return "MATCHES";
            default:
                return keyError;
        }
    }

    upsert(entity: any): Observable<UpsertResponse> {
        return this.http.post<any>(this.path, entity);
    }

    findById(id: string): Observable<any> {
        return this.http.get<any>(this.path + "/" + id);
    }

    list(
        filters?: Filter,
        page: number = 0,
        pageSize: number = 10
    ): Observable<any> {
        return this.http.post<PagingResponse<Entity>>(
            this.queryParamService.addExtraQueryParams(
                new Map([
                    ["page", page ? page.toString() : null],
                    ["pageSize", pageSize ? pageSize.toString() : null],
                ]),
                this.path + "/list"
            ),
            filters
        );
    }
}
