<vw-default-layout [bodyTemplate]="bodyT" [headerTemplate]="header">
  <ng-template #header>
    <vw-app-details-header [title]="'Etapes'"></vw-app-details-header>
  </ng-template>
  <ng-template #bodyT>
    <section class="p-3" *ngIf="cultivation">
      <!-- <vw-cultivation-horizontal-viewer
        [cultivation]="cultivation"
        (click)="goToCultivationDetails(cultivation)"
      ></vw-cultivation-horizontal-viewer> -->
      <vw-growing-steps-viewer
        [growingSteps]="cultivation?.growingSteps"
        [plant]="plant"
      ></vw-growing-steps-viewer>
    </section>
  </ng-template>
</vw-default-layout>
