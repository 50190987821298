<div
  class="absolute bottom-0 w-full transition-all ease-in-out bg-white rounded-t-lg duration-0"
  vwSwiper
>
  <div class="w-full my-2 space-y-2.5">
    <div>
      <div class="h-[3px] bg-[#7C7F8C] rounded-full mx-auto w-6 px-5"></div>
      <p class="px-5 text-lg font-medium">Jonathan’s Garden:</p>
    </div>
    <div class="flex px-5 overflow-x-auto snap-x gap-x-2" *ngIf="cultivations">
      <div
        *ngFor="let cultivation of cultivations"
        class="bg-[url('./assets/cards/Component4.png')] h-[216px] min-w-[160px] w-[160px] px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative rounded-lg overflow-hidden text-white snap-center"
      >
        <div class="flex items-start">
          <div>
            <p class="font-bold text-[20px] leading-6">Carrot</p>
            <p class="text-xs font-light leading-6">Vegetable &amp; Plant</p>
          </div>
        </div>

        <div class="absolute bottom-3">
          <div
            class="flex items-center gap-x-1.5 text-white text-[15px] leading-[21px]"
          >
            <p>Top Gardener !</p>
            <div class="flex items-center gap-x-0.5">
              <p class="text-sm font-medium left-6">5.0</p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00778 2.00919L9.03433 4.07926C9.17432 4.36742 9.54761 4.64382 9.86257 4.69675L11.7232 5.00844C12.9131 5.20839 13.193 6.07876 12.3356 6.93737L10.8891 8.39583C10.6442 8.64283 10.51 9.11918 10.5858 9.46027L10.9999 11.2657C11.3266 12.6948 10.5742 13.2476 9.32013 12.5007L7.57616 11.4598C7.26119 11.2716 6.74208 11.2716 6.42129 11.4598L4.67731 12.5007C3.42912 13.2476 2.67087 12.6889 2.9975 11.2657L3.41162 9.46027C3.48744 9.11918 3.35329 8.64283 3.10832 8.39583L1.66181 6.93737C0.81024 6.07876 1.08438 5.20839 2.27424 5.00844L4.13487 4.69675C4.444 4.64382 4.8173 4.36742 4.95728 4.07926L5.98383 2.00919C6.54377 0.885936 7.45367 0.885936 8.00778 2.00919Z"
                  fill="white"
                ></path>
              </svg>
            </div>
          </div>

          <p class="text-white text-[13px] leading-[21px]">145 reviews</p>
        </div>
      </div>
    </div>
  </div>
</div>
