<section class="relative">
  <div class="flex items-center px-5 py-4">
    <button (click)="onPreviousClicked()">
      <vw-arrow-left></vw-arrow-left>
    </button>

    <p class="text-center font-bold w-full">
      {{ title | translate: titleData }}
    </p>
  </div>
</section>
