import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-arrow-left',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './arrow-left.component.html',
  styleUrls: ['./arrow-left.component.scss']
})
export class ArrowLeftComponent {

}
