import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Modal } from "../../services/modal/modal";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { UserSuggestionApi } from "vegiwise-sdk-typescript/dist/sdk/plant/user-suggestion.api";
import { CrossCancelIconComponent } from "../../icons/cross-cancel-icon/cross-cancel-icon.component";

@Component({
  selector: "vw-upgrade-premium-modal",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    CrossCancelIconComponent,
  ],
  providers: [UserSuggestionApi],
  templateUrl: "./upgrade-premium-modal.component.html",
  styleUrls: ["./upgrade-premium-modal.component.scss"],
})
export class UpgradePremiumModalComponent extends Modal {
  remarks: string = "";

  constructor(private userSugestionApi: UserSuggestionApi) {
    super();
  }
  onInjectInputs(inputs: any): void {}

  onCloseClick($event: MouseEvent) {
    this.close("Test");
  }

  onSubmit($event: MouseEvent) {
    console.log(this.remarks);
    this.userSugestionApi
      .upsert({
        suggestions: this.remarks,
      })
      .then(() => {
        this.close(this.remarks);
      });
  }
}
