import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'vw-circle-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './circle-button.component.html',
  styleUrls: ['./circle-button.component.scss']
})
export class CircleButtonComponent {
  @Input() public selected: boolean;
  @Input() public label: string;
}
