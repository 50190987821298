<div class="relative py-[6px]">
    <input
           [class]="'bg-white px-5 py-[12px] pl-[60px]  border-[0.5px] border-[#B4B9CC] rounded-[8px] w-full ' + inputClasses"
           [readOnly]="!editMode || disabled"
           [disabled]="disabled" [class.form-control-plaintext]="!editMode || disabled" [required]="required"
           [id]="id" [name]="name" (focus)="onFocus();" (focusout)="onFocusOut();"
           [type]="((!editMode || disabled || !focus) && type === 'number') ? 'text' : type" lang="fr-FR"
           (keypress)="onKeyPress($event);"
           [(ngModel)]="((!editMode || disabled || !focus) && type === 'number') ? currencyFormat(value) : value"
           [placeholder]="placeholder | translate" (ngModelChange)="sendChange()">
    <ng-template #defaultIcon>
        <vw-writing-icon></vw-writing-icon>
    </ng-template>

    <div class="absolute top-1/2 -translate-y-1/2 left-5" *ngIf="showIcon">
        <ng-container [ngTemplateOutlet]="iconTemplate ? iconTemplate : defaultIcon">
        </ng-container>
    </div>

</div>