import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { TextInputComponent } from "../../@commons/components/@generic/inputs/text-input/text-input.component";
import { MailIconComponent } from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import { KeyIconComponent } from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { PersonStandingIconComponent } from "../../@commons/components/@generic/icons/person-standing-icon/person-standing-icon.component";
import { PlusIconComponent } from "../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import { ErrorMessageComponent } from "../../@commons/components/@forms/error-message/error-message.component";
import { LinkComponent } from "../../@commons/components/@generic/link/link.component";
import { Router, RouterModule } from "@angular/router";
import { UserApi } from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import { BaseService } from "src/app/@commons/services/base.service";

@Component({
  selector: "vw-login",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TextInputComponent,
    MailIconComponent,
    KeyIconComponent,
    TranslateModule,
    PersonStandingIconComponent,
    PlusIconComponent,
    ErrorMessageComponent,
    LinkComponent,
  ],
  templateUrl: "./forgot-password.component.html",
  providers: [UserApi],
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  errors: any[] = [];
  public form: FormGroup;

  constructor(
    private userApi: UserApi,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      emailCtrl: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  askForNewPassword() {
    if (this.form.valid) {
      this.userApi
        .resetPassword({ username: this.form.value.emailCtrl })
        .then(() => {
          this.router.navigate(["/auth/confirm-password"]);
        });
    } else {
      // TODO better handle specific errors
      this.errors = [{ label: "LOGIN.FORM.ERRORS.REQUIRED_FIELD" }];
    }
  }
}
