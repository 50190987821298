<div class="p-[10px]  border-[1px] border-[#DDDDDD] bg-brand-600 rounded-lg">
    <div class="flex items-center justify-between text-[15px] leading-[21px] font-medium text-white">
        <div>
            <div class="flex items-center justify-between leading-[20px]">
                <p *ngIf="selectedItems.length <= 1" class="font-semibold text-white" >{{ 'CULTIVATIONS.SELECT.ADD.TITLE' | translate: {totalSelected: selectedItems.length} }}</p>
                <p *ngIf="selectedItems.length > 1" class="font-semibold text-white" >{{ 'CULTIVATIONS.SELECT.ADD.TITLE_PLURAL' | translate: {totalSelected: selectedItems.length} }}</p>
            </div>
            <div class="w-full pr-2">
                <p class="my-2 text-white"> {{ 'CULTIVATIONS.SELECT.ADD.DESCRIPTION' | translate }}</p>
            </div>
        </div>
        <button>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 7L14.5 12L9.5 17" stroke="white" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </svg>
        </button>
    </div>
</div>