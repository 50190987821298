<vw-default-layout
  [bodyTemplate]="bodyT"
  [tabsTemplate]="tabs"
  [headerAdditionalComponent]="headerComponents"
>
  <ng-template #bodyT>
    <router-outlet></router-outlet>
  </ng-template>

  <ng-template #tabs>
    <vw-app-header-tabs [tabs]="tabItems"></vw-app-header-tabs>
  </ng-template>
  <ng-template #headerComponents>
    <vw-upgrade-pro-btn
      [style]="'COLOR'"
      (click)="onClickUpgradePro($event)"
    ></vw-upgrade-pro-btn>
  </ng-template>
</vw-default-layout>
