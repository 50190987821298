import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { TextInputComponent } from "../../@commons/components/@generic/inputs/text-input/text-input.component";
import { MailIconComponent } from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import { KeyIconComponent } from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { PersonStandingIconComponent } from "../../@commons/components/@generic/icons/person-standing-icon/person-standing-icon.component";
import { PlusIconComponent } from "../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import { ErrorMessageComponent } from "../../@commons/components/@forms/error-message/error-message.component";
import { LinkComponent } from "../../@commons/components/@generic/link/link.component";
import { Router, RouterModule } from "@angular/router";
import { OfficeIconComponent } from "../../@commons/components/@generic/icons/office-icon/office-icon.component";
import { UserApi } from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import { RegisterAdminRequest } from "vegiwise-sdk-typescript/dist/model/auth/register-admin.request";
import { Language } from "vegiwise-sdk-typescript/dist/enums/language.enum";
import { RegisterAdminResponse } from "vegiwise-sdk-typescript/dist/model/auth/register-admin.response";
import { StorageService } from "../../@commons/services/front/storage.service";
import { BaseService } from "src/app/@commons/services/base.service";
import {RegisterUserRequest} from "vegiwise-sdk-typescript/dist/model/auth/register-user.request";

@Component({
  selector: "vw-login",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TextInputComponent,
    MailIconComponent,
    KeyIconComponent,
    TranslateModule,
    PersonStandingIconComponent,
    PlusIconComponent,
    ErrorMessageComponent,
    LinkComponent,
    OfficeIconComponent,
  ],
  templateUrl: "./register.component.html",
  providers: [UserApi],
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  errors: any[] = []; //TODO
  public form: FormGroup;

  constructor(
    private storageService: StorageService,
    private userApi: UserApi,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      nameCtrl: new FormControl("", [Validators.required]),
      firstNameCtrl: new FormControl("", [Validators.required]),
      emailCtrl: new FormControl("", [Validators.required, Validators.email]),
      passwordCtrl: new FormControl("", [Validators.required,
        Validators.pattern("^(?=.*[A-Z].*)(?=.*[\\^\\$\\*\\.\\[\\]\\{\\}\\(\\)\\?\\-\\\"\\!\\@\\#\\%\\&\\/\\\\,\\>\\<\\'\\:\\;\\|\\_\\~\\`])(?=.*[0-9].*)(?=.*[a-z].*).{8,99}$")]),
    });
  }
  

  getFormData(): RegisterUserRequest {
    return {
      lastName: this.form.controls.nameCtrl.value,
      firstName: this.form.controls.firstNameCtrl.value,
      email: this.form.controls.emailCtrl.value,
      password: this.form.controls.passwordCtrl.value,
      //company: this.form.controls.companyNameCtrl.value,
      preferredLanguage: Language.FR,
      phoneNumber: "",
      consentDate: new Date().getDate(),
      gcuConsent: true,
      privacyConsent: true,
      commercialConsent: true,
    };
  }

  createAccount() {
    if (this.form.valid) {
      const data: RegisterUserRequest = this.getFormData();
      this.userApi.register(data).then((res: RegisterAdminResponse) => {
        this.storageService.setItem("REGISTER_EMAIL", data.email);
        this.router.navigate(["/auth/confirm"]);
      });
    } else {
      console.log("form invalid", this.form.errors);
      this.errors = BaseService.validationErrorsToErrorList(this.form, 'REGISTER.FORM.ERRORS');
    }
  }
}
