import { Routes, ExtraOptions } from "@angular/router";
import { NavigationGuard } from "./@commons/guards/navigation.guard";
import { HomePageComponent } from "./home/home-page/home-page.component";
import { HomeComponent } from "./home/home.component";
import { TaskComponent } from "./task/task.component";
import { SocialComponent } from "./social/social.component";
import { SettingsComponent } from "./settings/settings.component";
import { CalendarHomeComponent } from "./task/calendar-home/calendar-home.component";
import { TodoHomeComponent } from "./task/todo-home/todo-home.component";
import { SocialHomeComponent } from "./social/social-home/social-home.component";
import { ContactsListComponent } from "./settings/@components/contacts-list/contacts-list.component";
import { SeedStockComponent } from "./settings/@components/seed-stock/seed-stock.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { MapHomeComponent } from "./map/map-home/map-home.component";
import { MapComponent } from "./map/map.component";
import { MediaHomeComponent } from "./social/media-home/media-home.component";
import { CultivationsComponent } from "./cultivations/cultivations.component";
import { MySeedsComponent } from "./cultivations/my-seeds/my-seeds.component";
import { MarketplaceHomeComponent } from "./cultivations/marketplace-home/marketplace-home.component";
import { PlantDetailsComponent } from "./cultivations/plant-details/plant-details.component";
import { CultivationPlantsComponent } from "./cultivations/cultivation-plants/cultivation-plants.component";
import { CultivationDetailsComponent } from "./cultivations/cultivation-details/cultivation-details.component";
import { LoginComponent } from "./auth/login/login.component";
import { AuthComponent } from "./auth/auth.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ConfirmComponent } from "./auth/confirm/confirm.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { SelectCultivationComponent } from "./cultivations/select-cultivation/select-cultivation.component";
import { UserDetailsComponent } from "./user/user-details/user-details.component";
import { UserComponent } from "./user/user.component";

export const APP_ROUTES: Routes = [
  {
    path: "onboarding",

    component: OnboardingComponent,
    //loadChildren: () => import('./home/home.routes').then(home => home.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "confirm", component: ConfirmComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset-password", component: ResetPasswordComponent },
    ],
  },
  {
    path: "home",

    component: HomeComponent,
    children: [
      { path: "", component: HomePageComponent, pathMatch: "full" },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "full",
      },
    ],
    //loadChildren: () => import('./home/home.routes').then(home => home.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "tasks",
    component: TaskComponent,
    children: [
      { path: "calendar", component: CalendarHomeComponent },
      { path: "todo", component: TodoHomeComponent },
      { path: "", redirectTo: "calendar", pathMatch: "full" },
      { path: "**", redirectTo: "calendar", pathMatch: "full" },
    ],
    //loadChildren: () => import('./task/task.routes').then(task => task.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "cultivations",
    component: CultivationsComponent,
    children: [
      { path: "mine", component: MySeedsComponent },
      { path: "marketplace", component: MarketplaceHomeComponent },
      { path: "", redirectTo: "mine", pathMatch: "full" },
    ],
    //loadChildren: () => import('./marketplace/marketplace.routes').then(marketplace => marketplace.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "cultivations/select",
    component: SelectCultivationComponent,
  },
  {
    path: "cultivations/:cultivationUid/plants",
    component: CultivationPlantsComponent,
  },
  {
    path: "cultivations/:cultivationUid/plant/:plantUid",
    component: PlantDetailsComponent,
  },
  {
    path: "cultivations/:cultivationUid/details",
    component: CultivationDetailsComponent,
  },
  {
    path: "social",
    component: SocialComponent,
    children: [
      { path: "wall", component: SocialHomeComponent },
      { path: "media", component: MediaHomeComponent },
      { path: "", redirectTo: "media", pathMatch: "full" },
    ],
    //loadChildren: () => import('./social/social.routes').then(social => social.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "map",
    component: MapComponent,
    children: [
      { path: "home", component: MapHomeComponent },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ],
    //loadChildren: () => import('./marketplace/marketplace.routes').then(marketplace => marketplace.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
    children: [
      { path: "contacts", component: ContactsListComponent },
      { path: "stock", component: SeedStockComponent },
      { path: "", redirectTo: "stock", pathMatch: "full" },
    ],
    //loadChildren: () => import('./settings/settings.routes').then(settings => settings.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "user",
    component: UserComponent,
    children: [
      { path: "settings", component: UserDetailsComponent },
      { path: "", redirectTo: "settings", pathMatch: "full" },
    ],
    //loadChildren: () => import('./settings/settings.routes').then(settings => settings.routes),
    canActivate: [NavigationGuard],
  },
  //{ path: 'not-found', component: NotFoundComponent },
  { path: "**", redirectTo: "/home", pathMatch: "full" },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: "always",
  enableTracing: true,
  errorHandler: (error) => (e) => {
    //console.log(e);
    console.log("error routing ...");
  },
};
