import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    template: ''
})
export abstract class BaseEditableComponent {
    private pEditMode = false;
    @Input()
    public set editMode(mode: boolean) {
        this.pEditMode = mode;
        this.editModeChange.emit(mode);
    }

    public get editMode(): boolean {
        return this.pEditMode;
    }

    @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter();
}