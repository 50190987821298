<div>
  <div class="h-[16px] w-full"></div>
  <div class="px-[10px] py-2 rounded-[6px] bg-[#BE4A3A]">
    <div class="flex items-center gap-x-[8px]">
      <vw-exclamation-point-icon></vw-exclamation-point-icon>
      <p class="text-white font-medium text-[12px] leading-[13px]">
        {{ errorMsg | translate : { field: (fieldMsg | translate) } }}
      </p>
    </div>
  </div>
</div>
