import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ArticleComponent} from './article/article.component';
import {NgForOf} from '@angular/common';
import {ArticleCreatorComponent} from './article-creator/article-creator.component';
import {HeaderFooterService} from "../../@commons/services/display/header-footer.service";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {PagingResponse} from "../../@commons/services/base.service";
import {SocialApi} from "vegiwise-sdk-typescript/dist/sdk/social/social.api";
import {ListCultivationRequest} from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";
import {ArticleDto} from "vegiwise-sdk-typescript/dist/model/@common/article";

@Component({
    selector: 'vw-social-home',
    templateUrl: './social-home.component.html',
    styleUrls: ['./social-home.component.scss'],
    standalone: true,
    imports: [FormsModule, ArticleComponent, NgForOf, ArticleCreatorComponent, InfiniteScrollModule],
    providers: [SocialApi]
})
export class SocialHomeComponent implements OnInit {
    articles: Array<any> = [{}, {}, {}]
    page: number = 0;
    pageSize: number = 6;

    constructor(private headerFooterService: HeaderFooterService, private articleApi: SocialApi) {
    }

    ngOnInit(): void {
        this.loadArticles();
    }


    loadMore($event) {
        this.loadArticles();
    }

    loadArticles() {
        const filters: ListCultivationRequest = new ListCultivationRequest();
        this.articleApi.listArticles(filters, 'fr', this.page, this.pageSize).then((articles: PagingResponse<ArticleDto>) => {
            this.articles.push(articles.results);
        });
    }


    onScroll($event) {
        this.headerFooterService.onScroll('social', $event.target.scrollTop);
    }

}
