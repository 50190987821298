import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LightButtonComponent} from '../@generic/buttons/light-button/light-button.component';
import {ProgressBarComponent} from '../@generic/progress-bar/progress-bar.component';
import {TranslateModule} from "@ngx-translate/core";
import {LabelPipe} from "../../pipes/LabelPipe";
import {GrowingStepDto} from "vegiwise-sdk-typescript/dist/model/@common/growing-step";
import {DotButtonComponent} from "../@generic/buttons/dot-button/dot-button.component";
import {DotMenuItem} from "../../classes/dot-menu-item";
import {PlantToGrowingStepDto} from "vegiwise-sdk-typescript/dist/model/@common/plant-to-growing-step";
import * as moment from 'moment';

@Component({
    selector: 'vw-growing-step-viewer',
    standalone: true,
    imports: [CommonModule, LightButtonComponent, ProgressBarComponent, TranslateModule, LabelPipe, DotButtonComponent],
    templateUrl: './growing-step-viewer.component.html',
    styleUrls: ['./growing-step-viewer.component.scss']
})
export class GrowingStepViewerComponent implements OnInit, OnChanges {


    @Input() growingStep: GrowingStepDto;
    @Input() growingSteps: GrowingStepDto[];
    @Input() plantToGrowingStep!: PlantToGrowingStepDto;
    @Input() showPreviousNext: boolean = true;
    selectedStepIndex: number;
    progressionPercentage: number;
    @Output() previous: EventEmitter<any> = new EventEmitter<any>();
    @Output() next: EventEmitter<any> = new EventEmitter<any>();
    @Output() delete: EventEmitter<any> = new EventEmitter<any>();

    dotMenuItems: DotMenuItem[] = [
        {path: '', label: 'GROWING_STEP.VIEWER.DOT_MENU.DELETE', style: '', onClick: () => {this.delete.emit(this.growingStep)}}
    ]

    ngOnInit() {
        this.calculateProgression();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes && (changes['growingSteps'] || changes['growingStep'])) {
            this.calculateProgression();
        }
    }

    calculateProgression() {
        if(this.growingSteps) {
            let totalDaysForCultivation = 0;
            let actualDaysDuration = 0;
            let actualStepPassed = false;
            this.growingSteps.forEach((gs) => {
                totalDaysForCultivation += gs.durationDays;
                if (!actualStepPassed) {
                    if (gs.uid === this.growingStep.uid) {
                        if (this.plantToGrowingStep) {
                            actualDaysDuration += Math.abs(moment(this.plantToGrowingStep.startDate).subtract(1, 'day').diff(new Date(), 'day'))
                        }
                        actualStepPassed = true;
                    } else {
                        actualDaysDuration += gs.durationDays;
                    }
                }
            });
            this.progressionPercentage = Math.floor(actualDaysDuration / totalDaysForCultivation * 100);
        }
    }

    previousStep() {
        this.previous.emit();
    }

    nextStep() {
        this.next.emit();
    }

}
