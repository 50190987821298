<svg class="h-[22px] w-[22px] min-w-[22px]" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
            d="M13.75 4.58337C13.75 6.10216 12.1383 7.79171 11 7.79171C9.86169 7.79171 8.25 6.10216 8.25 4.58337C8.25 3.06459 9.48122 1.83337 11 1.83337C12.5188 1.83337 13.75 3.06459 13.75 4.58337Z"
            stroke="black"
            stroke-width="1.5"
    />
    <path
            d="M14.7047 8.25C16.1008 9.47403 17.0054 11.5471 16.193 13.4797C16.018 13.8961 15.6164 14.1661 15.1721 14.1661C14.7203 14.1661 13.9782 14.0213 13.834 14.6093L12.8282 18.7113C12.6185 19.5666 11.8647 20.1667 11 20.1667C10.1353 20.1667 9.38151 19.5666 9.17179 18.7113L8.16597 14.6093C8.02178 14.0213 7.2797 14.1661 6.8279 14.1661C6.38358 14.1661 5.982 13.8961 5.80698 13.4797C4.99461 11.5471 5.89928 9.47403 7.29535 8.25"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
    />
</svg>