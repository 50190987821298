import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabMenuItem } from "../../classes/tab-menu-item";
import { TranslateModule } from "@ngx-translate/core";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
  UrlSegment,
} from "@angular/router";

@Component({
  selector: "vw-app-header-tabs",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    RouterLinkActive,
    RouterLink,
  ],
  templateUrl: "./app-header-tabs.component.html",
  styleUrls: ["./app-header-tabs.component.scss"],
})
export class AppHeaderTabsComponent {
  @Input() tabs: TabMenuItem[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
}
