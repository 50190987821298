import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ColorUtils} from "../../../utils/color-utils";
import {CultivationPeriodDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";

@Component({
  selector: 'vw-cultivation-period-month',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './cultivation-period-month.component.html',
  styleUrls: ['./cultivation-period-month.component.scss']
})
export class CultivationPeriodMonthComponent implements OnInit, OnChanges {
  @Input() month: string;
  @Input() periods: CultivationPeriodDto[]
  @Input() backgroundColors: string[];
  backgroundColorStyle: string;


  ngOnInit() {
    this.initColors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes) {
      this.initColors();
    }
  }

  initColors() {
    if(this.periods.length > 1) {
      this.backgroundColors = []
      this.periods.forEach((p: CultivationPeriodDto) => {
        this.backgroundColors.push(ColorUtils.getBackgroundColorFromType(p.type));
      })
      this.backgroundColorStyle = this.getBackgroundColorGradient();
    } else {
      if(this.periods && this.periods[0]) {
        this.backgroundColorStyle = ColorUtils.getBackgroundColorFromType(this.periods[0].type);
      }
    }
  }

  getBackgroundColorGradient(): string {
    //return `linear-gradient(to bottom, ${this.backgroundColors[0]}, ${this.backgroundColors[1]})`;
    const percentage: number = Math.floor(100/this.backgroundColors.length);
    return `conic-gradient(${this.backgroundColors.join(' '+ percentage + '%, ')} ${percentage}%)`;
  }


}
