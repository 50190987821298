import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Modal} from "../../services/modal/modal";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {UserSuggestionApi} from "vegiwise-sdk-typescript/dist/sdk/plant/user-suggestion.api";
import {CrossCancelIconComponent} from "../../icons/cross-cancel-icon/cross-cancel-icon.component";
import {QuestionAskerComponent} from "../../components/question/question-asker/question-asker.component";
import {QuestionFooterComponent} from "../../components/question/question-footer/question-footer.component";
import {
  QuestionProgressionComponent
} from "../../components/question/question-progression/question-progression.component";
import {QuestionDto} from "vegiwise-sdk-typescript/dist/model/@common/question";
import {AnswerDto} from "vegiwise-sdk-typescript/dist/model/@common/answer";

@Component({
  selector: 'vw-upgrade-premium-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, CrossCancelIconComponent, QuestionAskerComponent, QuestionFooterComponent, QuestionProgressionComponent],
  providers: [UserSuggestionApi],
  templateUrl: './ask-questions-modal.component.html',
  styleUrls: ['./ask-questions-modal.component.scss']
})
export class AskQuestionsModalComponent extends Modal {

  NEXT_QUESTION_AUTO_TIMEOUT_DELAY: number = 1500;
  questions: QuestionDto[] = [];
  currentQuestion: QuestionDto;
  currentQuestionIndex: number = 0;


  constructor() {
    super();
  }
  onInjectInputs(questions: QuestionDto[]): void {
    this.questions = questions;
    this.currentQuestionIndex = 0;
    this.currentQuestion = this.questions[this.currentQuestionIndex];
    console.log(this.questions);
    console.log('ici');
  }


  onValidate(event) {
    console.log('CALLING BACKEND TO VALIDATE ANSWER');
    this.next();
  }

  onSkip(event) {
    this.next();
  }

  skipAll() {
    this.close(this.questions);
  }

  onCloseClick($event: MouseEvent) {
    this.close(this.questions);
  }

  onSubmit($event: MouseEvent) {
      this.close(this.questions)
  }

  previous() {
    if(this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
    } else {
      this.close(this.questions);
    }  }

  next() {
    if(this.currentQuestionIndex < this.questions.length -1) {
      this.currentQuestionIndex++;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
    } else {
      this.close(this.questions);
    }
  }

  onResponseSelected(answer: AnswerDto) {
    this.currentQuestion.selectedAnswer = answer;
    setTimeout(() => {
      this.next();
    }, this.NEXT_QUESTION_AUTO_TIMEOUT_DELAY);
  }
}
