import {AfterViewInit, Component, ElementRef, OnInit, Optional, VERSION} from '@angular/core';

import {CommonModule, DOCUMENT, Location} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MockService } from 'vegiwise-sdk-typescript/dist/mock/mock.service';
import {Router, RouterOutlet} from '@angular/router';
import { SdkConfigService } from 'vegiwise-sdk-typescript/dist/config/sdk-config.service';
import { WebViewBridgeService } from './@commons/services/webview/webview-bridge.service';
import { environment } from '../environments/environment';
import {ModalContainerComponent} from "./@commons/services/modal/modal-container/modal-container.component";
import {MockConfig} from "vegiwise-sdk-typescript/dist/config/mock-config";

import * as Hammer from 'hammerjs';
import { HammerModule} from '@angular/platform-browser';
import {FlutterStorageHelper} from "vegiwise-sdk-typescript/dist/sdk/@common/helpers/flutter-storage.helper";
import {StorageService} from "./@commons/services/front/storage.service";
import {StorageHelper} from "vegiwise-sdk-typescript/dist/sdk/@common/helpers/storage.helper";
import {AppLoaderComponent} from "./@commons/components/app-loader/app-loader.component";

@Component({
	selector: 'vw-app',
	templateUrl: './app.component.html',
	standalone: true,
    imports: [CommonModule, RouterOutlet, HttpClientModule, ModalContainerComponent, HammerModule, AppLoaderComponent],
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit{
	constructor(private location: Location,
				private router: Router,
				private el: ElementRef) {
	}

	public open(modal: any): void {}

	ngOnInit(): void {
		const mockConfig : MockConfig = {
			dms: true,
			tasks: false,
			plants: false,
			cultivations: false,
			'cultivation-periods': false,
			'botanic-families': true,
			'growing-steps': false,
			'plants-to-growing-steps': false,
			'user-suggestions': false,
			risks: true,
			social: true,
			user: false,
			users: false,
			weather: false,
			locations: false,
			notifications: true
		};
		const sdkConfig: SdkConfigService = SdkConfigService.configure({
			baseUrl: environment.API_BASE,
			apiKey: "",
			mockConfig,
			storeOnMobile: true,
			accessTokenName: "access_token",
			refreshTokenName: "refresh_token",
		});
		sdkConfig.setMock(true, mockConfig);

		StorageHelper.getInstance().getMobileStorageItem('access_token').then((res) => {
			console.log('JE lAI EUUUU' + res);
			StorageHelper.getInstance().setItem('access_token', res);
		});
		StorageHelper.getInstance().getMobileStorageItem('refresh_token').then((res) => {
			console.log('JE lAI EUUUU' + res);
			StorageHelper.getInstance().setItem('refresh_token', res);
		});
	}

	onSwipe(s: string) {
		console.log('SWIIIPE' + s);
	}

	ngAfterViewInit() {
		let hammertime = new Hammer(this.el.nativeElement);
		hammertime.get('swipe').set({direction: Hammer.DIRECTION_HORIZONTAL});
		hammertime.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL});
		hammertime.on("swipe", (ev) => {
			console.log('SWIPE');
		})
		;hammertime.on("swipeleft", (ev) => {
			console.log('SWIPE L');
		});
		hammertime.on("swiperight", (ev) => {
			console.log('SWIPE R');
			this.location.back();
		});
		hammertime.on("pan", (ev) => {
			console.log('PAN ');
		});
		hammertime.on("panleft", (ev) => {
			console.log('PAN L');
		});
		hammertime.on("panright", (ev) => {
			//this.router.
		});
	}
}
