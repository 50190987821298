import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AppHeaderComponent } from "../../components/app-header/app-header.component";
import { AppMenuComponent } from "../../components/app-menu/app-menu.component";
import { CommonModule } from "@angular/common";
import { HeaderFooterService } from "../../services/display/header-footer.service";
import { Subscription } from "rxjs";
import { vi } from "@faker-js/faker";

import { HammerGestureConfig, HammerModule } from "@angular/platform-browser";

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pan: { direction: Hammer.DIRECTION_ALL },
    swipe: { direction: Hammer.DIRECTION_VERTICAL },
  };
}
@Component({
  selector: "vw-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
  imports: [AppHeaderComponent, AppMenuComponent, CommonModule],
  standalone: true,
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() tabsTemplate: TemplateRef<any>;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() headerAdditionalComponent: TemplateRef<any>;

  @ViewChild("page") page: ElementRef; // Référence à l'élément contenant le contenu de la page
  @ViewChild("header") header: ElementRef; // Référence à l'élément du header que vous voulez cacher
  @ViewChild("footer") footer: ElementRef; // Référence à l'élément du header que vous voulez cacher

  headerFooterVisible: boolean = true;

  headerFooterVisibilitySubscription: Subscription;
  constructor(
    private renderer: Renderer2,
    private headerFooterService: HeaderFooterService
  ) {
    this.headerFooterVisibilitySubscription =
      this.headerFooterService.visibility.subscribe(
        this.onVisibilityChanged.bind(this)
      );
  }

  ngOnDestroy() {
    this.headerFooterVisibilitySubscription.unsubscribe();
  }

  ngOnInit() {}

  onVisibilityChanged(visibility: boolean) {
    this.headerFooterVisible = visibility;
  }
}
