<vw-default-layout [bodyTemplate]="bodyT" [headerTemplate]="headers" [tabsTemplate]="tabs">

    <ng-template #headers>
        <vw-app-details-header [title]="'SETTINGS.USER_INFO.EDIT.MY_DATA'"></vw-app-details-header>
    </ng-template>
    <ng-template #tabs>
    </ng-template>
    <ng-template #bodyT>
        <router-outlet></router-outlet>
    </ng-template>
</vw-default-layout>
